import { Message, messageSchema } from "../models/Message";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { array } from "yup";
import { getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { logValidationErrors } from "../utils";

const messageCollectionName = "messages";

export default class MessageService {
  static getAll = async (): Promise<Message[] | null> => {
    try {
      const messages = await getDocuments<Message>(messageCollectionName);
      logValidationErrors(messages, array().of(messageSchema), "MessageService.getAll");
      return messages;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting all messages in MessageService.getAll",
        error,
      });
      return null;
    }
  };

  static getById = async (id: string): Promise<Message | null> => {
    try {
      const message = await getDocumentById<Message>(messageCollectionName, id);
      logValidationErrors(message, messageSchema, "MessageService.getbyId");
      return message;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting a message in MessageService.getById",
        error,
      });
      return null;
    }
  };
}
