import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, Platform, Alert, SafeAreaView, Linking } from "react-native";
import {
  Button,
  HelperText,
  Avatar,
  ActivityIndicator,
  useTheme,
  Text,
  MD3Theme,
} from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import defaultAvatar from "../../../assets/default-profile.png";
import { useQsolBottomSheet } from "../../contexts/QsolBottomSheetContext";
import { ScrollView } from "react-native-gesture-handler";
import QsolBottomSheetTextInput from "../atoms/QsolBottomSheetTextInput";
import { BottomSheetNavigationProp } from "../../screens/navigation/BottomSheetNavigation";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import QsolDialog from "../molecules/QsolDialog";
import { useTranslation } from "react-i18next";
import useProfile from "../../hooks/queries/useProfile";
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from "firebase/storage";
import { storage } from "../../lib/firebase";

interface ProfileImageProps {
  imageUri: string | null;
}

interface EditProfileSheetProps {
  displayName: string;
  email?: string;
}

// Feature:
// - Display name is required field, meaning that it cannot be empty ->
// Edit profile screen is the first screen that user sees after signing up.
// For ensuring user has a display name, "Done" button is disabled until display name is filled;
// If user empties the display name, Done button is disabled again.

const EditProfileSheet = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<BottomSheetNavigationProp>();
  const bottomSheet = useQsolBottomSheet();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string | undefined>("");
  const [error, setError] = useState<Partial<EditProfileSheetProps> | undefined>(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const profile = useProfile();

  const requestMediaLibraryPermissionsAsync = async () => {
    if (Platform.OS === "web") {
      return true; // allow request media library on web
    }
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      if (Platform.OS === "ios") {
        Alert.alert(
          t("global.alert.mediaLibraryPermission.title"),
          t("global.alert.mediaLibraryPermission.description"),
          [
            { text: t("global.cancel"), style: "cancel" },
            {
              text: t("global.alert.mediaLibraryPermission.goToSettings"),
              onPress: Linking.openSettings,
            },
          ]
        );
      } else {
        Alert.alert(t("global.alert.mediaLibraryPermission.message"));
      }
      return false;
    }
    return true;
  };

  const pickImage = async () => {
    if (await requestMediaLibraryPermissionsAsync()) {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const image = result.assets[0];
        const type = image.uri.substring("data:image".length + 1, image.uri.indexOf(";"));
        const storageRef = ref(storage, "profile/" + profile.query.data?.uid + `/profile.${type}`);
        const snapshot = await uploadString(storageRef, image.uri);
        const url = await getDownloadURL(snapshot.ref);
        profile.updateProfile({ imageUrl: url });
      }
      setDialogVisible(false);
    }
    setDialogVisible(false);
  };

  const handleDeleteProfileImage = () => {
    profile.updateProfile({ imageUrl: null });
    setDialogVisible(false);
  };

  const handleDisplayNameChange = (text: string) => {
    setDisplayName(text);
    setError(undefined);
    if (text.length === 0) {
      setError({ displayName: t("editProfileSheet.errorMessage.displayName") });
    }
  };

  // Write the changed values to the database if they
  const handleSaveButtonPress = () => {
    if (error) {
      return;
    }

    profile.updateProfile({ displayName });
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  useEffect(() => {
    const disableSave = error !== undefined || !unsavedChanges;
    if (Platform.OS !== "web") {
      navigation.setOptions({
        headerBackTitle: unsavedChanges ? t("global.cancel") : t("global.profile"),
        headerRight: () => (
          <TouchableOpacity disabled={disableSave} onPress={handleSaveButtonPress}>
            <Text
              style={[
                styles.navigationHeaderRightSaveButton,
                {
                  color: disableSave ? theme.colors.surfaceDisabled : theme.colors.primary,
                  paddingRight: Platform.OS === "android" ? 12 : 0,
                },
              ]}
            >
              {t("global.save")}
            </Text>
          </TouchableOpacity>
        ),
      });
    } else {
      navigation.setOptions({
        headerRight: () => (
          <Button
            disabled={disableSave}
            onPress={handleSaveButtonPress}
            style={styles.webNavigationHeaderRightSaveButton}
            labelStyle={styles.webNavigationHeaderRightSaveButtonText}
          >
            {t("global.save")}
          </Button>
        ),
      });
    }
  }, [navigation, error, unsavedChanges, handleSaveButtonPress]);

  useEffect(() => {
    const hasUnsavedChanges = !displayName || displayName !== profile.query.data?.displayName;
    setUnsavedChanges(hasUnsavedChanges);
    setLoading(false);
  }, [displayName]);

  useEffect(() => {
    bottomSheet.preventDismiss();
    return () => {
      bottomSheet.allowDismiss();
    };
  }, []);

  useEffect(() => {
    if (profile.query.isSuccess) {
      setDisplayName(profile.query.data?.displayName ?? "");
    }
  }, [profile.query.isLoading]);

  if (profile.query.isLoading || !displayName === undefined || loading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  const imageUrl = profile.query.data?.imageUrl;
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.profileImageView}>
          <Avatar.Image size={100} style={styles.profileAvatar} source={defaultAvatar} />
          <Text variant="displaySmall">{profile.query.data?.displayName}</Text>
        </View>
        {/* <Button
          labelStyle={styles.editProfileImageButton}
          mode="text"
          onPress={() => setDialogVisible(!dialogVisible)}
        >
          {t("editProfileSheet.editProfileImage")}
        </Button> */}

        <QsolDialog
          visible={dialogVisible}
          hideDialog={() => setDialogVisible(false)}
          title={t("editProfileSheet.editProfileImage")}
          titleStyle={styles.dialogTitle}
          style={styles.dialogContainer}
          actions={
            <View style={styles.dialogActionView}>
              <Button onPress={pickImage}>{t("editProfileSheet.choosePhoto")}</Button>
              {/* TODO: Check whether profile image exists in firebase storage, if exists, show delete button */}
              <Button onPress={handleDeleteProfileImage}>
                {t("editProfileSheet.deletePhoto")}
              </Button>
              <Button onPress={() => setDialogVisible(false)}>{t("global.cancel")}</Button>
            </View>
          }
        />
        <View style={styles.listContainer}>
          <View>
            <Text variant="labelLarge">{t("editProfileSheet.email")}</Text>
            <Text>{profile.query.data?.email}</Text>
          </View>
          <QsolBottomSheetTextInput
            label={`${t("editProfileSheet.displayName")} *`}
            autoFocus={false}
            contentStyle={styles.textInputContent}
            style={styles.textInput}
            value={displayName}
            onChangeText={handleDisplayNameChange}
          />
          {error?.displayName && (
            <HelperText type="error" visible style={styles.helperText}>
              {error.displayName}
            </HelperText>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      padding: 20,
    },
    activityIndicator: { paddingTop: 20 },
    profileImageView: { alignItems: "center" },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    webNavigationHeaderRightSaveButton: { position: "absolute", right: 10 },
    navigationHeaderRightSaveButton: {
      fontSize: 18,
      textAlign: "right",
    },
    webNavigationHeaderRightSaveButtonText: { fontSize: 16 },
    profileHeaderView: {
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      width: "100%",
      justifyContent: "center",
    },
    buttonText: {
      fontSize: 20,
    },
    listContainer: {
      width: "100%",
      paddingHorizontal: 2,
      marginBottom: 40,
      gap: 20,
    },
    textInputContent: {
      borderRadius: 4,
    },
    textInput: {
      marginBottom: 12,
    },
    profileAvatar: {
      marginTop: 16,
      marginBottom: 16,
      backgroundColor: "white",
    },
    editProfileImageButton: {
      fontSize: 16,
      marginBottom: 16,
    },
    dialogTitle: {
      textAlign: "center",
      fontSize: 20,
    },
    dialogActionView: {
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
    helperText: {
      marginTop: 0,
      paddingTop: 0,
      paddingLeft: 0,
      marginLeft: 2,
      marginBottom: 6,
    },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
  });

export default EditProfileSheet;
