import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Button, List, Avatar, Badge, Text } from "react-native-paper";
import defaultAvatar from "../../../assets/default-profile.png";
import LanguagePicker from "../molecules/LanguagePicker";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import { BottomSheetNavigationProp } from "../../screens/navigation/BottomSheetNavigation";
import ListItem from "../atoms/ListItem";
import SignoutConfirmationDialog from "../molecules/SignoutConfirmationDialog";
import useUnreadMessagesCount from "../../hooks/queries/useUnreadMessagesCount";

const ProfileSheet = () => {
  const [languagePickerVisible, setLanguagePickerVisible] = useState<boolean>(false);
  const [showSignOutConfirmationDialog, setShowSignOutConfirmationDialog] =
    useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation<BottomSheetNavigationProp>();
  const unreadMessagesCount = useUnreadMessagesCount();

  const goToEditScreen = () => {
    navigate("EditProfile");
  };
  const goToMessages = () => {
    navigate("Messages");
  };

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Avatar.Image size={100} style={styles.profileAvatar} source={defaultAvatar} />
        <Button labelStyle={{ fontSize: 16 }} onPress={goToEditScreen} mode="text">
          {t("profileSheet.editProfile")}
        </Button>
        <View style={styles.listView}>
          <ListItem
            title={t("profileSheet.messages")}
            left={(props) => (
              <View>
                <List.Icon {...props} icon="chat-processing-outline" />
                {unreadMessagesCount > 0 && (
                  <Badge style={styles.messageBadge}>{unreadMessagesCount}</Badge>
                )}
              </View>
            )}
            right={(props) => <List.Icon {...props} icon="chevron-right" />}
            style={styles.listItem}
            onPress={goToMessages}
          />
          <ListItem
            title={t("profileSheet.language")}
            left={(props) => <List.Icon {...props} icon="cards-heart-outline" />}
            right={(props) => (
              <>
                <Text {...props}>{t(`global.${i18n.language}`)}</Text>
                <List.Icon {...props} icon="chevron-right" />
              </>
            )}
            style={styles.listItem}
            onPress={() => setLanguagePickerVisible(true)}
          />
        </View>
        <View style={styles.listView}>
          <ListItem
            title={t("profileSheet.signout")}
            left={(props) => <List.Icon {...props} icon="logout" />}
            right={(props) => <List.Icon {...props} icon="chevron-right" />}
            style={styles.listItem}
            onPress={() => setShowSignOutConfirmationDialog(true)}
          />
        </View>
      </View>
      <LanguagePicker
        languagePickerVisible={languagePickerVisible}
        onDismiss={() => setLanguagePickerVisible(false)}
      />
      <SignoutConfirmationDialog
        visible={showSignOutConfirmationDialog}
        hideDialog={() => setShowSignOutConfirmationDialog(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: "center",
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
  },
  listView: {
    width: "100%",
    paddingHorizontal: 2,
    marginBottom: 40,
  },
  listItem: {
    borderBottomWidth: 1,
    marginVertical: 8,
  },
  profileAvatar: {
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: "white",
  },
  messageBadge: {
    position: "absolute",
    top: -8,
    right: -10,
  },
});

export default ProfileSheet;
