import useRewards from "./useRewards";
import useProducts from "./useProducts";
import { useEffect, useState } from "react";
import { VendorReward } from "../../models/VendorRewards";
import useVendors from "./useVendors";
import useVendorCustomer from "./usePoints";

export interface Properties {
  queryOnLoad?: boolean;
}

export default function useVendorRewards() {
  const rewards = useRewards();
  const products = useProducts();
  const vendors = useVendors();
  const points = useVendorCustomer();

  const loading =
    vendors.query.isLoading ||
    rewards.query.isLoading ||
    products.query.isLoading ||
    points.query.isLoading;

  const [data, setData] = useState<VendorReward[]>([]);

  useEffect(() => {
    if (loading) {
      return;
    }

    const rewardsFromTemplates =
      rewards.query.data?.map((rewardTemplate) => {
        const userOwnedPointsInAVendor =
          points.query.data?.find((x) => x.vendorId === rewardTemplate.vendorId)?.numberOfPoints ??
          0;
        const reward: VendorReward = {
          id: rewardTemplate.id!,
          title: rewardTemplate.title,
          description: rewardTemplate.description ?? {},
          requiredPoints: Number(rewardTemplate.requiredPoints),
          vendorId: rewardTemplate.vendorId!,
          vendor: vendors.query.data?.find((vendor) => vendor.id === rewardTemplate.vendorId)!,
          userPointsForVendor: userOwnedPointsInAVendor,
          active: rewardTemplate.active,
          imageUrl: rewardTemplate.imageUrl ?? "",
          createdAt: rewardTemplate.createdAt,
          type: "rewardTemplate",
        };
        return reward;
      }) ?? [];

    const rewardsFromProducts =
      products.query.data
        ?.filter((x) => x.usableAsReward)
        .map((product) => {
          const userOwnedPointsInAVendor =
            points.query.data?.find((x) => x.vendorId === product.vendorId)?.numberOfPoints ?? 0;
          const reward: VendorReward = {
            id: product.id!,
            title: product.title,
            description: product.description ?? {},
            requiredPoints: Number(product.pointsRequired),
            vendorId: product.vendorId!,
            vendor: vendors.query.data?.find((vendor) => vendor.id === product.vendorId)!,
            userPointsForVendor: userOwnedPointsInAVendor,
            active: product.active,
            imageUrl: product.imageUrl ?? "",
            createdAt: product.createdAt,
            type: "product",
          };
          return reward;
        }) ?? [];

    const allRewards = rewardsFromTemplates.concat(rewardsFromProducts);
    setData(allRewards);
  }, [rewards.query.data, products.query.data, rewards.query.data, points.query.data, loading]);

  const invalidate = () => {
    products.invalidate();
    rewards.invalidate();
    points.invalidate();
  };

  return { data, loading, invalidate };
}
