import { promotionTemplateSchema, PromotionTemplate } from "../models/PromotionTemplate";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { array } from "yup";
import { getDocuments } from "../lib/firebase/connectors";
import { logValidationErrors } from "../utils";

export const promotionsCollection = "promotionTemplates";

export default class PromotionService {
  static getAll = async (): Promise<PromotionTemplate[] | null> => {
    try {
      const promotions = await getDocuments<PromotionTemplate>(promotionsCollection);
      logValidationErrors(
        promotions,
        array().of(promotionTemplateSchema),
        "PromotionService.getAll"
      );
      return promotions;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting promotion templates",
        error,
      });
      return null;
    }
  };
}
