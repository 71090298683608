import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Platform, BackHandler, StyleSheet } from "react-native";
import { StackActions, useNavigation } from "@react-navigation/native";
import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
} from "@gorhom/bottom-sheet";
import BottomSheetNavigation, {
  BottomSheetNavigationKey,
} from "../../screens/navigation/BottomSheetNavigation";
import { QsolBottomSheetContext } from ".";
import { MainNavigationProp } from "../../screens/navigation/MainNavigation";

export default function QsolBottomSheetProvider({ children }: any) {
  const sheetRef = useRef<BottomSheetModal>(null);
  const snapPoints = useMemo(() => ["85%"], []);
  const [routeToOpen, setRouteToOpen] = useState<BottomSheetNavigationKey>("Profile");
  const [isDismissAllowed, setIsDismissAllowed] = useState(true);
  const { navigate } = useNavigation<MainNavigationProp>();

  const onBackPress = () => {
    if (sheetRef.current?.close) {
      sheetRef.current.close();
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      const backHandler = BackHandler.addEventListener("hardwareBackPress", onBackPress);
      return () => backHandler.remove();
    }
  }, []);

  const renderBackdrop = useCallback(
    (props: any) => (
      <BottomSheetBackdrop
        {...props}
        appearsOnIndex={0}
        disappearsOnIndex={-1}
        pressBehavior={isDismissAllowed ? "close" : "none"}
      />
    ),
    [isDismissAllowed]
  );

  const show = (route: BottomSheetNavigationKey) => {
    if (Platform.OS === "web") {
      navigate(route);
    } else {
      setRouteToOpen(route);
      sheetRef.current?.present();
    }
  };

  const dismiss = useCallback(() => {
    if (Platform.OS === "web") {
      StackActions.pop(1);
    } else {
      sheetRef.current?.dismiss();
    }
  }, []);

  const allowDismiss = () => {
    setIsDismissAllowed(true);
  };

  const preventDismiss = () => {
    setIsDismissAllowed(false);
  };

  return (
    <QsolBottomSheetContext.Provider value={{ show, dismiss, allowDismiss, preventDismiss }}>
      <BottomSheetModalProvider>
        {children}
        {Platform.OS !== "web" && (
          <BottomSheetModal
            ref={sheetRef}
            snapPoints={snapPoints}
            backdropComponent={renderBackdrop}
            enablePanDownToClose={isDismissAllowed}
            enableDismissOnClose={isDismissAllowed}
            keyboardBehavior={Platform.OS === "android" ? "interactive" : "extend"}
            keyboardBlurBehavior="restore"
            android_keyboardInputMode="adjustResize"
          >
            <BottomSheetNavigation openWithRoute={routeToOpen} />
          </BottomSheetModal>
        )}
      </BottomSheetModalProvider>
    </QsolBottomSheetContext.Provider>
  );
}
