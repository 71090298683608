import { Session } from "@supabase/supabase-js";
import { useEffect } from "react";
import { supabase } from "../lib/supabase";

export default function ensureFirebaseSession(supabaseSession: Session | undefined) {
  useEffect(() => {
    (async () => {
      if (!supabaseSession) return;

      // This user is now email-verified, flag it in the database so other's cant register using this email
      supabase.auth.updateUser({ data: { hasLoggedInThroughMobile: true } });
    })();
  }, [supabaseSession?.access_token]);
}
