import { VendorCustomerHistory } from "../models/VendorCustomerHistory";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { getCollectionGroup, tryGetCurrentUser, getDocuments } from "../lib/firebase/connectors";
import { where } from "../lib/firebase/helpers";
import { throwAndLogMessage } from "../utils";

export const vendorCustomersCollection = "vendorCustomers";
export const historyCollection = "pointHistory";
export const vendorCustomerCollectionName = (vendorId: string) =>
  `vendors/${vendorId}/vendorCustomers`;
export const vendorCustomerHistoryCollectionName = (vendorId: string, customerId: string) =>
  `vendors/${vendorId}/vendorCustomers/${customerId}/${historyCollection}`;

export default class HistoryService {
  static getAllHistory = async (): Promise<VendorCustomerHistory[] | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    const uid = currentUser.id;
    try {
      const histories = await getCollectionGroup<VendorCustomerHistory>(
        historyCollection,
        where("uid", "==", uid)
      );
      return histories;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting vendorCustomerHistory",
        error,
      });
      return null;
    }
  };

  static getHistoryByVendorId = async (
    vendorId: string
  ): Promise<VendorCustomerHistory[] | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    const uid = currentUser.id;
    try {
      const histories = await getDocuments<VendorCustomerHistory>(
        vendorCustomerHistoryCollectionName(vendorId, uid)
      );
      return histories;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message:
          "Error getting vendorCustomerHistory in vendorCustomerService.getHistoryByCustomerUid",
        error,
      });
      // throw new Error("Error getting vendorCustomerHistory");
      return null;
    }
  };
}
