import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, TextStyle, StyleSheet, View } from "react-native";
import { Text, Button, MD3Theme, useTheme } from "react-native-paper";
import { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

interface DescriptionProps {
  initialText: string;
  maxLines?: number;
  variant?: VariantProp<string>;
  style?: StyleProp<TextStyle>;
}
const Description: React.FC<DescriptionProps> = ({
  initialText,
  maxLines = 2,
  variant,
  style,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <View style={styles.descriptionContainer}>
      <Text
        numberOfLines={isExpanded ? undefined : maxLines}
        variant={variant}
        style={[style, styles.text]}
      >
        {initialText}
      </Text>
      <View>
        {isExpanded && (
          <Button style={styles.button} onPress={toggleDescription} compact>
            {t("vendorScreen.showLess")}
          </Button>
        )}
        {!isExpanded && (
          <Button style={styles.button} onPress={toggleDescription} compact>
            {t("vendorScreen.showMore")}
          </Button>
        )}
      </View>
    </View>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    button: {
      width: "100%",
      alignItems: "flex-end",
    },
    descriptionContainer: {
      display: "flex",
      flexFlow: "column",
    },
    text: {
      textAlign: "left",
    },
  });

export default Description;
