import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View, Linking, TouchableOpacity, StyleSheet } from "react-native";
import { Text, Icon, MD3Theme, useTheme } from "react-native-paper";

interface ContactProps {
  phone?: string | null;
  email?: string | null;
  website?: string | null;
}

const ContactComponent: React.FC<ContactProps> = ({
  phone,
  email,
  website,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const handlePhonePress = () => {
    const phoneUrl = `tel:${phone}`;
    Linking.openURL(phoneUrl);
  };

  const handleEmailPress = () => {
    const emailUrl = `mailto:${email}`;
    Linking.openURL(emailUrl);
  };

  const handleWebsitePress = () => {
    const websiteUrl = `${website}`;
    Linking.openURL(websiteUrl);
  };

  return (
    <View>
      <Text style={styles.contactTitle} variant="titleMedium">
        {t("vendorScreen.contact")}
      </Text>
      <View style={styles.contactContent}>
        <Icon source="phone-outline" size={20} />
        <TouchableOpacity style={styles.link} onPress={handlePhonePress}>
          <Text style={styles.text}>{phone}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.contactContent}>
        <Icon source="email-outline" size={20} />
        <TouchableOpacity style={styles.link} onPress={handleEmailPress}>
          <Text style={styles.text}>{email}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.contactContent}>
        <Icon source="web" size={20} />
        <TouchableOpacity style={styles.link} onPress={handleWebsitePress}>
          <Text style={styles.text}>{website}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    contactTitle: {
      textAlign: "left",
      marginBottom: 10,
    },
    contactContent: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 10,
    },
    link: {
      marginLeft: 10,
    },
    text: {
      textDecorationLine: "underline",
    },
  });
export default ContactComponent;
