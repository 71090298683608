import { Customer } from "../models/Customer";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import {
  getCloudFunction,
  tryGetCurrentUser,
  getDocumentById,
  updateDocument,
} from "../lib/firebase/connectors";
import {
  CopyCustomerChangesToVendorsRequest,
  EnsureCustomerHasModelRequest,
} from "../models/FunctionParams";
import { FunctionNames } from "../models/FunctionNames";
import { ensureCustomerHasModel } from "../lib/firebase/functions";
import { throwAndLogMessage } from "../utils";

const customerCollection = "customers";

export default class CustomerService {
  static tryGetCurrentUserCustomerModel = async (): Promise<Customer | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    try {
      const customer = await getDocumentById<Customer>(customerCollection, currentUser.id);
      return customer;
    } catch (error) {
      return null;
    }
  };

  static updateFavoriteVendorIds = async (favoriteVendorIds: string[]): Promise<string[]> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    try {
      await updateDocument<Customer>(customerCollection, currentUser.id, { favoriteVendorIds });
      return favoriteVendorIds;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error updating favorite vendor ids in CustomerService.updateFavoriteVendorIds",
        uid: currentUser.id,
        favoriteVendorIds,
        error,
      });
      throw new Error("Error updating favorite vendor ids");
    }
  };

  static updateCurrentUser = async (customer: Partial<Customer>): Promise<Customer> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    let returnValue: Customer | null = null;
    try {
      await updateDocument<Customer>(customerCollection, currentUser.id, customer);
      const returnedCustomer = await this.tryGetCurrentUserCustomerModel();
      returnValue = returnedCustomer;
    } catch (error: any) {
      console.log("User could not update customer model, trying to ensure customer has model");
      returnValue = await ensureCustomerHasModel({
        uid: currentUser.id ?? "customer.service--UID_MISSING",
        email: currentUser.email ?? "customer.service--EMAIL_MISSING",
        displayName: customer.displayName ?? "customer.service--DISPLAY_NAME_MISSING",
      });
    }
    if (!returnValue) {
      throw new Error("Error updating current user");
    }
    setTimeout(() => this.copyCustomerChanges(), 1000);
    return returnValue;
  };

  static ensureUserHasCustomerModel = async (uid: string): Promise<void> => {
    const ensureCustomerModel = await getCloudFunction<EnsureCustomerHasModelRequest>(
      FunctionNames.EnsureCustomerHasModel
    );
    await ensureCustomerModel({ uid });
  };

  static copyCustomerChanges = async (): Promise<void> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    const copyChangesFunc = await getCloudFunction<CopyCustomerChangesToVendorsRequest>(
      FunctionNames.CopyCustomerChangesToVendors
    );
    await copyChangesFunc({ uid: currentUser.id });
  };
}
