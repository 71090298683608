import React from "react";
import WebViewModal from "../molecules/WebViewModal";

interface TermsOfServiceModalProps {
  visible: boolean;
  onClose?: () => void;
  language: string;
}

const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({
  visible,
  onClose,
  language,
}) => {
  const termsUri = `https://qponly.app/${language}/terms?prevent-navigation`;

  return (
    <WebViewModal uri={termsUri} visible={visible} onClose={onClose} />
  );
};

export default TermsOfServiceModal;
