import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import MemberCard from "../molecules/MemberCard";

const WalletSheet = () => {
  const styles = useMemo(() => createStyles(), []);
  return (
    <View style={styles.container}>
      <MemberCard />
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      height: "100%",
      maxHeight: 700,
      justifyContent: "center",
      alignItems: "center",
    },
  });

export default WalletSheet;
