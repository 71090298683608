import {
  StyleSheet,
  View,
  useWindowDimensions,
  Platform,
  StyleProp,
  ViewStyle,
  Dimensions,
} from "react-native";
import React, { useMemo } from "react";
import { Icon, MD3Theme, Text, useTheme } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { VendorCoupon } from "../../../models/VendorCoupon";
import QRCodeGenerator from "../../../components/molecules/QRCodeGenerator";
import FlipCard from "../../../components/molecules/FlipCard";
import QsolImage from "../../../components/atoms/QsolImage";
import { dbt, formatDate, getDate } from "../../../utils";

interface Props {
  uid: string;
  item: VendorCoupon;
  userOwnedStampsInAVendor: number;
  active?: boolean;
  canRedeem?: boolean;
  usesLeft?: number | null;
}
export default function CouponsCarouselItem(props: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { width } = useWindowDimensions();
  const locale = i18n.language;
  const carouselInnerItemStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      height: "100%",
      flex: 1,
      flexBasis: width / 2,
      backgroundColor: props.canRedeem ? theme.colors.secondaryContainer : theme.colors.background,
    }),
    [width]
  );
  const windowWidth = Dimensions.get("window").width;
  const qrSize = Math.min(Math.round(windowWidth * 0.8), 400);

  const frontContent = () => {
    return (
      <View style={carouselInnerItemStyle}>
        {props.item.imageUrl ? (
          <QsolImage
            uri={props.item.imageUrl ?? undefined}
            style={styles.image}
            fallbackImage="reward.webp"
          />
        ) : (
          <View style={styles.image}>
            <Icon source="ticket" size={250} color={theme.colors.elevation.level5} />
          </View>
        )}
        <View style={styles.carouselInnerItemTextContainer}>
          <View style={styles.rewardInfoContainer}>
            <View
              style={
                (styles.rewardTitleContainer,
                {
                  width: Platform.OS === "web" ? "100%" : "80%",
                })
              }
            >
              {!!props.item.title && (
                <Text variant="titleMedium" style={styles.rewardTitleText}>
                  {dbt(i18n.language, props.item.title)}
                </Text>
              )}
              {!!props.item.description && (
                <Text variant="bodyMedium" style={styles.rewardTitleText}>
                  {dbt(i18n.language, props.item.description)}
                </Text>
              )}

              {props.item.lastUsageDate && (
                <View style={{ alignItems: "center" }}>
                  {locale === "fi" ? (
                    <Text>
                      <Icon source="calendar-clock" size={24} /> Voimassa{" "}
                      {formatDate(props.item.lastUsageDate, locale)} asti
                    </Text>
                  ) : (
                    <Text>
                      <Icon source="calendar-clock" size={24} /> Valid until{" "}
                      {formatDate(props.item.lastUsageDate, locale)}
                    </Text>
                  )}
                </View>
              )}

              {props.usesLeft !== null && (
                <View style={{ alignItems: "center" }}>
                  <Text>
                    <Icon source="ticket-confirmation" size={24} /> {props.usesLeft}{" "}
                    {t("vendorScreen.usesLeft")}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const backContent = () => {
    return (
      <View style={styles.backContentContainer}>
        <QRCodeGenerator size={qrSize} qrContent={"TODO"} />
        <Text style={styles.backContentText} variant="bodySmall">
          {t("vendorScreen.redeemYourRewardUsingThisQRCode")}
        </Text>
      </View>
    );
  };

  return (
    <View style={[styles.container, { width }]}>
      <FlipCard
        style={[styles.carouselInnerItemTextContainer, carouselInnerItemStyle]}
        frontContent={frontContent()}
        backContent={backContent()}
        canFlip={props.item.usesLeft === null || props.item.usesLeft > 0}
      />
    </View>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: Platform.OS === "web" ? 500 : "auto",
      flexDirection: "row",
      paddingBottom: 30,
    },
    image: {
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      resizeMode: "cover",
      height: "100%",
      backgroundColor: theme.colors.elevation.level2,
      borderRadius: 15,
      margin: 15,
    },
    rewardTitleText: {
      fontWeight: "800",
      textAlign: "center",
      paddingHorizontal: 6,
      flexShrink: 1,
      width: "100%",
    },
    stampsText: {
      textAlign: "center",
      paddingHorizontal: 6,
    },
    carouselInnerItemTextContainer: {
      flexDirection: "column",
      justifyContent: "center",
      paddingBottom: 15,
    },
    rewardInfoContainer: { flexDirection: "column", alignItems: "center" },
    rewardTitleContainer: { flexDirection: "row" },
    backContentContainer: { alignItems: "center", flexDirection: "column" },
    backContentText: { paddingTop: 10, paddingHorizontal: 30 },
  });
