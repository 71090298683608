import React, { forwardRef, useCallback, useEffect } from "react";
import { TextStyle, Platform, TextInput } from "react-native";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import QsolTextInput from "./QsolTextInput";
import { TextInputProps as ReactNativePaperTextInputProps } from "react-native-paper";

export interface QsolBottomSheetTextInputProps extends ReactNativePaperTextInputProps {
  style?: TextStyle;
  value?: string;
  onFocus?(args: any): void;
  onBlur?(args: any): void;
}

const QsolBottomSheetTextInput = forwardRef<TextInput, QsolBottomSheetTextInputProps>(
  (props, ref) => {
    const { onFocus, onBlur, ...rest } = props;

    if (Platform.OS === "web") {
      return <QsolTextInput {...rest} />;
    }

    const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

    const handleOnFocus = useCallback(
      (args: any) => {
        shouldHandleKeyboardEvents.value = true;
        if (onFocus) {
          onFocus(args);
        }
      },
      [onFocus, shouldHandleKeyboardEvents]
    );
    const handleOnBlur = useCallback(
      (args: any) => {
        shouldHandleKeyboardEvents.value = false;
        if (onBlur) {
          onBlur(args);
        }
      },
      [onBlur, shouldHandleKeyboardEvents]
    );

    useEffect(() => {
      return () => {
        shouldHandleKeyboardEvents.value = false;
      };
    }, [shouldHandleKeyboardEvents]);

    return <QsolTextInput ref={ref} onFocus={handleOnFocus} onBlur={handleOnBlur} {...rest} />;
  }
);

export default QsolBottomSheetTextInput;
