import { InferType, object, string } from "yup";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

// Message
export const messageSchema = object({
  id: string().required(),
  createdAt: firebaseTimestampSchema.required(),
  title: string().required(),
  description: string().required(),
  updatedAt: firebaseTimestampSchema.required(),
});

export type Message = InferType<typeof messageSchema>;
