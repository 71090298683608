import { NavigationContainer } from "@react-navigation/native";
import { useAuth } from "../../../contexts/AuthContext";

interface Props {
  children: React.ReactNode;
  theme: any;
}

export default function QsolNavigationContainer({ children, ...props }: Props) {
  const { isAuthenticated } = useAuth();

  const onStateChange = (state: any) => {
    if (isAuthenticated) {
      const stateString = JSON.stringify(state);
      localStorage.setItem("navigationState", stateString);
    }
  };

  return (
    <NavigationContainer onStateChange={onStateChange} {...props}>
      {children}
    </NavigationContainer>
  );
}
