import { View } from "react-native";
import { TextInput, TextInputProps, useTheme } from "react-native-paper";

export default function QsolTextInput(props: TextInputProps) {
  const theme = useTheme();
  const { editable, style, contentStyle, disabled, ...rest } = props;

  const originalBackgroundColor = (contentStyle as any)?.backgroundColor;

  return (
    <View
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <TextInput
        mode="outlined"
        style={[style]}
        contentStyle={[
          contentStyle,
          {
            backgroundColor: disabled ? theme.colors.surfaceDisabled : originalBackgroundColor,
          },
        ]}
        disabled={disabled}
        {...rest}
      />
    </View>
  );
}
