import React, { useMemo, useState } from "react";
import { View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import {
  Button,
  Text,
  HelperText,
  useTheme,
  MD3Theme,
  ActivityIndicator,
} from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import QsolTextInput from "../../components/atoms/QsolTextInput";
import { useTranslation } from "react-i18next";
import { supabase } from "../../lib/supabase";
import Logo from "../../components/atoms/Logo";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "../navigation/MainNavigation";
import LanguageSwitchButton from "../../components/atoms/LanguageSwitchButton";
import { AuthError } from "@supabase/supabase-js";
import { toastError } from "../../components/atoms/ToastProvider";
import { useAuth } from "../../contexts/AuthContext";

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
};

export default function RegistrationForm() {
  const auth = useAuth();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { t } = useTranslation();
  const { navigate } = useNavigation<MainNavigationProp>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleSupabaseAuthError = (error: AuthError) => {
    if (error.name === "AuthApiError") {
      toastError(t("auth.wrongEmailOrPassword"));
      return;
    }
    toastError(t("global.errorMessage.unknownErrorOccurred"));
  };

  const onSubmit = async (formValues: FormData) => {
    try {
      setLoading(true);
      const credentials = {
        email: formValues.email,
        password: formValues.password,
      };

      const { error } = await supabase.auth.signInWithPassword(credentials);
      if (error) {
        handleSupabaseAuthError(error);
        setLoading(false);
        return;
      }
    } catch (error: unknown) {
      console.error("Error signing in", error);
      toastError(t("global.errorMessage.unknownErrorOccurred"));
      setLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{ width: "100%", alignItems: "center", marginBottom: 30 }}>
          <Logo />
          <LanguageSwitchButton />
        </View>
        <Text variant="headlineLarge" style={{ marginBottom: 10 }}>
          {t("auth.signIn")}
        </Text>
        <Controller
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Invalid email address",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <QsolTextInput
              label={t("auth.email")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={styles.input}
              mode="outlined"
              error={!!errors.email}
              autoComplete="off"
            />
          )}
          name="email"
        />
        {errors.email && <HelperText type="error">{errors.email.message}</HelperText>}

        <Controller
          control={control}
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <QsolTextInput
              label={t("auth.password")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry
              style={styles.input}
              mode="outlined"
              error={!!errors.password}
            />
          )}
          name="password"
        />
        {errors.password && <HelperText type="error">{errors.password.message}</HelperText>}

        <Button
          mode="contained"
          onPress={handleSubmit(onSubmit)}
          style={styles.button}
          disabled={auth.loading || loading}
        >
          {auth.loading || loading ? <ActivityIndicator color="white" size={14} /> : t("auth.sign")}
        </Button>
        <Button
          style={{ marginTop: 50 }}
          onPress={() => navigate("RegisterAccount")}
          disabled={auth.loading || loading}
        >
          {t("auth.newAccount")}
        </Button>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      justifyContent: "center",
      padding: 20,
    },
    input: {
      marginBottom: 12,
    },
    button: {
      marginTop: 12,
    },
  });
