import React from "react";
import WebViewModal from "../molecules/WebViewModal";

interface FAQModalProps {
  visible: boolean;
  onClose?: () => void;
  language: string;
}

const FAQModal: React.FC<FAQModalProps> = ({ visible, onClose, language }) => {
  const termsUri = `https://qponly.app/${language}?prevent-navigation`;

  return <WebViewModal uri={termsUri} visible={visible} onClose={onClose} />;
};

export default FAQModal;
