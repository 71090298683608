import React, { useMemo } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { Icon, MD3Theme, useTheme } from "react-native-paper";

const CustomHeader = (props: NativeStackHeaderProps) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.headerContainer}>
      {props.back ? (
        <TouchableOpacity
          onPress={() => props.navigation.goBack()}
          style={styles.backButton}
        >
          <Icon source="chevron-left" size={20} color={theme.colors.primary} />
          <Text style={styles.backButtonText}>{props.back?.title}</Text>
        </TouchableOpacity>
      ) : (
        <View style={styles.sideElement} />
      )}
      <Text style={styles.title}>
        {props.options.title ?? props.route.name}
      </Text>
      {props.options.headerRight ? (
        <View style={styles.headerRightView}>
          {props.options.headerRight({
            tintColor: theme.colors.primary,
            canGoBack: true,
          })}
        </View>
      ) : (
        <View style={styles.headerRightView} />
      )}
    </View>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    headerContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      height: 38,
      width: "100%",
      backgroundColor: "#fff",
      ...Platform.select({
        android: {
          elevation: 1,
        },
      }),
    },
    title: {
      fontSize: 18,
      fontWeight: "bold",
    },
    backButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: 100,
    },
    backButtonText: {
      fontSize: 18,
      color: theme.colors.primary,
    },
    sideElement: {
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    headerRightView: { width: 100 },
  });

export default CustomHeader;
