// LocationContext.tsx

import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import * as Location from "expo-location";
import { Alert, Linking, Platform } from "react-native";
import { useTranslation } from "react-i18next";

interface LocationContextProps {
  location: Location.LocationObject | null;
  status: Location.PermissionStatus | null;
  updateLocation: (raisePrompt?: boolean) => void;
}

interface LocationProviderProps {
  children: ReactNode;
  disablePermissionPropmt?: boolean;
}

const LocationContext = createContext<LocationContextProps | undefined>(
  undefined
);

export const LocationProvider: React.FC<LocationProviderProps> = ({
  children,
  disablePermissionPropmt,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Location.PermissionStatus | null>(null);
  const [permissionPromptEnabled, setPermissionPromptEnabled] =
    useState<boolean>(!disablePermissionPropmt);
  const [location, setLocation] = useState<Location.LocationObject | null>(
    null
  );

  const updateLocation = async (raisePrompt?: boolean) => {
    try {
      const result = await Location.requestForegroundPermissionsAsync();
      setStatus(result.status);

      if (
        result.status === "denied" &&
        (permissionPromptEnabled || raisePrompt)
      ) {
        if (Platform.OS === "web" && raisePrompt) {
          window.alert(
            `${t("global.alert.locationPermission.title")}: ${t(
              "global.alert.locationPermission.description"
            )}`
          );
        } else {
          Alert.alert(
            t("global.alert.locationPermission.title"),
            t("global.alert.locationPermission.description"),
            Platform.select({
              ios: [
                {
                  text: t("global.close"),
                  style: "cancel",
                  onPress: () => setPermissionPromptEnabled(false),
                },
                {
                  text: t("global.alert.mediaLibraryPermission.goToSettings"),
                  onPress: Linking.openSettings,
                },
              ],
              android: [
                {
                  text: t("global.close"),
                  style: "cancel",
                  onPress: () => setPermissionPromptEnabled(false),
                },
              ],
            })
          );
        }
      }

      if (result.status === "granted") {
        const currentLocation = await Location.getCurrentPositionAsync({
          accuracy:
            Platform.OS === "android"
              ? Location.Accuracy.Low
              : Location.Accuracy.Lowest,
        });
        setLocation(currentLocation);
      }
    } catch (error) {
      console.error("Error getting location:", error);
    }
  };

  return (
    <LocationContext.Provider value={{ location, updateLocation, status }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }

  return context;
};
