import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigation } from "@react-navigation/native";

export default function useNavigateToPreviousState(appLoading: boolean) {
  const { isAuthenticated } = useAuth();
  const navigation = useNavigation();

  useEffect(() => {
    if (!appLoading && isAuthenticated) {
      (async () => {
        const savedStateString = localStorage.getItem("navigationState");
        const state = savedStateString ? JSON.parse(savedStateString) : undefined;
        if (state) {
          navigation.reset(state);
        }
      })();
    }
  }, [appLoading, isAuthenticated]);

  return null;
}
