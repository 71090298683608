// DeleteAccountScreen.tsx
import { useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Button, TextInput, Text, MD3Theme, useTheme } from "react-native-paper";
import useKeyboardVisible from "../../hooks/useKeyboardVisible";
import { MainNavigationProp } from "../../screens/navigation/MainNavigation";
import QsolDialog from "../molecules/QsolDialog";
import { useTranslation } from "react-i18next";

interface DeleteAccountScreenProps {
  visible: boolean;
  onDismiss: () => void;
}

const DeleteAccountScreen: React.FC<DeleteAccountScreenProps> = ({ visible, onDismiss }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<MainNavigationProp>();
  const [confirmationText, setConfirmationText] = useState("");
  const isKeyboardVisible = useKeyboardVisible();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const handleConfirmDelete = () => {
    // Here, add logic to delete the user account, such as making an API call
    // After deletion, navigate the user to a login or a splash screen

    console.log("TODO: delete account");
    onDismiss();
    navigate("Login");
  };

  const handleCancel = () => {
    setConfirmationText("");
    onDismiss();
  };

  return (
    <View style={styles.view}>
      <QsolDialog
        visible={visible}
        hideDialog={onDismiss}
        title={t("deleteAccountDialog.title")}
        style={[
          { top: Platform.OS === "ios" && isKeyboardVisible ? -200 : 0 },
          styles.dialogContainer,
        ]}
        titleStyle={styles.title}
        actions={
          <>
            <Button onPress={handleCancel} mode="contained-tonal">
              {t(`global.cancel`)}
            </Button>
            <Button
              onPress={handleConfirmDelete}
              disabled={confirmationText !== t("deleteAccountDialog.delete").toUpperCase()}
            >
              {t(`global.confirm`)}
            </Button>
          </>
        }
        children={
          <>
            <View style={styles.textView}>
              <Text variant="bodyMedium" style={styles.contentParagraph}>
                {t("deleteAccountDialog.description.paragraph1")}
              </Text>
              <Text variant="bodyMedium" style={styles.contentParagraph}>
                {t("deleteAccountDialog.description.paragraph2")}
              </Text>
              <Text variant="bodyMedium" style={styles.contentParagraph}>
                {t("deleteAccountDialog.description.paragraph3")}
              </Text>
              <Text variant="bodyMedium" style={styles.contentParagraph}>
                {t("deleteAccountDialog.description.paragraph4")}
              </Text>
            </View>
            <TextInput
              value={confirmationText}
              onChangeText={setConfirmationText}
              autoCapitalize="none"
              right={
                <TextInput.Icon
                  icon="pencil-remove-outline"
                  onPress={() => setConfirmationText("")}
                />
              }
            />
          </>
        }
      />
    </View>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    view: {
      flex: 1,
      justifyContent: "center",
      paddingHorizontal: 20,
    },
    title: {
      fontSize: 20,
      marginVertical: 16,
    },
    textView: {
      marginBottom: 16,
    },
    contentParagraph: {
      marginVertical: 8,
      fontSize: 16,
    },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
  });

export default DeleteAccountScreen;
