import React, { useMemo } from "react";
import { View, StyleSheet, Platform, Dimensions } from "react-native";
import { IconButton, Text, MD3Theme, useTheme, Icon } from "react-native-paper";
import FlipCard from "./FlipCard";
import QRCodeGenerator from "./QRCodeGenerator";
import QsolImage from "../atoms/QsolImage";
import { dbt } from "../../utils";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { Vendor } from "../../models/Vendor";
import { VendorReward } from "../../models/VendorRewards";

export interface RedeemableRewardTemplateWithVendor extends VendorReward {
  vendor: Vendor;
}

interface RedeemableItemProps {
  redeemableItem: RedeemableRewardTemplateWithVendor;
}

const RedeemableItem: React.FC<RedeemableItemProps> = ({ redeemableItem }) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { t, i18n } = useTranslation();
  const { uid } = useAuth();
  const windowWidth = Dimensions.get("window").width;
  const qrSize = Math.min(windowWidth * 0.75, 400);

  const frontContent = () => {
    return (
      <View style={styles.card}>
        <View style={styles.cardContent}>
          {redeemableItem.imageUrl ? (
            <QsolImage
              uri={redeemableItem.imageUrl ?? undefined}
              style={styles.image}
              fallbackImage="reward.webp"
            />
          ) : (
            <View style={styles.image}>
              <Icon source="star-face" size={128} color={theme.colors.elevation.level5} />
            </View>
          )}
          <View style={styles.contentContainer}>
            <View style={styles.textContainer}>
              <Text variant="titleMedium" style={styles.title}>
                {dbt(i18n.language, redeemableItem.title)}
              </Text>
              <Text variant="bodyMedium" style={styles.points}>
                {redeemableItem.requiredPoints}{" "}
                {t(`global.stamp.${redeemableItem.requiredPoints > 1 ? "plural" : "singular"}`)}
              </Text>
              <Text variant="bodyMedium" style={Platform.OS == "android" && styles.text}>
                {redeemableItem.vendor.name}
              </Text>
            </View>
            <IconButton icon="arrow-right-bottom" iconColor={"black"} size={30} />
          </View>
        </View>
      </View>
    );
  };

  const backContent = () => {
    return (
      <View style={styles.card}>
        <View style={styles.cardContent}>
          <View style={styles.qrContainer}>
            <QRCodeGenerator size={qrSize} qrContent={`RWD_${uid}_${redeemableItem.id}`} />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.textContainer}>
              <Text variant="titleMedium" style={styles.title}>
                {dbt(i18n.language, redeemableItem.title)}
              </Text>
              <Text variant="bodyMedium" style={styles.points}>
                {redeemableItem.requiredPoints}{" "}
                {t(`global.stamp.${redeemableItem.requiredPoints > 1 ? "plural" : "singular"}`)}
              </Text>
              <Text variant="bodyMedium" style={Platform.OS == "android" && styles.text}>
                {t("redeemableRewardsComponent.scanQRAtTheProvider")}
              </Text>
            </View>
            <IconButton icon="keyboard-backspace" iconColor={"black"} size={30} />
          </View>
        </View>
      </View>
    );
  };

  return <FlipCard frontContent={frontContent()} backContent={backContent()} />;
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    imageRow: {
      flexDirection: "row",
      height: 180,
    },
    card: {
      borderRadius: 8,
      borderColor: "transparent",
      borderWidth: 1,
    },
    cardContent: {
      borderRadius: 8,
    },
    image: {
      width: "100%",
      height: 180,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      resizeMode: "cover",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.colors.elevation.level3,
    },
    logo: {
      width: "50%",
      height: 180,
      resizeMode: "cover",
      borderTopLeftRadius: 8,
      justifyContent: "center",
      backgroundColor: theme.colors.surface,
      alignItems: "center",
    },
    fallbackView: {
      width: "100%",
      height: 180,
      backgroundColor: theme.colors.surface,
      borderTopLeftRadius: 8,
    },
    qrContainer: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      borderTopRightRadius: 8,
      padding: 10,
    },
    contentContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      backgroundColor: "white",
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 16,
      borderTopWidth: 1,
      borderColor: "transparent",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    textContainer: {
      flex: 1,
      marginRight: 5,
      marginTop: 8,
    },
    title: {
      fontSize: 18,
      fontWeight: "bold",
    },
    points: {
      fontWeight: "bold",
    },
    text: {
      fontSize: 12,
    },
    button: {
      paddingVertical: 8,
      paddingHorizontal: 16,
    },
    buttonText: {
      color: theme.colors.onSurface,
      fontWeight: "bold",
    },
  });

export default RedeemableItem;
