import { useQuery, useQueryClient } from "@tanstack/react-query";
import CustomerService from "../../services/customer.service";
import { Customer } from "../../models/Customer";

interface Properties {
  queryOnLoad?: boolean;
}

export default function useProfile(props?: Properties) {
  const queryKey = ["useProfile"];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: () => CustomerService.tryGetCurrentUserCustomerModel(),
    enabled: props?.queryOnLoad ?? true,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const updateProfile = async (profile: Partial<Customer>) => {
    const updatedCustomerDoc = await CustomerService.updateCurrentUser(profile);
    queryClient.setQueryData(queryKey, (oldData: Customer) => ({
      ...oldData,
      ...updatedCustomerDoc,
    }));
  };

  return { query, invalidate, updateProfile };
}
