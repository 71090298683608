import { array } from "yup";
import { RewardTemplate, rewardTemplateSchema } from "../models/RewardTemplate";
import { logValidationErrors } from "../utils";
import { Translatable } from "../models/helpers/Translatable";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { tryGetCurrentUser, getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { where } from "../lib/firebase/helpers";

export interface CreateNewRewardTemplateRequest {
  vendorId: string;
  active: boolean;
  requiredPoints: number;
  title: Translatable;
  description?: Translatable;
}

export const rewardTemplatesCollection = "rewardTemplates";

export default class RewardService {
  static getQrContentForReward = (uid: string, rewardId: string) => {
    return `RWD_${uid}_${rewardId}`;
  };

  static getRewardsForVendor = async (vendorId: string): Promise<RewardTemplate[]> => {
    try {
      const rewardTemplatesForVendor = await getDocuments<RewardTemplate>(
        rewardTemplatesCollection,
        where("vendorId", "==", vendorId),
        where("active", "==", true)
      );
      logValidationErrors(
        rewardTemplatesForVendor,
        array().of(rewardTemplateSchema),
        "RewardService.getRewardsForVendor"
      );
      return rewardTemplatesForVendor;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting reward templates for vendor",
        error,
      });
      throw new Error("Error getting reward templates for vendor");
    }
  };

  static getAll = async (): Promise<RewardTemplate[]> => {
    try {
      const rewardTemplates = await getDocuments<RewardTemplate>(
        rewardTemplatesCollection,
        where("active", "==", true)
      );
      logValidationErrors(
        rewardTemplates,
        array().of(rewardTemplateSchema),
        "RewardService.getAll"
      );
      return rewardTemplates;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting reward templates",
        error,
      });
      throw new Error("Error getting reward templates");
    }
  };

  static getById = async (id: string) => {
    const rewardTemplate = getDocumentById<RewardTemplate>(rewardTemplatesCollection, id);
    logValidationErrors(rewardTemplate, rewardTemplateSchema, "RewardService.getById");
    return rewardTemplate;
  };
}
