import { array } from "yup";
import { logValidationErrors } from "../utils";
import { Translatable } from "../models/helpers/Translatable";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { where } from "../lib/firebase/helpers";
import { Product, productSchema } from "../models/Product";

export interface CreateNewProductRequest {
  vendorId: string;
  active: boolean;
  requiredPoints: number;
  title: Translatable;
  description?: Translatable;
}

export const productsCollection = "products";

export default class ProductService {
  static getQrContentForProductReward = (uid: string, productId: string) => {
    return `PRD_${uid}_${productId}`;
  };

  static getProductsForVendor = async (vendorId: string): Promise<Product[]> => {
    try {
      const productsForVendor = await getDocuments<Product>(
        productsCollection,
        where("vendorId", "==", vendorId),
        where("active", "==", true),
        where("visibleForCustomers", "==", true)
      );
      logValidationErrors(
        productsForVendor,
        array().of(productSchema),
        "ProductService.getProductsForVendor"
      );
      return productsForVendor;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting products for vendor",
        error,
      });
      throw new Error("Error getting products for vendor");
    }
  };

  static getAll = async (): Promise<Product[]> => {
    try {
      const products = await getDocuments<Product>(
        productsCollection,
        where("active", "==", true),
        where("visibleForCustomers", "==", true)
      );
      logValidationErrors(products, array().of(productSchema), "ProductService.getAll");
      return products;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting products",
        error,
      });
      throw new Error("Error getting products");
    }
  };

  static getById = async (id: string) => {
    const product = getDocumentById<Product>(productsCollection, id);
    logValidationErrors(product, productSchema, "ProductService.getById");
    return product;
  };
}
