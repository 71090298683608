import { FirebaseTimestamp } from "../models/helpers/FirebaseTimestamp";
import dayjs from "dayjs";

export function throwWithMessage(message: string): never {
  throw new Error(message);
}

export function throwAndLogMessage(message: string): never {
  Logger.error(LogLevel.Error, {
    message,
  });
  throw new Error(message);
}

/**
 * Validates if a given phone number string is in the E.164 format.
 *
 * @param {string} phone - The phone number string to be validated.
 * @return {boolean} Returns true if the phone number is in E.164 format, otherwise false.
 *
 * @example
 * isValidE164("+123456789012345"); // true
 * isValidE164("+12345a6789012345"); // false
 * isValidE164("123456789012345");  // false
 * isValidE164("+12345 6789012345"); // false
 */
export const isValidE164 = (phone: string): boolean => {
  const phoneRegex = /^\+[1-9]\d{1,14}$/;
  return phoneRegex.test(phone);
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
  // const regex = /\S+@\S+\.\S+/;
  // return regex.test(email);
};

export const key = <T>(k: keyof T) => {
  return k;
};

export const formatDateTimeDiffToNow = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Within four days, display the days ago or hours / mins ago
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  const diffHours = Math.floor(diff / (1000 * 3600));
  const diffMinutes = Math.floor(diff / (1000 * 60));

  if (diffDays < 4) {
    if (diffDays > 0) {
      return `${diffDays} days ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hours ago`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minutes ago`;
    }
  }

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const convertTimestampToDate = (timestamp: Timestamp | Date) => {
  if (timestamp instanceof Date) {
    return timestamp;
  }

  return timestamp.toDate();
};

import { LocationObjectCoords } from "expo-location";
import { FirebaseGeopoint } from "../models/helpers/FirebaseGeopoint";
import { Translatable } from "../models/helpers/Translatable";
import { availableLanguages } from "../models/helpers/Language";
import { Schema } from "yup";
import { LogLevel } from "../models/helpers/LogEvent";
import Logger from "./logger";
import { Timestamp } from "firebase/firestore";

/**
 * @returns distance in kilometers
 */
export const calculateDistance = (
  customerCoordinates: LocationObjectCoords,
  vendorCoordinates: FirebaseGeopoint
) => {
  const customerLatitude = customerCoordinates.latitude;
  const customerLongitude = customerCoordinates.longitude;
  const vendorLatitude = vendorCoordinates.latitude;
  const vendorLongitude = vendorCoordinates.longitude;

  const earthRadiusKm = 6371;
  const dLat = ((vendorLatitude - customerLatitude) * Math.PI) / 180;
  const dLon = ((vendorLongitude - customerLongitude) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((customerLatitude * Math.PI) / 180) *
      Math.cos((vendorLatitude * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c;
  return distance;
};

export const getDistanceText = (distance: number): string => {
  if (distance < 1) {
    // Convert to meters when distance is less than 1 kilometer
    return `${Math.round(distance * 1000)} m`;
  } else {
    // Display in kilometers for distances 1 kilometer or more
    return `${distance.toFixed(2)} km`;
  }
};

export const getAvatarText = (vendorName: string): string => {
  const firstLetter = vendorName.charAt(0).toUpperCase();
  const secondLetter = vendorName.length > 1 ? vendorName.charAt(1).toUpperCase() : "";

  return secondLetter ? `${firstLetter}${secondLetter}` : firstLetter;
};

export const capitalizeEachWord = (inputString: string): string => {
  return inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const dbt = (language: string, translations: Translatable): string => {
  if (!translations) {
    throw new Error("Translations parameter was undefined");
  }
  if (typeof translations !== "object") {
    throw new Error("Translations parameter was not an object");
  }
  if (language in translations) {
    return translations[language]!;
  }

  for (let i = 0; i < availableLanguages.length; i += 1) {
    const availableLanguage = availableLanguages[i];
    if (availableLanguage in translations) {
      return translations[availableLanguage] as string;
    }
  }

  return "";
};

export async function logValidationErrors<T>(
  values: T,
  schema: Schema,
  context?: string
): Promise<void> {
  // try {
  //   schema.validateSync(values);
  // } catch (error: unknown) {
  //   Logger.error(LogLevel.Error, {
  //     message: "Validation failed",
  //     error,
  //     values,
  //     context,
  //   });
  // }
}

export type TimestampOrDate = Pick<Timestamp, "seconds" | "nanoseconds"> | Date;
export function getDate(timestamp: TimestampOrDate) {
  if (timestamp instanceof Date) {
    return timestamp;
  }
  return new Date(timestamp.seconds * 1000);
}

export function formatDate(date: TimestampOrDate, locale: string = "fi") {
  const parsedDate = getDate(date);
  const dayjsDate = dayjs(parsedDate);
  if (locale === "fi") {
    return dayjsDate.format("DD.MM.YYYY");
  }
  if (locale === "en") {
    return dayjsDate.format("MM/DD/YYYY");
  }
  throw new Error("Unsupported locale");
}
