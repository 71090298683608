import React, { useMemo, useState } from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import ExpandableListItem from "../atoms/ExpandableListItem";
import { ActivityIndicator, Text, useTheme } from "react-native-paper";
import { useTranslation } from "react-i18next";
import useMessages from "../../hooks/queries/useMessages";
import useReadByCustomer from "../../hooks/queries/useReadByCustomer";

const MessagesSheet = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const [currentlyExpandedMessageId, setCurrentlyExpandedMessageId] = useState<string>("");

  const messages = useMessages();
  const readByCustomer = useReadByCustomer();

  const closeCurrentlyOpenMessage = () => {
    setCurrentlyExpandedMessageId("");
  };

  if (messages.query.isLoading || readByCustomer.query.isLoading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!messages.query.isSuccess || !messages.query.data || messages.query.data.length === 0) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("global.errorMessage.youDoNotHaveMessagesYet")}</Text>
      </SafeAreaView>
    );
  }

  const messagesReadByCustomer = readByCustomer.query.data?.readMessages ?? [];

  const messageList = messages.query
    .data!.map((message) => ({
      ...message,
      isRead: messagesReadByCustomer.includes(message.id),
    }))
    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

  const setMessageIdExpanded = (messageId: string) => {
    if (currentlyExpandedMessageId === messageId) {
      closeCurrentlyOpenMessage();
    } else {
      setCurrentlyExpandedMessageId(messageId);
      if (!messagesReadByCustomer.includes(messageId)) {
        readByCustomer.mutation.mutate({
          ...readByCustomer.query.data!,
          readMessages: [...messagesReadByCustomer, messageId],
        });
      }
    }
  };

  return (
    <ScrollView>
      <View style={styles.container}>
        {messageList.map((message, index) => (
          <ExpandableListItem
            key={message.id}
            message={message}
            expanded={message.id === currentlyExpandedMessageId}
            onExpand={setMessageIdExpanded}
          />
        ))}
      </View>
    </ScrollView>
  );
};
const createStyles = () =>
  StyleSheet.create({
    container: { paddingBottom: 50 },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
  });

export default MessagesSheet;
