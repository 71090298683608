import React, { useEffect, useMemo } from "react";
import { Text, useTheme } from "react-native-paper";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useQuery } from "@tanstack/react-query";
import { type VendorCustomerPoints } from "../../../models/VendorCustomer";
import VendorCustomerService from "../../../services/vendorCustomer.service";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "../../navigation/MainNavigation";
import { useLocation } from "../../../contexts/LocationContext";
import { useTranslation } from "react-i18next";
import useProfile from "../../../hooks/queries/useProfile";
import useVendor from "../../../hooks/queries/useVendor";
import useRewards from "../../../hooks/queries/useRewards";
import useVendorRewards from "../../../hooks/queries/useVendorRewards";
import Carousel from "../../../components/atoms/Carousel";
import RewardsCarouselItem from "./RewardsCarouselItem";

type Props = {
  vendorId: string;
};

export default function VendorRewards({ vendorId }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const { updateLocation } = useLocation();
  const { navigate } = useNavigation<MainNavigationProp>();
  const profile = useProfile();
  const vendor = useVendor({ id: vendorId, queryOnLoad: !!vendorId });
  const rewards = useRewards();
  const vendorRewards = useVendorRewards();

  const customerPointsForAllVendorsQuery = useQuery<VendorCustomerPoints[] | null>({
    queryKey: ["customerPointsForAllVendors"],
    queryFn: () => VendorCustomerService.getCustomerMetaForAllVendors(),
  });

  useEffect(() => {
    updateLocation();
  }, []);

  if (
    profile.query.isLoading ||
    vendor.query.isLoading ||
    customerPointsForAllVendorsQuery.isLoading ||
    rewards.query.isLoading
  ) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!vendor.query.isSuccess || !vendor.query.data) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <Text style={styles.notFoundText}>{t("vendorScreen.vendorNotFound")}</Text>
      </SafeAreaView>
    );
  }

  const customerOwnedStampsInAVendor =
    customerPointsForAllVendorsQuery.data?.find((x) => x.vendorId === vendorId)?.numberOfPoints ??
    0;

  const rewardList = vendorRewards.data
    ?.filter((reward) => reward.vendorId === vendorId && reward.active)
    ?.sort((a, b) => {
      return a.requiredPoints - b.requiredPoints;
    });

  return (
    <View style={styles.rewardInfoContactAddressContainer}>
      <Text style={styles.rewardInfoTitle} variant="titleMedium">
        {t("vendorScreen.rewardInfo")}
      </Text>
      <Text style={styles.customerOwnedStampsInAVendorText}>
        {t("vendorScreen.youHave")} {customerOwnedStampsInAVendor}{" "}
        {t(`global.stamp.${customerOwnedStampsInAVendor === 1 ? "singular" : "plural"}`)}
      </Text>
      <Carousel
        items={rewardList}
        renderItem={({ item }) => (
          <RewardsCarouselItem
            uid={profile.query.data?.uid ?? ""}
            item={item}
            userOwnedStampsInAVendor={customerOwnedStampsInAVendor}
            canRedeem={customerOwnedStampsInAVendor >= item.requiredPoints}
          />
        )}
      />
    </View>
  );
}

const createStyles = () =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
    },
    activityIndicator: {
      paddingTop: 20,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    rewardInfoContactAddressContainer: { marginTop: 30, paddingBottom: 50 },
    rewardInfoTitle: {
      textAlign: "left",
      marginHorizontal: 16,
    },
    customerOwnedStampsInAVendorText: {
      textAlign: "center",
      marginTop: 20,
    },
  });
