import React from "react";
import {
  Platform,
  SafeAreaView,
  StyleSheet,
  Modal,
  View,
  StatusBar,
} from "react-native";
import { Button } from "react-native-paper";
import IframeWebView from "../organisms/IframeWebView";
import WebView from "react-native-webview";
import { useTranslation } from "react-i18next";

interface WebViewModalProps {
  visible: boolean;
  onClose?: () => void;
  uri: string;
}

const WebViewModal: React.FC<WebViewModalProps> = ({
  visible,
  onClose,
  uri,
}) => {
  const { t } = useTranslation();
  return (
    <Modal visible={visible} onRequestClose={onClose} animationType="slide">
      {Platform.OS === "web" ? (
        <IframeWebView uri={uri} />
      ) : (
        <SafeAreaView style={styles.safeAreaView}>
          <WebView source={{ uri: uri }} />
        </SafeAreaView>
      )}
      <View style={styles.buttonContainer}>
        <Button
          mode="contained-tonal"
          labelStyle={styles.buttonText}
          onPress={onClose}
        >
          {t("global.close")}
        </Button>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    paddingTop: Platform.select({
      ios: 20,
    }),
  },
  buttonContainer: {
    alignItems: "center",
    marginVertical: 10,
    paddingBottom: Platform.select({ web: 20, ios: 20, android: 0 }),
  },
  buttonText: {
    fontSize: 20,
  },
});

export default WebViewModal;
