import React, { useMemo } from "react";
import { Text, useTheme } from "react-native-paper";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useQuery } from "@tanstack/react-query";
import { type VendorCustomerPoints } from "../../../models/VendorCustomer";
import VendorCustomerService from "../../../services/vendorCustomer.service";
import { useTranslation } from "react-i18next";
import useProfile from "../../../hooks/queries/useProfile";
import useVendor from "../../../hooks/queries/useVendor";
import useRewards from "../../../hooks/queries/useRewards";
import useVendorCoupons from "../../../hooks/queries/useVendorCoupons";
import Carousel from "../../../components/atoms/Carousel";
import CouponsCarouselItem from "./CouponsCarouselItem";

type Props = {
  vendorId: string;
};

export default function VendorCoupons({ vendorId }: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const profile = useProfile();
  const vendor = useVendor({ id: vendorId, queryOnLoad: !!vendorId });
  const rewards = useRewards();
  const vendorCoupons = useVendorCoupons();

  const customerPointsForAllVendorsQuery = useQuery<VendorCustomerPoints[] | null>({
    queryKey: ["customerPointsForAllVendors"],
    queryFn: () => VendorCustomerService.getCustomerMetaForAllVendors(),
  });

  if (
    profile.query.isLoading ||
    vendor.query.isLoading ||
    customerPointsForAllVendorsQuery.isLoading ||
    rewards.query.isLoading
  ) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!vendor.query.isSuccess || !vendor.query.data) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("vendorScreen.vendorNotFound")}</Text>
      </SafeAreaView>
    );
  }

  const customerOwnedStampsInAVendor =
    customerPointsForAllVendorsQuery.data?.find((x) => x.vendorId === vendorId)?.numberOfPoints ??
    0;

  const couponsList = vendorCoupons.data
    ?.filter((reward) => reward.vendorId === vendorId && reward.active)
    ?.sort((a, b) => {
      return a.lastUsageDate < b.lastUsageDate ? -1 : 1;
    });

  return (
    <View style={styles.rewardInfoContactAddressContainer}>
      <Text style={styles.rewardInfoTitle} variant="titleMedium">
        {t("vendorScreen.vendorCoupons")}
      </Text>
      {couponsList && couponsList.length > 0 ? (
        <Carousel
          items={couponsList}
          renderItem={({ item }) => (
            <CouponsCarouselItem
              uid={profile.query.data!.uid}
              item={item}
              userOwnedStampsInAVendor={customerOwnedStampsInAVendor}
              usesLeft={item.usesLeft}
            />
          )}
        />
      ) : (
        <Text style={styles.notFoundText}>{t("vendorScreen.noCouponsFound")}</Text>
      )}
    </View>
  );
}

const createStyles = () =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
    },
    listItem: {
      borderBottomWidth: 1,
      marginVertical: 8,
    },
    activityIndicator: {
      paddingTop: 20,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    image: {
      justifyContent: "center",
      resizeMode: "cover",
    },
    vendorNameAndFavoriteButtonContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 16,
    },
    vendorNameContainer: {
      flex: 1,
      alignItems: "flex-start",
      paddingRight: 20,
    },
    vendorNameText: {
      flexWrap: "wrap",
    },
    vendorIntroductionContainer: { marginHorizontal: 16 },
    distanceContainer: { flexDirection: "row", alignItems: "center" },
    distanceText: { marginLeft: 10 },
    tagsContainer: { flexDirection: "row", alignItems: "center" },
    tagsView: { marginLeft: 10 },
    rewardInfoContactAddressContainer: { marginTop: 30, paddingBottom: 50 },
    rewardInfoTitle: {
      textAlign: "left",
      marginHorizontal: 16,
    },
    customerOwnedStampsInAVendorText: {
      textAlign: "center",
      marginTop: 20,
    },
    checkoutRewardsText: { textAlign: "center", marginTop: 10 },
    contactContainer: { marginTop: 30, marginHorizontal: 16 },
    locationContainer: { marginTop: 30, marginHorizontal: 16 },
    addressTitle: {
      textAlign: "left",
      marginBottom: 10,
    },
    addressContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 10,
    },
    addressView: { marginLeft: 10 },
  });
