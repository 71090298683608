import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";

export default function useNavigationState() {
  const [navState, setNavState] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const savedStateString = localStorage.getItem("navigationState");
        const savedTabIndex = localStorage.getItem("navigationTabIndex");
        const state = savedStateString ? JSON.parse(savedStateString) : undefined;
        if (state) {
          setNavState(state);
        }
        if (savedTabIndex) {
          setTabIndex(Number(savedTabIndex));
        }
      })();
    }
  }, [isAuthenticated]);

  const updateTabIndex = (index: number) => {
    if (isAuthenticated) {
      localStorage.setItem("navigationTabIndex", index.toString());
    }
  };

  return { navState, tabIndex, updateTabIndex };
}
