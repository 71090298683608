import React from "react";
import { StyleProp, TextStyle, ViewStyle, ScrollView } from "react-native";
import { Dialog, Portal } from "react-native-paper";
interface QsolDialogProps {
  visible: boolean;
  hideDialog: () => void;
  title?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  scrollable?: boolean;
}

const QsolDialog: React.FC<QsolDialogProps> = ({
  visible,
  hideDialog,
  title,
  children,
  actions,
  style,
  titleStyle,
  scrollable,
}) => {
  const content = (
    <>
      <Dialog.Title style={titleStyle}>{title}</Dialog.Title>
      {children && <Dialog.Content>{children}</Dialog.Content>}
      {actions && <Dialog.Actions>{actions}</Dialog.Actions>}
    </>
  );

  return (
    <Portal>
      <Dialog style={style} visible={visible} onDismiss={hideDialog}>
        {scrollable ? <ScrollView>{content}</ScrollView> : content}
      </Dialog>
    </Portal>
  );
};

export default QsolDialog;
