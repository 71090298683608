import { useQuery, useQueryClient } from "@tanstack/react-query";
import VendorCustomerService from "../../services/vendorCustomer.service";

interface Properties {
  queryOnLoad?: boolean;
}

export default function useVendorCustomer(props?: Properties) {
  const queryKey = ["useVendorCustomer"];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: () => VendorCustomerService.getCustomerMetaForAllVendors(),
    enabled: props?.queryOnLoad ?? true,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return { query, invalidate };
}
