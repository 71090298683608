import i18next from "i18next";
import { Button } from "react-native-paper";

export default function LanguageSwitchButton() {
  if (i18next.language === "en") {
    return (
      <Button key="fi" onPress={() => i18next.changeLanguage("fi")}>
        Suomeksi
      </Button>
    );
  }

  if (i18next.language === "fi") {
    return (
      <Button key="en" onPress={() => i18next.changeLanguage("en")}>
        In English
      </Button>
    );
  }

  return null;
}
