import React, { useMemo } from "react";
import { Alert, Platform, StyleSheet } from "react-native";
import { Text, Button, useTheme, MD3Theme } from "react-native-paper";
import QsolDialog from "./QsolDialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import Logger from "../../utils/logger";
import { LogLevel } from "../../models/helpers/LogEvent";
import { useQsolBottomSheet } from "../../contexts/QsolBottomSheetContext";

interface SignoutConfirmationDialogProps {
  visible: boolean;
  hideDialog: () => void;
}

const SignoutConfirmationDialog: React.FC<SignoutConfirmationDialogProps> = ({
  visible,
  hideDialog,
}) => {
  const { t } = useTranslation();
  const { dismiss } = useQsolBottomSheet();
  const { logout } = useAuth();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const handleLogout = async () => {
    try {
      await logout();
      if (Platform.OS !== "web") {
        hideDialog();
        dismiss();
      }
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Failed to logout.",
        data: error,
      });
      Alert.alert(t("global.error"), t("global.errorMessage.failedToLogout"));
      console.error(error);
    }
  };

  return (
    <QsolDialog
      visible={visible}
      hideDialog={hideDialog}
      title={t("profileSheet.signout")}
      titleStyle={styles.dialogTitle}
      style={styles.dialogContainer}
      actions={
        <>
          <Button onPress={hideDialog}>{t("global.cancel")}</Button>
          <Button mode="contained-tonal" onPress={handleLogout}>
            {t("global.yes")}
          </Button>
        </>
      }
      children={
        <Text variant="bodyMedium">
          {t("profileSheet.signoutConfirmationDialogContent")}
        </Text>
      }
    />
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    dialogContainer: { backgroundColor: theme.colors.background },
    dialogTitle: {
      fontSize: 20,
      marginVertical: 16,
    },
  });

export default SignoutConfirmationDialog;
