import React, { useState } from "react";
import { Platform, SafeAreaView, ScrollView, StatusBar, StyleSheet, View } from "react-native";
import { List, Text } from "react-native-paper";
import TermsOfServiceModal from "../components/organisms/TermsOfServiceModal";
import PrivacyPolicyModal from "../components/organisms/PrivacyPolicyModal";
import FAQModal from "../components/organisms/FAQModal";
import DeleteAccount from "../components/organisms/DeleteAccount";
import ContactFormModal from "../components/organisms/ContactFormModal";
import ListItem from "../components/atoms/ListItem";
import { MainNavigationProp } from "./navigation/MainNavigation";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";

const MoreScreen = () => {
  const { t, i18n } = useTranslation();
  const [faqVisible, setFaqVisible] = useState<boolean>(false);
  const [contactFormVisible, setContactFormVisible] = useState<boolean>(false);
  const [termsOfServiceVisible, setTermsOfServiceVisible] = useState<boolean>(false);
  const [privatePolicyvisible, setPrivatePolicyvisible] = useState<boolean>(false);
  const [deleteAccountDialogVisible, setDeleteAccountDialogVisible] = useState<boolean>(false);

  const { navigate } = useNavigation<MainNavigationProp>();

  const goToAppNews = () => {
    navigate("AppNewsList");
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView>
        <View style={styles.container}>
          <Text variant="titleLarge" style={styles.title}>
            {t("moreScreen.moreOptions")}
          </Text>
          <List.Section>
            <View>
              <ListItem
                title="FAQ"
                leftIcon="comment-question-outline"
                rightIcon="chevron-right"
                style={styles.listItem}
                onPress={() => setFaqVisible(true)}
              />
              <ListItem
                title={t("moreScreen.contactQponly")}
                leftIcon="email-multiple-outline"
                rightIcon="chevron-right"
                style={styles.listItem}
                onPress={() => setContactFormVisible(true)}
              />
              <ListItem
                title={t("moreScreen.termsOfService")}
                leftIcon="file-document-outline"
                rightIcon="chevron-right"
                style={styles.listItem}
                onPress={() => setTermsOfServiceVisible(true)}
              />
              <ListItem
                title={t("moreScreen.privacyPolicy")}
                leftIcon="database-check-outline"
                rightIcon="chevron-right"
                style={styles.listItem}
                onPress={() => setPrivatePolicyvisible(true)}
              />
              {/* <ListItem
                title={t("moreScreen.appNews")}
                leftIcon="newspaper-variant-outline"
                rightIcon="chevron-right"
                style={styles.listItem}
                onPress={goToAppNews}
              /> */}
            </View>

            {/* <View style={styles.accountDeletionView}>
              <ListItem
                title={t("moreScreen.requestAccountDeletion")}
                titleStyle={styles.accountDeletionTitle}
                left={(props) => <List.Icon {...props} icon="delete-empty-outline" color="red" />}
                right={(props) => <List.Icon {...props} icon="chevron-right" color="red" />}
                style={styles.listItem}
                onPress={() => setDeleteAccountDialogVisible(true)}
              />
            </View> */}
          </List.Section>
          <FAQModal
            visible={faqVisible}
            onClose={() => setFaqVisible(false)}
            language={i18n.language}
          />
          <ContactFormModal
            visible={contactFormVisible}
            onClose={() => setContactFormVisible(false)}
            language={i18n.language}
          />
          <TermsOfServiceModal
            visible={termsOfServiceVisible}
            onClose={() => setTermsOfServiceVisible(false)}
            language={i18n.language}
          />
          <PrivacyPolicyModal
            visible={privatePolicyvisible}
            onClose={() => setPrivatePolicyvisible(false)}
            language={i18n.language}
          />
          <DeleteAccount
            visible={deleteAccountDialogVisible}
            onDismiss={() => setDeleteAccountDialogVisible(false)}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    paddingTop: Platform.select({
      ios: 0,
      android: StatusBar.currentHeight,
      web: 20,
    }),
  },
  container: { marginTop: 20 },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 6,
  },
  listItem: {
    paddingHorizontal: 20,
    paddingTop: 16,
    height: 70,
    borderBottomWidth: 1,
    // marginVertical: 8,
  },
  accountDeletionTitle: {
    color: "red",
  },
  accountDeletionView: { marginTop: 40 },
});

export default MoreScreen;
