import { InferType, bool, number, object, string } from "yup";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";
import { translatableSchema } from "./helpers/Translatable";

// PromotionTemplate
export const promotionTemplateSchema = object({
  id: string().required(),
  createdAt: firebaseTimestampSchema.required(),
  active: bool().required(),
  vendorId: string().required(),
  requiredPoints: number().required(),
  title: translatableSchema.required(),
  description: translatableSchema.nullable(),
  imageUrl: string().nullable().required(),
  startDate: firebaseTimestampSchema.required(),
  expiryDate: firebaseTimestampSchema.required(),
  updatedAt: firebaseTimestampSchema.required(),
});

export type PromotionTemplate = InferType<typeof promotionTemplateSchema>;
