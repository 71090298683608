import { InferType, object, string } from "yup";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

// AppNews
export const appNewsSchema = object({
  id: string().required(),
  createdAt: firebaseTimestampSchema.required(),
  title: string().required(),
  summary: string().required(),
  details: string().nullable(),
  imageUrl: string().nullable(),
  updatedAt: firebaseTimestampSchema.required(),
});

export type AppNews = InferType<typeof appNewsSchema>;
