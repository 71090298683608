import { ThemeProp } from "react-native-paper/lib/typescript/types";
import { fontConfig } from "./fonts";
import {
  MD3DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";
import {
  TonalPalette,
  argbFromHex,
  hexFromArgb,
  themeFromSourceColor,
} from "@material/material-color-utilities";

// To generate the palette from these values, go to
// https://www.figma.com/community/file/1035203688168086460/material-3-design-kit
// and enter the values, then export the values into a json and copy them here

const primaryHex = "#4685ff";
// const secondaryHex = "#f24a07";
// const tertiaryHex = "#f2f2f2";
// const errorHex = "#e46962";

const ionaColorHex = "#FDF87D";
const screenBackgroundHex = "#F8F8F8";
const ionaColor = TonalPalette.fromInt(argbFromHex(ionaColorHex));

const theme = themeFromSourceColor(argbFromHex(primaryHex), [
  // { value: argbFromHex(secondaryHex), name: "Secondary", blend: true },
  // { value: argbFromHex(tertiaryHex), name: "Tertiary", blend: true },
  // { value: argbFromHex(errorHex), name: "Error", blend: true },
]);

export const QponlyDefaultTheme: ThemeProp = {
  ...PaperDefaultTheme,
  isV3: true,
  fonts: fontConfig,
  colors: {
    ...PaperDefaultTheme.colors,

    primary: hexFromArgb(theme.palettes.primary.tone(40)),
    primaryContainer: hexFromArgb(theme.palettes.primary.tone(90)),
    onPrimary: hexFromArgb(theme.palettes.primary.tone(100)),
    onPrimaryContainer: hexFromArgb(theme.palettes.primary.tone(10)),
    secondary: hexFromArgb(theme.palettes.secondary.tone(40)),
    secondaryContainer: hexFromArgb(theme.palettes.secondary.tone(90)),
    onSecondary: hexFromArgb(theme.palettes.secondary.tone(100)),
    onSecondaryContainer: hexFromArgb(theme.palettes.secondary.tone(10)),
    tertiary: hexFromArgb(theme.palettes.tertiary.tone(40)),
    tertiaryContainer: hexFromArgb(theme.palettes.tertiary.tone(90)),
    onTertiary: hexFromArgb(theme.palettes.tertiary.tone(100)),
    onTertiaryContainer: hexFromArgb(theme.palettes.tertiary.tone(10)),

    error: hexFromArgb(theme.palettes.error.tone(40)),
    errorContainer: hexFromArgb(theme.palettes.error.tone(90)),
    onError: hexFromArgb(theme.palettes.error.tone(100)),
    onErrorContainer: hexFromArgb(theme.palettes.error.tone(10)),

    outline: hexFromArgb(theme.palettes.neutralVariant.tone(50)),

    inverseOnSurface: hexFromArgb(theme.palettes.neutral.tone(95)),
    inverseSurface: hexFromArgb(theme.palettes.neutral.tone(20)),
    inversePrimary: hexFromArgb(theme.palettes.primary.tone(80)),
    outlineVariant: hexFromArgb(theme.palettes.neutralVariant.tone(95)),
    shadow: hexFromArgb(theme.palettes.neutral.tone(0)),
    scrim: hexFromArgb(theme.palettes.neutral.tone(0)),

    surface: hexFromArgb(theme.palettes.neutral.tone(98)),
    onSurface: hexFromArgb(theme.palettes.neutral.tone(10)),
    surfaceVariant: hexFromArgb(theme.palettes.neutralVariant.tone(98)),
    onSurfaceVariant: hexFromArgb(theme.palettes.neutralVariant.tone(30)),

    surfaceDisabled: hexFromArgb(theme.palettes.neutral.tone(80)),
    onSurfaceDisabled: hexFromArgb(theme.palettes.neutral.tone(50)),
    elevation: {
      level0: "rgba(255, 255, 255, 0)", // transparent
      level1: hexFromArgb(theme.palettes.neutralVariant.tone(97)),
      level2: hexFromArgb(theme.palettes.neutralVariant.tone(94)),
      level3: hexFromArgb(theme.palettes.neutralVariant.tone(92)),
      level4: hexFromArgb(theme.palettes.neutralVariant.tone(90)),
      level5: hexFromArgb(theme.palettes.neutralVariant.tone(87)),
    },
    background: screenBackgroundHex, // Manual override (background should always be white)
    onBackground: "#1d1b20", // Manual override (palette doesn't have a tone for this)
  },
} as const;

export const QponlyNavbarTheme: ThemeProp = {
  isV3: true,
  colors: {
    // secondaryContainer: hexFromArgb(theme.palettes.secondary.tone(90)), // selected item container
    secondaryContainer: screenBackgroundHex, // selected item container
    onSecondaryContainer: hexFromArgb(theme.palettes.secondary.tone(10)), // selected icon
    onSurfaceVariant: hexFromArgb(theme.palettes.neutral.tone(0)), // unselected icon and label color
    elevation: {
      level0: hexFromArgb(theme.palettes.secondary.tone(90)), // make the search bar icon background not white
      level2: "#fff", // navbar background color
    },
  },
} as const;

export const reactNavigationTheme = {
  dark: false,
  colors: {
    primary: hexFromArgb(theme.palettes.primary.tone(40)),
    background: hexFromArgb(theme.palettes.neutral.tone(100)),
    card: hexFromArgb(theme.palettes.neutral.tone(100)),
    text: hexFromArgb(theme.palettes.neutral.tone(0)),
    border: hexFromArgb(theme.palettes.neutralVariant.tone(95)),

    notification: hexFromArgb(theme.palettes.primary.tone(40)),
  },
} as const;

// export const QponlyDarkTheme: ThemeProp = {
//   ...PaperDarkTheme,
//   isV3: true,
//   fonts: fontConfig,
//   colors: {
//     ...PaperDarkTheme.colors,
//     primary: "#94d2bd",
//     background: "#000000",
//     // Define other colors if needed
//   },
// } as const;
