import React from "react";
import WebViewModal from "../molecules/WebViewModal";

interface PrivacyPolicyModalProps {
  visible: boolean;
  onClose?: () => void;
  language: string;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ visible, onClose, language }) => {
  const privacyUrl = `https://qponly.app/${language}/privacy?prevent-navigation`;

  return <WebViewModal uri={privacyUrl} visible={visible} onClose={onClose} />;
};

export default PrivacyPolicyModal;
