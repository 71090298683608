import React, { useMemo, useState } from "react";
import { ActivityIndicator, MD3Theme, useTheme, Text } from "react-native-paper";
import { type AppNews } from "../models/AppNews";
import { ScrollView, View, StyleSheet, SafeAreaView } from "react-native";
import ListItem from "../components/atoms/ListItem";
import { MainNavigationProp } from "./navigation/MainNavigation";
import { useNavigation } from "@react-navigation/native";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import AppNewsService from "../services/appNews.service";
import ReadByCustomerService from "../services/readByCustomer.service";
import { type ReadByCustomer } from "../models/ReadByCustomer";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { useTranslation } from "react-i18next";

const AppNewsListScreen = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { navigate } = useNavigation<MainNavigationProp>();
  const [currentlyExpandedAppNewsId, setCurrentlyExpandedAppNewsId] = useState<string>("");

  const appNewsQuery = useQuery<AppNews[] | null>({
    queryKey: ["appNews"],
    queryFn: () => AppNewsService.getAll(),
  });

  const readByCustomerQuery = useQuery<ReadByCustomer | null>({
    queryKey: ["readByCustomer"],
    queryFn: () => ReadByCustomerService.get(),
  });

  const readByCustomerMutation = useMutation({
    mutationFn: ReadByCustomerService.set,
    onSuccess: (data) => {
      queryClient.setQueryData(["readByCustomer"], data);
    },
    onError: (error) => {
      Logger.error(LogLevel.Error, {
        message:
          "Error updating readByCustomer in AppNewsListScreen component: ReadByCustomerService.upsert.",
        error,
      });
    },
  });

  const closeCurrentlyOpenAppNews = () => {
    setCurrentlyExpandedAppNewsId("");
  };

  if (appNewsQuery.isLoading || readByCustomerQuery.isLoading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!appNewsQuery.isSuccess || !appNewsQuery.data) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("global.errorMessage.youDoNotHaveAppNewsYet")}</Text>
      </SafeAreaView>
    );
  }
  const appNewsReadByCustomer = readByCustomerQuery.data!.readNews ?? [];
  const appNewsList = appNewsQuery
    .data!.map((news) => ({
      ...news,
      isRead: appNewsReadByCustomer.includes(news.id),
    }))
    .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

  const setAppNewsIdExpanded = (appNewsId: string) => {
    if (currentlyExpandedAppNewsId === appNewsId) {
      closeCurrentlyOpenAppNews();
    } else {
      setCurrentlyExpandedAppNewsId(appNewsId);
      if (!appNewsReadByCustomer.includes(appNewsId)) {
        readByCustomerMutation.mutate({
          ...readByCustomerQuery.data!,
          readNews: [...appNewsReadByCustomer, appNewsId],
        });
      }
    }
  };

  const goToAppNewsDetail = (appNewsId: string) => {
    setAppNewsIdExpanded(appNewsId);
    navigate("AppNewsDetails", { appNewsId });
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView>
        <View style={styles.container}>
          {appNewsList.map((news, index) => (
            <ListItem
              key={news.id}
              title={news.title}
              description={news.summary}
              style={[
                styles.listItem,
                !news.isRead && {
                  backgroundColor: theme.colors.elevation.level1,
                },
              ]}
              onPress={() => goToAppNewsDetail(news.id)}
            />
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    safeAreaView: { flex: 1 },
    container: { paddingBottom: 50 },
    listItem: {
      borderBottomWidth: 1,
      borderColor: theme.colors.outlineVariant,
    },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
  });
export default AppNewsListScreen;
