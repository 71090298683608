import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { Icon, Text, useTheme } from "react-native-paper";
import { MD3Theme } from "react-native-paper/lib/typescript/types";
import { useTranslation } from "react-i18next";
import RedeemableItem from "../molecules/RedeemableItem";
import useVendorRewards from "../../hooks/queries/useVendorRewards";
import { VIEW_PADDING } from "../../constants/styles";

const HomeScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const vendorRewards = useVendorRewards();

  if (!vendorRewards.data || vendorRewards.data.length === 0) {
    return (
      <Text>
        <Icon size={20} source="emoticon-sad-outline" />{" "}
        {t("redeemableRewardsComponent.noAvailableRewards")}
      </Text>
    );
  }

  return (
    <View style={styles.redeemableRewards}>
      {vendorRewards.data
        .filter((x) => x.userPointsForVendor >= x.requiredPoints)
        .map((redeemableItem) => (
          <RedeemableItem key={redeemableItem.id} redeemableItem={redeemableItem} />
        ))}
    </View>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    redeemableRewards: {
      gap: VIEW_PADDING,
    },
    redeemableRewardsTitle: {
      textAlign: "center",
      width: "100%",
      paddingBottom: 10,
      fontWeight: "800",
    },
  });

export default HomeScreen;
