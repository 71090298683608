import React, { useMemo } from "react";
import { StyleSheet } from "react-native";

interface IframeWebViewProps {
  uri: string;
}
const IframeWebView: React.FC<IframeWebViewProps> = ({ uri }) => {
  const styles = useMemo(() => createStyles(), []);
  return <iframe src={uri} style={styles.container} title="WebView"></iframe>;
};

const createStyles = () =>
  StyleSheet.create({
    container: { width: "100%", height: "100%" },
  });

export default IframeWebView;
