import React from "react";
import { Image, ImageStyle, StyleProp } from "react-native";
import Logger from "../../utils/logger";
import { LogLevel } from "../../models/helpers/LogEvent";
import { EXPO_PUBLIC_HOSTIP } from "@env";

interface QsolImageProps {
  uri: string | undefined;
  fallbackImage?: string;
  style?: StyleProp<ImageStyle>;
}
const QsolImage: React.FC<QsolImageProps> = ({ uri, style, fallbackImage }) => {
  let imageUriWithFallback = uri;

  if (!imageUriWithFallback) {
    const storageEmulatorUrl = "http://127.0.0.1:9199/v0/b/qsol-qponly.appspot.com";
    const storageUrl = "https://firebasestorage.googleapis.com/v0/b/qsol-qponly.appspot.com";
    imageUriWithFallback =
      (__DEV__ ? storageEmulatorUrl : storageUrl) + `/o/fallbackImages/${fallbackImage}?alt=media`;
  }

  if (__DEV__) {
    imageUriWithFallback = imageUriWithFallback.replace("127.0.0.1", EXPO_PUBLIC_HOSTIP);
  }

  return (
    <Image
      source={{ uri: imageUriWithFallback }}
      style={style}
      onError={(error) =>
        Logger.error(LogLevel.Error, {
          message: "Failed to load image",
          imageUri: imageUriWithFallback,
          error: error,
        })
      }
    />
  );
};

export default QsolImage;
