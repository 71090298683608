import { Session } from "@supabase/supabase-js";
import { useEffect } from "react";
import { auth, signInWithToken } from "../lib/firebase";
import { getToken } from "../lib/firebase/functions";

export default function ensureFirebaseSession(
  loading: boolean,
  supabaseSession: Session | undefined
) {
  useEffect(() => {
    (async () => {
      if (loading) return;
      if (supabaseSession) {
        const firebaseToken = await getToken({
          uid: supabaseSession.user.id,
          accessToken: supabaseSession.access_token,
        });
        await signInWithToken(firebaseToken);
      } else {
        auth.signOut();
      }
    })();
  }, [supabaseSession?.access_token]);
}
