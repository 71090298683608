import { initializeApp } from "firebase/app";
import { connectAuthEmulator, initializeAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  EXPO_PUBLIC_APIKEY,
  EXPO_PUBLIC_AUTHDOMAIN,
  EXPO_PUBLIC_PROJECTID,
  EXPO_PUBLIC_STORAGEBUCKET,
  EXPO_PUBLIC_MESSAGINGSENDERID,
  EXPO_PUBLIC_APPID,
  EXPO_PUBLIC_MEASUREMENTID,
  EXPO_PUBLIC_HOSTIP,
} from "@env";
import ReactNativeAsyncStorage from "@react-native-async-storage/async-storage";
import { getReactNativePersistence } from "./persistence/react_native";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: EXPO_PUBLIC_APIKEY,
  authDomain: EXPO_PUBLIC_AUTHDOMAIN,
  projectId: EXPO_PUBLIC_PROJECTID,
  storageBucket: EXPO_PUBLIC_STORAGEBUCKET,
  messagingSenderId: EXPO_PUBLIC_MESSAGINGSENDERID,
  appId: EXPO_PUBLIC_APPID,
  measurementId: EXPO_PUBLIC_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const _auth = initializeAuth(app, {
  persistence: getReactNativePersistence(ReactNativeAsyncStorage),
});
_auth.languageCode = "fi";

export const auth = _auth;
export const functions = getFunctions(app, "europe-north1");
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// Use emulator if localhost
if (__DEV__) {
  const firebaseEmulatorHost = EXPO_PUBLIC_HOSTIP;
  connectAuthEmulator(auth, `http://${firebaseEmulatorHost}:9099`);
  connectFunctionsEmulator(functions, firebaseEmulatorHost, 5001);
  connectFirestoreEmulator(firestore, firebaseEmulatorHost, 8080);
  connectStorageEmulator(storage, firebaseEmulatorHost, 9199);
}

const appCheckToken = __DEV__
  ? "556f4628-4adc-496c-90e3-68e866d08d6f"
  : process.env.EXPO_PUBLIC_RECAPTCHA_SITEKEY;
if (__DEV__) {
  (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckToken;
}
if (appCheckToken) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(appCheckToken),
    isTokenAutoRefreshEnabled: true,
  });
}

export const signInWithToken = async (token: string) => {
  signInWithCustomToken(auth, token);
};

export default app;
