import React, { useMemo, useRef, useState } from "react";
import { View, ScrollView, Animated, StyleSheet, TouchableOpacity } from "react-native";
import { Card, IconButton, Text, List, useTheme, MD3Theme } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "../../screens/navigation/MainNavigation";
import QsolImage from "../atoms/QsolImage";
import { useTranslation } from "react-i18next";
import { dbt } from "../../utils";
import { VendorReward } from "../../models/VendorRewards";

interface StampCardProps {
  vendorName: string;
  vendorId: string;
  points: number;
  availableRewards: VendorReward[];
}

const StampCard: React.FC<StampCardProps> = ({
  vendorName,
  vendorId,
  points,
  availableRewards,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { navigate } = useNavigation<MainNavigationProp>();
  const animationMs = 400;
  const [isGiftOpen, setIsGiftOpen] = useState(false);

  const toggleIcon = () => {
    if (isGiftOpen) {
      animateHideRewards();
      setTimeout(() => {
        setIsGiftOpen(false);
      }, animationMs);
    } else {
      setIsGiftOpen(true);
      animateShowRewards();
    }
  };

  const heightAnim = useRef(new Animated.Value(0)).current;

  const animateShowRewards = () => {
    Animated.timing(heightAnim, {
      toValue: 80,
      duration: animationMs,
      useNativeDriver: false,
    }).start();
  };

  const animateHideRewards = () => {
    Animated.timing(heightAnim, {
      toValue: 0,
      duration: animationMs,
      useNativeDriver: false,
    }).start();
  };

  const handleNavigateToVendor = () => {
    navigate("Vendor", { vendorId: vendorId });
  };

  const multipleRewards = t("stampCardListComponent.availableReward.plural");
  const singleReward = t("stampCardListComponent.availableReward.singular");

  return (
    <Card
      style={[styles.cardContainer, !isGiftOpen && styles.cardContainerClosed]}
      mode="contained"
    >
      <TouchableOpacity onPress={handleNavigateToVendor} activeOpacity={1}>
        <Text variant="titleMedium" style={styles.stampSubHeading}>
          {vendorName}
        </Text>
        <View style={styles.stampsAndButtonView}>
          <View style={styles.textContainer}>
            <Text variant="titleMedium" style={styles.text}>
              {points} {t(`global.stamp.${points > 1 ? "plural" : "singular"}`)}
              {availableRewards.length > 0
                ? `, ${availableRewards.length} ${
                    availableRewards.length > 1 ? multipleRewards : singleReward
                  }`
                : ""}
            </Text>
          </View>
          <IconButton
            icon={isGiftOpen ? "gift-open" : "gift"}
            mode="contained"
            onPress={toggleIcon}
            disabled={availableRewards.length === 0}
          />
        </View>
      </TouchableOpacity>
      {isGiftOpen && (
        <Animated.View style={[styles.stampView, { height: heightAnim }]}>
          <ScrollView contentContainerStyle={styles.rewardsView} horizontal>
            <Card.Content style={styles.content}>
              {availableRewards.map((reward) => (
                <TouchableOpacity key={reward.id} onPress={handleNavigateToVendor}>
                  <List.Item
                    style={styles.rewardContainer}
                    title={
                      <View style={styles.rewardImageAndTitleContainer}>
                        <QsolImage
                          uri={reward.imageUrl ?? undefined}
                          style={styles.rewardImage}
                          fallbackImage="reward.webp"
                        />
                        <View style={styles.rewardTitleContainer}>
                          <Text>{dbt(i18n.language, reward.title)}</Text>
                        </View>
                      </View>
                    }
                  />
                </TouchableOpacity>
              ))}
            </Card.Content>
          </ScrollView>
        </Animated.View>
      )}
    </Card>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    cardContainer: {
      justifyContent: "center",
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: theme.colors.elevation.level3,
      borderWidth: 1,
      borderColor: theme.colors.elevation.level3,
    },
    cardContainerClosed: {
      paddingBottom: 8,
    },
    textContainer: { flex: 1 },
    text: {
      flexWrap: "wrap",
    },
    stampSubHeading: {
      position: "relative",
      textAlign: "left",
      padding: 10,
      fontWeight: "800",
      paddingBottom: 0,
      fontSize: 16,
    },
    rewardImageAndTitleContainer: {
      flexDirection: "row",
      alignItems: "center",
      height: "100%",
    },
    rewardTitleContainer: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    rewardContainer: {
      justifyContent: "center",
    },
    rewardsView: {
      flexDirection: "row",
      alignItems: "center",
      flexGrow: 1,
      height: 80,
    },
    rewardImage: {
      width: 50,
      height: 50,
      marginRight: 10,
      objectFit: "cover",
    },
    stampsAndButtonView: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 5,
      width: "100%",
      height: 50,
    },
    content: {
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: 8,
    },
    stampView: {
      backgroundColor: theme.colors.background,
      // backgroundColor: theme.colors.elevation.level1,
      borderRadius: 8,
      margin: 8,
      marginTop: 0,
    },
  });

export default StampCard;
