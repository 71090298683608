import {
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
  collectionGroup,
  setDoc,
  DocumentData,
} from "firebase/firestore";
import { firestore as db, functions } from "..";
import { CallableFunc, FirebaseQueryConstraint } from "../helpers";
import { throwAndLogMessage } from "../../../utils";
import { Customer } from "../../../models/Customer";
import { httpsCallable } from "firebase/functions";
import { supabase } from "../../supabase";
import { User } from "@supabase/supabase-js";

export async function tryGetCurrentUser(): Promise<User | null> {
  try {
    const { data } = await supabase.auth.getUser();
    return data.user;
  } catch (error) {
    console.log("User was not logged in");
    return null;
  }
}

export async function getOptionalDocumentById<T>(
  collectionPath: string,
  documentId: string
): Promise<T | null> {
  const docRef = doc(db, collectionPath, documentId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? (docSnap.data() as T) : null;
}

export async function getDocumentById<T>(collectionPath: string, documentId: string): Promise<T> {
  const document = await getOptionalDocumentById<T>(collectionPath, documentId);
  if (document === null) {
    throw new Error(`Document '${documentId}' does not exist in collection '${collectionPath}'`);
  }
  return document;
}

export async function getDocuments<T>(
  collectionPath: string,
  ...queryConstraints: FirebaseQueryConstraint[]
): Promise<T[]> {
  const customersRef = collection(db, collectionPath);
  const constraints = queryConstraints.map((constraint) => {
    return where(constraint.property, constraint.operator, constraint.value);
  });
  const q = query(customersRef, ...constraints);
  const querySnapshot = await getDocs(q);
  const documents: T[] = [];
  querySnapshot.forEach((document) => {
    documents.push({ ...document.data(), id: document.id } as T);
  });
  return documents;
}

export async function getCollectionGroup<T>(
  collectionPath: string,
  ...queryConstraints: FirebaseQueryConstraint[]
): Promise<T[]> {
  const customersRef = collectionGroup(db, collectionPath);
  const constraints = queryConstraints.map((constraint) => {
    return where(constraint.property, constraint.operator, constraint.value);
  });
  const q = query(customersRef, ...constraints);
  const querySnapshot = await getDocs(q);
  const documents: T[] = [];
  querySnapshot.forEach((document) => {
    documents.push(document.data() as T);
  });
  return documents;
}

export async function setDocument<T>(
  collectionPath: string,
  documentId: string,
  data: Partial<T>
): Promise<void> {
  const docRef = doc(db, collectionPath, documentId);
  await setDoc(docRef, data as DocumentData);
}

export async function updateDocument<T>(
  collectionPath: string,
  documentId: string,
  data: Partial<T>
): Promise<void> {
  const docRef = doc(db, collectionPath, documentId);
  await updateDoc(docRef, data);
}

export async function updateUserDocument(
  collectionPath: string,
  newData: Partial<Customer>
): Promise<void> {
  const currentUser = await tryGetCurrentUser();
  if (!currentUser) {
    throwAndLogMessage("User is not logged in");
  }
  await updateDocument<Customer>(collectionPath, currentUser.id, newData);
}

export async function getCloudFunction<Tin = unknown, Tout = unknown>(
  functionName: string
): Promise<CallableFunc<Tin, Tout>> {
  const cloudFunction = await httpsCallable<Tin, Tout>(functions, functionName);
  return cloudFunction;
}
