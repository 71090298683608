import { InferType, bool, number, object, string } from "yup";
import { translatableSchema } from "./helpers/Translatable";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

export const couponSchema = object({
  id: string().when("$isNew", ([isNew], schema) =>
    isNew === true ? schema.notRequired() : schema.defined()
  ),
  vendorId: string().defined(),
  active: bool().defined().default(true),
  title: translatableSchema.required().defined(),
  description: translatableSchema.default(null).nullable(),
  visibleForCustomers: bool().defined().default(false),
  lastUsageDate: firebaseTimestampSchema,
  imageUrl: string().optional(),
  discountValue: number().positive().optional(),
  hasUsageLimitPerCustomer: bool().defined().default(false),
  usageLimitPerCustomer: number().when("hasUsageLimitPerCustomer", (hasUsageLimitPerCustomer) =>
    hasUsageLimitPerCustomer
      ? number().defined().positive().integer().default(1)
      : number().optional().default(0)
  ),
});
export type CouponWithoutId = InferType<typeof couponSchema>;
export type Coupon = InferType<typeof couponSchema> & { id: string };
export type CouponWithUsesLeft = Coupon & { usesLeft: number };
