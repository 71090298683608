import React from "react";
import { AuthProvider } from "./src/contexts/AuthContext";
import MainNavigation from "./src/screens/navigation/MainNavigation";
import {
  GestureHandlerRootView,
  enableLegacyWebImplementation,
} from "react-native-gesture-handler";
import { Provider as PaperProvider } from "react-native-paper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import QsolBottomSheetProvider from "./src/contexts/QsolBottomSheetContext/Provider";
import { StatusBar } from "expo-status-bar";
import { QponlyDefaultTheme, reactNavigationTheme } from "./src/constants/theme";
import { LocationProvider } from "./src/contexts/LocationContext";
import { loadFonts } from "./src/utils/fonts";
import { LogBox } from "./src/utils/customLogBox";
import "./src/locales/i18n";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Platform } from "react-native";
import QsolNavigationContainer from "./src/screens/navigation/QsolNavigationContainer";
import ToastProvider from "./src/components/atoms/ToastProvider";

enableLegacyWebImplementation(true);

LogBox.ignoreLogs([
  "No native ExpoFirebaseCore module found, are you sure the expo-firebase-core module is linked properly?",
  "WARNING: You are using the Auth Emulator, which is intended for local testing only.  Do not use with production credentials.",
  "Animated: `useNativeDriver` is not supported because the native animated module is missing. Falling back to JS-based animation. To resolve this, add `RCTAnimation` module to this app, or remove `useNativeDriver`. Make sure to run `bundle exec pod install` first. Read more about autolinking: https://github.com/react-native-community/cli/blob/master/docs/autolinking.md",
]);

const tenMinutesInMilliseconds = 1000 * 60 * 10;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: tenMinutesInMilliseconds,
      staleTime: tenMinutesInMilliseconds,
    },
  },
});

export default function App() {
  const [fontsLoaded, fontError] = loadFonts();

  if (!fontsLoaded || fontError) {
    return null;
  }
  const theme = QponlyDefaultTheme;

  return (
    <React.StrictMode>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <StatusBar style="dark" />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <PaperProvider theme={theme}>
              <QsolNavigationContainer theme={reactNavigationTheme}>
                <QsolBottomSheetProvider>
                  <LocationProvider>
                    <MainNavigation />
                    <ToastProvider />
                    {Platform.OS === "web" && <ReactQueryDevtools />}
                  </LocationProvider>
                </QsolBottomSheetProvider>
              </QsolNavigationContainer>
            </PaperProvider>
          </AuthProvider>
        </QueryClientProvider>
      </GestureHandlerRootView>
    </React.StrictMode>
  );
}
