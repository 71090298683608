import {
  VendorCustomer,
  VendorCustomerPoints,
  vendorCustomerSchema,
} from "../models/VendorCustomer";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { getCollectionGroup, tryGetCurrentUser } from "../lib/firebase/connectors";
import { where } from "../lib/firebase/helpers";
import { logValidationErrors, throwAndLogMessage } from "../utils";
import { array } from "yup";

export const vendorCustomerCollection = "vendorCustomers";

export default class VendorCustomerService {
  static getCustomerMetaForAllVendors = async (): Promise<VendorCustomerPoints[] | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    try {
      const vendorCustomers = await getCollectionGroup<VendorCustomer>(
        vendorCustomerCollection,
        where("uid", "==", currentUser.id)
      );
      logValidationErrors(
        vendorCustomers,
        array().of(vendorCustomerSchema),
        "VendorCustomerService.getCustomerPointsForAllVendor"
      );
      return vendorCustomers.map((vendorCustomer) => ({
        uid: vendorCustomer.uid,
        vendorId: vendorCustomer.vendorId,
        numberOfPoints: vendorCustomer.numberOfPoints,
        pointsUpdatedAt: vendorCustomer.pointsUpdatedAt,
        couponUsage: vendorCustomer.couponUsage,
      }));
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting VendorCustomers",
        error,
      });
      throw new Error("Error getting VendorCustomers");
    }
  };
}
