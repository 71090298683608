import React, { useMemo } from "react";
import { StyleProp, ViewStyle, StyleSheet, TextStyle } from "react-native";
import { List, MD3Theme, useTheme } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { Style } from "react-native-paper/lib/typescript/components/List/utils";

interface ListItemProps {
  title: string;
  description?: string;
  left?: (props: { color: string; style?: Style }) => React.ReactNode;
  leftIcon?: IconSource;
  right?: (props: { color: string; style?: Style }) => React.ReactNode;
  rightIcon?: IconSource;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  onPress?(): void;
}

const ListItem: React.FC<ListItemProps> = ({
  title,
  description,
  left,
  leftIcon,
  right,
  rightIcon,
  style,
  titleStyle,
  onPress,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <List.Item
      title={title}
      description={description}
      left={(props) => left ? left(props) : leftIcon && <List.Icon {...props} icon={leftIcon} />}
      right={(props) => right ? right(props) : rightIcon && <List.Icon {...props} icon={rightIcon} />}
      style={[style, styles.listItem]}
      titleStyle={[titleStyle, styles.listItem]}
      onPress={onPress}
    />
  );
};

const createStyles = (theme: MD3Theme) => StyleSheet.create({
  listItem: {
    borderBottomColor: theme.colors.outlineVariant,
  }
})


export default ListItem;
