import { InferType, object, string } from "yup";

export const translatableSchema = object({
  en: string().optional().notRequired().nullable(),
  fi: string().optional().notRequired().nullable(),
});

type Maybe<T> = T | null | undefined;
export type Translatable = InferType<typeof translatableSchema> & {
  [key: string]: Maybe<string | undefined>; // Index signature
};