import React, { useMemo, useState } from "react";
import StampCard from "../molecules/StampCard";
import { Text, Searchbar, ActivityIndicator, useTheme } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { useQuery } from "@tanstack/react-query";
import { type RewardTemplate } from "../../models/RewardTemplate";
import { type VendorCustomerPoints } from "../../models/VendorCustomer";
import { type Vendor } from "../../models/Vendor";
import VendorService from "../../services/vendor.service";
import RewardService from "../../services/reward.service";
import VendorCustomerService from "../../services/vendorCustomer.service";
import { capitalizeEachWord } from "../../utils";
import { useTranslation } from "react-i18next";
import useRewards from "../../hooks/queries/useRewards";
import useVendorRewards from "../../hooks/queries/useVendorRewards";

const StampCardList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onChangeSearch = (query: string) => setSearchQuery(query);
  const rewards = useVendorRewards();

  // 1. Get all customerPointsForAllVendors and vendors
  // 2. Map the customerPointsForAllVendors to include vendor name
  // 3. Filter the customerPointsForAllVendors by searchQuery
  // 4. Sort the customerPointsForAllVendors by pointsUpdatedAt
  // 5. Map the customerPointsForAllVendors to include availableRewards
  // 6. Render the stamp card list
  const customerPointsForAllVendorsQuery = useQuery<VendorCustomerPoints[] | null>({
    queryKey: ["customerPointsForAllVendors"],
    queryFn: () => VendorCustomerService.getCustomerMetaForAllVendors(),
  });

  const vendorsQuery = useQuery<Vendor[] | null>({
    queryKey: ["vendors"],
    queryFn: () => VendorService.getAll(),
  });

  if (customerPointsForAllVendorsQuery.isLoading || vendorsQuery.isLoading || rewards.loading) {
    return (
      <ActivityIndicator
        style={styles.activityIndicator}
        animating={true}
        color={theme.colors.primary}
      />
    );
  }

  const customerPointsForAllVendorsWithVendorInfo = customerPointsForAllVendorsQuery.data?.map(
    (customerPoint) => {
      const vendor = vendorsQuery.data?.find((vendor) => vendor.id === customerPoint.vendorId);
      return {
        ...customerPoint,
        name: vendor?.name ?? "",
      };
    }
  );

  const filteredCustomerPointsForAllVendorsWithVendorInfo =
    customerPointsForAllVendorsWithVendorInfo?.filter((customerPointWithVendor) =>
      customerPointWithVendor.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const sortedFilteredCustomerPointsForAllVendorsWithVendorInfoWithRewards =
    filteredCustomerPointsForAllVendorsWithVendorInfo
      ?.map((customerPointWithVendor) => {
        const filteredRewards = rewards.data?.filter(
          (reward) => reward.vendorId === customerPointWithVendor.vendorId
        );
        return {
          ...customerPointWithVendor,
          availableRewards: filteredRewards,
        };
      })
      .sort((a, b) => {
        return b.pointsUpdatedAt.seconds - a.pointsUpdatedAt.seconds;
      });

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <Searchbar
        style={styles.searchBar}
        placeholder={t("stampCardListComponent.searchYourStampCard")}
        onChangeText={onChangeSearch}
        value={searchQuery}
      />

      {!sortedFilteredCustomerPointsForAllVendorsWithVendorInfoWithRewards ||
      sortedFilteredCustomerPointsForAllVendorsWithVendorInfoWithRewards.length === 0 ? (
        <Text style={styles.stampText}>{t("stampCardListComponent.noStampCardFound")}</Text>
      ) : (
        sortedFilteredCustomerPointsForAllVendorsWithVendorInfoWithRewards.map((data, index) => (
          <StampCard
            key={index}
            vendorId={data.vendorId}
            vendorName={capitalizeEachWord(data.name)}
            points={data.numberOfPoints}
            availableRewards={data.availableRewards ?? []}
          />
        ))
      )}
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    stampText: {
      textAlign: "center",
      paddingTop: 20,
    },
    activityIndicator: {
      marginTop: 10,
    },
    searchBar: {
      width: "100%",
      marginVertical: 10,
      alignSelf: "center",
    },
  });

export default StampCardList;
