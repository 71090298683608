import { createContext, useContext } from "react";
import { BottomSheetNavigationKey } from "../../screens/navigation/BottomSheetNavigation";

type IQsolBottomSheetContext = {
  show(route?: BottomSheetNavigationKey): void;
  dismiss(): void;
  allowDismiss(): void;
  preventDismiss(): void;
};

export const QsolBottomSheetContext = createContext<IQsolBottomSheetContext | undefined>(undefined);

export function useQsolBottomSheet(): IQsolBottomSheetContext {
  const context = useContext(QsolBottomSheetContext);

  if (context === undefined) {
    throw new Error("useQsolBottomSheet must be used within a QsolBottomSheetProvider");
  }

  return context;
}
