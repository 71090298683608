import { Inter_400Regular, Inter_500Medium } from "@expo-google-fonts/inter";
import { useFonts } from "expo-font";
import TwemojiMozilla from '../../node_modules/react-native-international-phone-number/lib/assets/fonts/TwemojiMozilla.woff2';

export const loadFonts = () => {
  let [fontsLoaded, fontError] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    TwemojiMozilla,
  });

  return [fontsLoaded, fontError];
};
