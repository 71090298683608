import React from "react";
import WebViewModal from "../molecules/WebViewModal";

interface ContactFormModalProps {
  visible: boolean;
  onClose?: () => void;
  language: string;
}

const ContactFormModal: React.FC<ContactFormModalProps> = ({ visible, onClose, language }) => {
  const contactFormUri = `https://qponly.app/${language}/contact?prevent-navigation`;

  return <WebViewModal uri={contactFormUri} visible={visible} onClose={onClose} />;
};

export default ContactFormModal;
