import { useEffect, useState } from "react";
import { VendorReward } from "../../models/VendorRewards";
import useVendors from "./useVendors";
import useCoupons from "./useCoupons";
import { VendorCoupon } from "../../models/VendorCoupon";
import useVendorCustomer from "./usePoints";

export interface Properties {
  queryOnLoad?: boolean;
}

export default function useVendorCoupons() {
  const coupons = useCoupons();
  const vendorCustomer = useVendorCustomer();
  const loading = coupons.query.isLoading || vendorCustomer.query.isLoading;
  const [data, setData] = useState<VendorCoupon[]>([]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setData(
      coupons.query.data?.map((x) => {
        const vendor = vendorCustomer.query.data?.find((y) => y.vendorId === x.vendorId);
        const couponUsage = vendor?.couponUsage?.find((y) => y.couponId === x.id);
        const usageLimit = x.hasUsageLimitPerCustomer ? x.usageLimitPerCustomer : undefined;
        const usesLeft = usageLimit ? usageLimit - (couponUsage?.timesUsed ?? 0) : null;
        const coupon: VendorCoupon = {
          id: x.id,
          vendorId: x.vendorId,
          active: x.active,
          title: x.title,
          description: x.description ?? {},
          discountValue: x.discountValue,
          imageUrl: x.imageUrl,
          hasUsageLimitPerCustomer: x.hasUsageLimitPerCustomer,
          usageLimitPerCustomer: x.usageLimitPerCustomer,
          visibleForCustomers: x.visibleForCustomers,
          lastUsageDate: x.lastUsageDate,
          usesLeft,
        };
        return coupon;
      }) ?? []
    );
  }, [coupons.query.data, vendorCustomer.query.data, loading]);

  const invalidate = () => {
    coupons.invalidate();
  };

  return { data, loading, invalidate };
}
