import { readByCustomerSchema, ReadByCustomer } from "../models/ReadByCustomer";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import {
  tryGetCurrentUser,
  getOptionalDocumentById,
  setDocument,
} from "../lib/firebase/connectors";
import { logValidationErrors, throwAndLogMessage } from "../utils";

const readByCustomerCollectionName = "readByCustomer";

export default class ReadByCustomerService {
  static get = async (): Promise<ReadByCustomer | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    const uid = currentUser.id;
    const readByCustomer = await getOptionalDocumentById<ReadByCustomer>(
      readByCustomerCollectionName,
      uid
    );
    logValidationErrors(readByCustomer, readByCustomerSchema, "ReadByCustomerService.getAll");
    return readByCustomer;
  };

  static set = async (updatedReadByData: ReadByCustomer): Promise<ReadByCustomer | null> => {
    const currentUser = await tryGetCurrentUser();
    if (!currentUser) {
      throwAndLogMessage("User is not logged in");
    }
    const uid = currentUser.id;
    try {
      await setDocument<ReadByCustomer>(readByCustomerCollectionName, uid, updatedReadByData);
      return await this.get();
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error setting read news",
        error,
      });
      throw new Error("Error setting read news");
    }
  };
}
