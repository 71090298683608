import React, { useMemo } from "react";
import { List, MD3Theme, Text, useTheme } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { convertTimestampToDate, dbt, formatDateTimeDiffToNow } from "../../utils";
import { VendorCustomerHistory } from "../../models/VendorCustomerHistory";
import { useTranslation } from "react-i18next";

interface HistoryProps {
  history: VendorCustomerHistory;
  vendorName: string;
}

const History: React.FC<HistoryProps> = ({ history, vendorName }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const rewards = (redeemedRewards: any) => {
    if (redeemedRewards.length > 0) {
      let rewards = dbt(i18n.language, redeemedRewards[0].title);
      // If there are more than one rewards redeemed, add "," to connect rewards and add "and" before the last reward
      if (redeemedRewards.length > 1) {
        for (let i = 1; i < redeemedRewards.length; i++) {
          if (i === redeemedRewards.length - 1) {
            rewards += ` ${t("historyScreen.and")} ${dbt(i18n.language, redeemedRewards[i].title)}`;
          } else {
            rewards += `, ${dbt(i18n.language, redeemedRewards[i].title)}`;
          }
        }
      }
      return `${t("historyScreen.youHaveRedeemed")} ${rewards}!`;
    } else {
      return null;
    }
  };
  return (
    <List.Section style={styles.listSectionContainer}>
      <View style={styles.listSectionTopContainer}>
        <View style={styles.vendorNameAndDateTimeView}>
          <Text style={styles.dateTimeText}>
            {formatDateTimeDiffToNow(convertTimestampToDate(history.createdAt))}
          </Text>
          <Text style={styles.vendorNameText}>{vendorName}</Text>
        </View>
        <View style={styles.pointsAddedAndPointsRedeemedContainer}>
          <Text>{`${history.pointsAdded}  ${t(
            `global.stamp.${history.pointsAdded > 1 ? "plural" : "singular"}`
          )} ${t("historyScreen.added")}`}</Text>
          <Text>{`${history.pointsRedeemed}  ${t(
            `global.stamp.${history.pointsRedeemed > 1 ? "plural" : "singular"}`
          )} ${t("global.redeemed")}`}</Text>
        </View>
      </View>
      {rewards(history.redeemedRewards) && (
        <Text style={styles.redeemedRewardsText}>{rewards(history.redeemedRewards)}</Text>
      )}
    </List.Section>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    listSectionContainer: {
      borderBottomColor: theme.colors.outlineVariant,
      borderBottomWidth: 1,
    },
    listSectionTopContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 16,
    },
    vendorNameAndDateTimeView: {
      flex: 1,
      alignItems: "flex-start",
      paddingRight: 20,
    },
    dateTimeText: { fontWeight: "bold", flexWrap: "wrap" },
    vendorNameText: { flexWrap: "wrap" },
    pointsAddedAndPointsRedeemedContainer: { alignItems: "flex-end" },
    redeemedRewardsText: {
      textAlign: "left",
      marginTop: 6,
      marginLeft: 16,
      marginRight: 8,
      marginBottom: 16,
    },
  });

export default History;
