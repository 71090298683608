import { List, MD3Theme, useTheme } from "react-native-paper";
import { Text, StyleSheet } from "react-native";
import { useMemo } from "react";

interface ExpandableListItemProps {
  message: {
    id: string;
    title: string;
    description: string;
    isRead?: boolean;
  };
  expanded: boolean;
  onExpand: (messageId: string) => void;
}

const ExpandableListItem = ({
  message,
  expanded,
  onExpand,
}: ExpandableListItemProps) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const handlePress = () => {
    onExpand(message.id);
  };

  return (
    <List.Item
      style={[styles.listItem, !message.isRead && styles.unreadItem]}
      title={message.title}
      description={(props) => (
        <Text {...props} numberOfLines={expanded ? undefined : 2} style={styles.text}>
          {message.description}
        </Text>
      )}
      onPress={handlePress}
    />
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    listItem: {
      borderBottomColor: theme.colors.outlineVariant,
      borderBottomWidth: 1,
    },
    unreadItem: {
      backgroundColor: theme.colors.primaryContainer,
    },
    text: { flex: 1 },
  });

export default ExpandableListItem;
