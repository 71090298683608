import { useEffect, useState } from "react";
import useMessages from "./useMessages";
import useReadByCustomer from "./useReadByCustomer";

export default function useUnreadMessagesCount() {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const messages = useMessages();
  const readByCustomer = useReadByCustomer();

  useEffect(() => {
    const currentMessages = messages.query.data ?? [];
    const messagesReadByCustomer = readByCustomer.query.data?.readMessages ?? [];
    if (currentMessages.length > messagesReadByCustomer.length) {
      setUnreadMessagesCount(currentMessages.length - messagesReadByCustomer.length);
    } else {
      setUnreadMessagesCount(0);
    }
  }, [messages.query.data, readByCustomer.query.data]);

  return unreadMessagesCount;
}
