import { useQuery, useQueryClient } from "@tanstack/react-query";
import RewardService from "../../services/reward.service";

interface Properties {
  queryOnLoad?: boolean;
}

export default function useRewards(props?: Properties) {
  const queryKey = ["useRewards"];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: () => RewardService.getAll(),
    enabled: props?.queryOnLoad ?? true,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return { query, invalidate };
}
