import React, { useMemo } from "react";
import { View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { Button, Text, HelperText, useTheme, MD3Theme } from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import QsolTextInput from "../../components/atoms/QsolTextInput";
import { useTranslation } from "react-i18next";
import { supabase } from "../../lib/supabase";
import Logo from "../../components/atoms/Logo";
import { MainNavigationProp } from "../navigation/MainNavigation";
import { useNavigation } from "@react-navigation/native";
import LanguageSwitchButton from "../../components/atoms/LanguageSwitchButton";
import { handleMobileSupabaseLogin } from "../../lib/firebase/functions";
import { toastError } from "../../components/atoms/ToastProvider";

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
};

export default function EmailSignUp() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { t } = useTranslation();
  const { navigate } = useNavigation<MainNavigationProp>();

  const onSubmit = async (formValues: FormData) => {
    try {
      const credentials = {
        email: formValues.email,
        password: formValues.password,
      };

      const supabaseCheck = await handleMobileSupabaseLogin(credentials);
      if (!supabaseCheck.success) {
        toastError(t("auth.wrongEmailOrPassword"));
      }
      if (supabaseCheck.status === "can_login") {
        await supabase.auth.signInWithPassword(credentials);
      }
      if (supabaseCheck.status === "can_register" || supabaseCheck.status === "not_confirmed") {
        await supabase.auth.signUp({
          ...credentials,
          options: {
            emailRedirectTo: window.location.origin,
          },
        });
        navigate("VerifyEmail", { email: formValues.email });
      }
    } catch (error: unknown) {
      console.error("Error signing in", error);
    }
  };

  // Watching password to compare it with confirmPassword
  const passwordValue = watch("password");

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{ width: "100%", alignItems: "center", marginBottom: 10 }}>
          <Logo />
          <LanguageSwitchButton />
        </View>
        <Text variant="headlineLarge">{t("auth.registerAccount")}</Text>
        <Controller
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Invalid email address",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <QsolTextInput
              label={t("auth.email")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              style={styles.input}
              mode="outlined"
              error={!!errors.email}
              autoComplete="off"
            />
          )}
          name="email"
        />
        {errors.email && <HelperText type="error">{errors.email.message}</HelperText>}
        <Controller
          control={control}
          rules={{
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <QsolTextInput
              label={t("auth.password")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry
              style={styles.input}
              mode="outlined"
              error={!!errors.password}
            />
          )}
          name="password"
        />
        {errors.password && <HelperText type="error">{errors.password.message}</HelperText>}

        <Controller
          control={control}
          rules={{
            validate: (value) => value === passwordValue || "Passwords do not match",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <QsolTextInput
              label={t("auth.confirmPassword")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry
              style={styles.input}
              mode="outlined"
              error={!!errors.confirmPassword}
            />
          )}
          name="confirmPassword"
        />
        {errors.confirmPassword && (
          <HelperText type="error">{errors.confirmPassword.message}</HelperText>
        )}
        <Button mode="contained" onPress={handleSubmit(onSubmit)} style={styles.button}>
          {t("auth.register")}
        </Button>

        <Button style={{ marginTop: 50 }} onPress={() => navigate("Login")}>
          {t("auth.signIn")}
        </Button>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      justifyContent: "center",
      padding: 20,
    },
    input: {
      marginBottom: 12,
    },
    button: {
      marginTop: 12,
    },
  });
