export type ConstraintType = "where";
export type Operator = "==" | "<" | ">" | "<=" | ">=" | "array-contains" | "in" | "array-contains-any";
export type Value = string | number | boolean | Date | null;

export type CallableResult<T> = { data: T; }
export type CallableFunc<Tin, Tout> = (params: Tin) => Promise<CallableResult<Tout>>;

export interface FirebaseQueryConstraint {
  property: string;
  operator: Operator;
  value: Value;
}

export const where = (property: string, operator: Operator, value: Value): FirebaseQueryConstraint => {
  return {
    property,
    operator,
    value,
  }
}