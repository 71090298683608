/* eslint-disable no-console */
import { doc, setDoc } from "firebase/firestore";
import { LogLevel } from "../models/helpers/LogEvent";
import { auth, firestore as db } from "../lib/firebase";

export default class Logger {
  static error = (logLevel: LogLevel, params?: any) => {
    if (process.env.NODE_ENV === "development") {
      console.error(params);
      console.error(params.error?.stack);
    }
    // TODO: use analytics
    // setDoc(doc(db, `logs/${new Date().toISOString()}`), {
    //   logLevel,
    //   createdAt: new Date(),
    //   userId: auth.currentUser?.uid ?? "",
    //   params: JSON.stringify(params)
    // });
  };
}
