// VendorList.tsx
import React, { useState, useEffect, useMemo } from "react";
import { StyleSheet } from "react-native";
import { type Vendor } from "../../models/Vendor";
import { calculateDistance } from "../../utils";
import VendorItem from "../molecules/VendorItem";
import { Text } from "react-native-paper";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import { LocationObject } from "expo-location";

interface VendorWithDistance extends Vendor {
  distance: number;
}

interface VendorListProps {
  vendors: Vendor[];
  location?: LocationObject | null;
}

interface VendorsGroupedByDistance {
  [key: number]: VendorWithDistance[];
}

function compareVendors(a: VendorWithDistance, b: VendorWithDistance) {
  if (a.distance === -1 && b.distance === -1) {
    // If both keys are -1, compare by vendor name
    return a.name.localeCompare(b.name);
  } else if (a.distance === -1) {
    // If 'a' is -1, it comes after other numeric keys
    return 1;
  } else if (b.distance === -1) {
    // If 'b' is -1, it comes before other numeric keys
    return -1;
  } else {
    return a.distance - b.distance;
  }
}

const VendorList: React.FC<VendorListProps> = ({ vendors, location }) => {
  // const { location } = useLocation();
  const { t } = useTranslation();
  const styles = useMemo(() => createStyles(), []);
  const [sortedVendorGroups, setSortedVendorGroups] = useState<VendorsGroupedByDistance>();

  useEffect(() => {
    // Calculate distance for each vendor based on user's location
    const vendorsWithDistance = vendors.map((vendor) => ({
      ...vendor,
      distance:
        vendor.coordinates && location
          ? // If vendor has coordinates, calculate distance;
            calculateDistance(location.coords, vendor.coordinates)
          : // otherwise, set distance to -1
            -1,
    }));

    const groupedVendors = vendorsWithDistance.reduce((groups, vendor) => {
      const distance = vendor.distance;
      const key = Math.floor(distance);
      groups[key] = groups[key] || [];
      groups[key].push(vendor);
      return groups;
    }, {} as VendorsGroupedByDistance);

    const sortedGroupedVendors = Object.keys(groupedVendors).reduce((result, group) => {
      const vendorGroup = groupedVendors[Number(group)];
      return {
        ...result,
        [Number(group)]: [...vendorGroup].sort(compareVendors),
      };
    }, {});

    setSortedVendorGroups(sortedGroupedVendors);
  }, [location, vendors]);

  const getDistanceText = (distance: string) => {
    let textToRender = "";

    if (distance === "0") {
      // If distance is "0", no text needed.
      textToRender = "";
    } else if (distance === "-1") {
      // If distance is "-1", render "other".
      textToRender = t("global.other");
    } else {
      // For all other values of distance, render "Over ${distance} km".
      textToRender = `${t("global.over")} ${distance} km`;
    }

    if (textToRender !== "") {
      return (
        <Text variant="titleLarge" style={styles.distanceText}>
          {textToRender}
        </Text>
      );
    }
  };

  return (
    <>
      {sortedVendorGroups &&
        Object.keys(sortedVendorGroups).map((distance) => (
          <View key={distance}>
            {location && getDistanceText(distance)}
            {sortedVendorGroups[Number(distance)].map((vendor) => (
              <VendorItem
                key={vendor.id}
                vendorId={vendor.id}
                heroUrl={vendor.heroUrl ?? undefined}
                vendorName={vendor.name}
                distance={vendor.distance}
                vendorTags={vendor.tags}
              />
            ))}
          </View>
        ))}
    </>
  );
};
const createStyles = () =>
  StyleSheet.create({
    container: {
      marginTop: 16,
    },
    distanceText: { marginTop: 16, marginLeft: 4 },
  });
export default VendorList;
