import React, { useRef, useEffect, useMemo } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { Portal, Modal, Button, MD3Theme, useTheme } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import QsolDialog from "./QsolDialog";

interface LanguagePickerProps {
  languagePickerVisible: boolean;
  onDismiss: () => void;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  languagePickerVisible,
  onDismiss,
}) => {
  const pickerRef = useRef<any>(null);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const handleLanguageChange = (itemValue: string) => {
    i18n.changeLanguage(itemValue);
  };

  const openPicker = () => {
    pickerRef.current?.focus();
  };
  const closePicker = () => {
    pickerRef.current?.blur();
  };

  useEffect(() => {
    if (languagePickerVisible && Platform.OS === "android") {
      openPicker();
    }
  }, [languagePickerVisible]);

  const androidPicker = () => {
    return (
      <Picker
        style={styles.andriodPickerView}
        ref={pickerRef}
        selectedValue={i18n.language}
        onValueChange={(itemValue, itemIndex) => {
          handleLanguageChange(itemValue);
        }}
        onBlur={() => {
          onDismiss();
          closePicker();
        }}
      >
        <Picker.Item label="English" value="en" />
        <Picker.Item label="Finnish" value="fi" />
      </Picker>
    );
  };

  const picker = () => {
    return (
      <Picker
        selectedValue={i18n.language}
        onValueChange={(itemValue, itemIndex) => {
          handleLanguageChange(itemValue);
        }}
      >
        <Picker.Item label="English" value="en" />
        <Picker.Item label="Finnish" value="fi" />
      </Picker>
    );
  };

  if (Platform.OS === "android") {
    return <Portal>{androidPicker()}</Portal>;
  }

  if (Platform.OS === "web") {
    return (
      <Modal
        visible={languagePickerVisible}
        onDismiss={onDismiss}
        contentContainerStyle={styles.modalStyle}
      >
        <View style={styles.webPickerView}>
          {picker()}
          <View style={styles.webButtonView}>
            <Button
              mode="contained-tonal"
              style={styles.webButton}
              onPress={onDismiss}
            >
              {t("global.done")}
            </Button>
          </View>
        </View>
      </Modal>
    );
  }

  return (
    <QsolDialog
      style={styles.dialogContainer}
      visible={languagePickerVisible}
      hideDialog={onDismiss}
      children={picker()}
      actions={
        <View>
          <Button mode="contained-tonal" onPress={onDismiss}>
            {t("global.done")}
          </Button>
        </View>
      }
    />
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    andriodPickerView: { display: "none", backgroundColor: "red" },
    webPickerView: {
      marginVertical: 6,
    },
    webButtonView: { alignItems: "flex-end" },
    webButton: {
      marginTop: 10,
    },

    modalStyle: {
      backgroundColor: "white",
      padding: 20,
      borderRadius: 4,
      width: "80%",
      marginHorizontal: "10%",
    },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
  });

export default LanguagePicker;
