import { array } from "yup";
import { logValidationErrors } from "../utils";
import { Translatable } from "../models/helpers/Translatable";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { where } from "../lib/firebase/helpers";
import { Coupon, couponSchema } from "../models/Coupon";

export interface CreateNewCouponRequest {
  vendorId: string;
  active: boolean;
  requiredPoints: number;
  title: Translatable;
  description?: Translatable;
}

export const couponsCollection = "coupons";

export default class CouponService {
  static getQrContentForCouponReward = (uid: string, couponId: string) => {
    return `CPN_${uid}_${couponId}`;
  };

  static getCouponsForVendor = async (vendorId: string): Promise<Coupon[]> => {
    try {
      const couponsForVendor = await getDocuments<Coupon>(
        couponsCollection,
        where("vendorId", "==", vendorId),
        where("active", "==", true),
        where("visibleForCustomers", "==", true)
      );
      logValidationErrors(
        couponsForVendor,
        array().of(couponSchema),
        "CouponService.getCouponsForVendor"
      );
      return couponsForVendor;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting coupons for vendor",
        error,
      });
      throw new Error("Error getting coupons for vendor");
    }
  };

  static getAll = async (): Promise<Coupon[]> => {
    try {
      const coupons = await getDocuments<Coupon>(
        couponsCollection,
        where("active", "==", true),
        where("visibleForCustomers", "==", true)
      );
      logValidationErrors(coupons, array().of(couponSchema), "CouponService.getAll");
      return coupons;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting coupons",
        error,
      });
      throw new Error("Error getting coupons");
    }
  };

  static getById = async (id: string) => {
    const coupon = getDocumentById<Coupon>(couponsCollection, id);
    logValidationErrors(coupon, couponSchema, "CouponService.getById");
    return coupon;
  };
}
