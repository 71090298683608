import React, { createContext, ReactNode, useContext } from "react";

export type QponlyTabs = "Home" | "Search" | "Wallet" | "More";
interface TabNavigationContextValues {
  openTab: (tab: QponlyTabs) => void;
}
export const TabNavigationContext = createContext<TabNavigationContextValues | undefined>(
  undefined
);

export const useTabContext = () => {
  const tabContext = useContext(TabNavigationContext);
  if (!tabContext) {
    throw new Error("useAuth must be used within an TabNavigationContextProvider");
  }
  return tabContext;
};

interface TabNavigationContext {
  children: ReactNode;
  openTab: (tab: QponlyTabs) => void;
}
export default function TabNavigationContextProvider({ children, openTab }: TabNavigationContext) {
  return (
    <TabNavigationContext.Provider value={{ openTab }}>{children}</TabNavigationContext.Provider>
  );
}
