import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Button, useTheme, Text, MD3Theme, ActivityIndicator } from "react-native-paper";
import QsolTextInput from "../../components/atoms/QsolTextInput";
import { useTranslation } from "react-i18next";
import useProfile from "../../hooks/queries/useProfile";
import { useAuth } from "../../contexts/AuthContext";

export default function SetupProfile() {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = createStyles(theme);
  const [displayName, setDisplayName] = useState<string | undefined>("");
  const profile = useProfile({ queryOnLoad: false });
  const [loading, setLoading] = useState<boolean>(false);

  const updateDisplayName = async () => {
    setLoading(true);
    await profile.updateProfile({ displayName });
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <View>
        <Text variant="headlineMedium">{t("profileSheet.setYourUsername")}</Text>
        <Text>{t("profileSheet.thisIsWhatVendorsSeeWhenTheyScanYourCode")}</Text>
      </View>
      <QsolTextInput value={displayName} onChangeText={setDisplayName} />
      <Button mode="contained" onPress={updateDisplayName} disabled={loading}>
        {loading ? <ActivityIndicator color="white" size={14} /> : t("global.save")}
      </Button>
      <View style={{ alignItems: "center" }}>
        <Button style={{ marginTop: 40 }} onPress={logout}>
          {t("profileSheet.signout")}
        </Button>
      </View>
    </View>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      padding: 20,
      flex: 1,
      justifyContent: "center",
      gap: 20,
    },
  });
