import { InferType, array, bool, object, string } from "yup";
import { translatableSchema } from "./helpers/Translatable";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";
import { firebaseGeopointSchema } from "./helpers/FirebaseGeopoint";

export const vendorSchema = object({
  id: string().defined(),
  active: bool().defined(),
  businessId: string().nullable(),
  createdAt: firebaseTimestampSchema.defined(),
  description: translatableSchema,
  heroUrl: string().nullable(),
  logoUrl: string().nullable(),
  name: string().defined(),
  owner: string().nullable(),
  tags: array(translatableSchema.defined()),
  phone: string().nullable(),
  email: string().email("Invalid email format").nullable(),
  website: string().url("Invalid website URL format").nullable(),
  address: string().nullable(),
  coordinates: firebaseGeopointSchema.nullable().default(null),
  updatedAt: firebaseTimestampSchema.defined(),
});

export type Vendor = InferType<typeof vendorSchema>;
