import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Animated,
  ViewToken,
  useWindowDimensions,
  Platform,
} from "react-native";
import { MD3Theme, Text, useTheme } from "react-native-paper";

type viewableItemsChangedType =
  | ((info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => void)
  | null
  | undefined;

interface Props<T> {
  items: T[];
  renderItem: ({ item, index }: { item: T; index: number }) => JSX.Element;
}

export default function RewardsCarousel<T>(props: Props<T>) {
  /**
   * Rendering
   */
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const indexOnComponentMount = Platform.OS === "web" ? 0 : 1;
  const scrollX = useRef(new Animated.Value(0)).current;
  const rewardTemplatesRef = useRef<FlatList>(null);
  const { width } = useWindowDimensions();
  const [currentIndex, setCurrentIndex] = useState<number | null>(indexOnComponentMount);
  const viewConfig = useRef({ viewAreaCoveragePercentThreshold: 50 }).current;
  const viewableItemsChanged = useRef<viewableItemsChangedType>(({ viewableItems }) => {
    setCurrentIndex(viewableItems[0]?.index);
  }).current;
  const renderIndicators = () => {
    if (data.length <= 1) return null;

    const showingIndex = currentIndex! - 1;
    return data.map((val: any, key: number) => (
      <Text
        key={key}
        style={
          key === showingIndex % data.length ? styles.activeIndicator : styles.inactiveIndicator
        }
      >
        ⬤
      </Text>
    ));
  };

  /**
   * Business logic
   */
  const [data, setData] = useState<T[]>([]);
  const clampedInitialScrollIndex = data.length <= 1 ? 0 : indexOnComponentMount;
  useEffect(() => {
    // For web, or when there's only one reward, don't create a looping carousel.
    if (Platform.OS === "web" || props.items.length <= 1) {
      setData(props.items);
    } else {
      // For other platforms, set up data for looping.
      setData([props.items[props.items.length - 1], ...props.items, props.items[0]]);
    }
  }, [props.items]);

  return (
    <View>
      <View style={styles.container}>
        <FlatList
          data={data}
          horizontal
          pagingEnabled
          bounces={false}
          showsHorizontalScrollIndicator={Platform.OS === "web"}
          snapToInterval={Platform.OS === "web" ? width : undefined}
          keyExtractor={(item, index) => `${item.id}-${index}`}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
            useNativeDriver: false,
          })}
          scrollEventThrottle={32}
          onEndReached={() => {
            if (Platform.OS === "web") {
              return;
            }
            if (data.length <= 1) return;

            if (rewardTemplatesRef.current) {
              rewardTemplatesRef.current.scrollToIndex({
                animated: false,
                index: 1,
              });
              setCurrentIndex(1);
            }
          }}
          onEndReachedThreshold={0.1}
          onStartReached={() => {
            if (Platform.OS === "web") {
              return;
            }
            if (data.length <= 1) return;

            const secondLastIndex = data!.length - 2; // the data array has n+2 elements, one extra in start, one extra in end
            if (rewardTemplatesRef.current) {
              rewardTemplatesRef.current.scrollToIndex({
                animated: false,
                index: secondLastIndex,
              });
              setCurrentIndex(secondLastIndex);
            }
          }}
          onStartReachedThreshold={0.1}
          onScrollToIndexFailed={() => setCurrentIndex(indexOnComponentMount)}
          getItemLayout={(data, index) => ({
            length: width,
            offset: width * index,
            index,
          })}
          onViewableItemsChanged={viewableItemsChanged}
          initialScrollIndex={clampedInitialScrollIndex}
          viewabilityConfig={viewConfig}
          renderItem={({ item, index }) => props.renderItem({ item, index })}
          ref={rewardTemplatesRef}
        />
      </View>
      {Platform.OS !== "web" && (
        <View style={styles.indicatorContainerStyle}>{renderIndicators()}</View>
      )}
    </View>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      paddingTop: 20,
      flex: 1,
    },
    indicatorContainerStyle: {
      flexDirection: "row",
      position: "absolute",
      bottom: 0,
      alignSelf: "center",
    },
    activeIndicator: {
      color: theme.colors.primary,
      fontSize: 10,
      marginBottom: 8,
      marginHorizontal: 2,
    },
    inactiveIndicator: {
      color: theme.colors.primaryContainer,
      fontSize: 10,
      marginBottom: 8,
      marginHorizontal: 2,
    },
  });
