import React, { useMemo } from "react";
import { Platform, SafeAreaView, ScrollView, StatusBar, StyleSheet, View } from "react-native";
import { Button, MD3Theme, useTheme, Text } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "./navigation/MainNavigation";
import StampCardList from "../components/organisms/StampCardList";
import MemberCard from "../components/molecules/MemberCard";
import { useTranslation } from "react-i18next";

const WalletScreen = () => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { navigate } = useNavigation<MainNavigationProp>();
  const { t } = useTranslation();

  const goToHistory = () => {
    navigate("History", { vendorId: undefined });
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView>
        <View style={styles.container}>
          <Text variant="titleLarge" style={styles.title}>
            {t("global.wallet")}
          </Text>
          <View style={styles.cardContainer}>
            <MemberCard />
          </View>
          <View style={styles.buttonView}>
            <Button
              mode="contained"
              icon="file-document-multiple-outline"
              onPress={goToHistory}
              labelStyle={styles.buttonFontSize}
            >
              {t(`global.viewAllHistory`)}
            </Button>
          </View>
          <View style={styles.stampCardListContainer}>
            <StampCardList />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
      paddingTop: Platform.select({
        ios: 0,
        android: StatusBar.currentHeight,
        web: 20,
      }),
    },
    container: {
      marginTop: 20,
      justifyContent: "center",
    },
    cardContainer: {
      alignItems: "center",
    },
    buttonView: { alignItems: "center", marginVertical: 10 },
    listItem: {
      borderBottomColor: theme.colors.outlineVariant,
      borderBottomWidth: 1,
      marginVertical: 8,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: 10,
    },
    buttonFontSize: {
      fontSize: 16,
    },
    stampCardListContainer: {
      margin: 8,
      paddingBottom: 70,
    },
  });

export default WalletScreen;
