import React, { useMemo } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { Card, Text, useTheme, ActivityIndicator } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "./navigation/MainNavigation";
import { type AppNews } from "../models/AppNews";
import { MD3Theme } from "react-native-paper/lib/typescript/types";
import { useQuery } from "@tanstack/react-query";
import AppNewsService from "../services/appNews.service";
import { convertTimestampToDate } from "../utils";
import { useTranslation } from "react-i18next";

type Props = NativeStackScreenProps<RootStackParamList, "AppNewsDetails">;

const AppNewsDetailsScreen = ({ route }: Props) => {
  const { t } = useTranslation();
  const appNewsId = route.params.appNewsId;
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const appNewsQuery = useQuery<AppNews | null>({
    queryKey: ["appNews", appNewsId],
    queryFn: () => AppNewsService.getById(appNewsId),
  });

  if (appNewsQuery.isLoading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!appNewsQuery.isSuccess || !appNewsQuery.data) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("global.errorMessage.newsNotFound")}</Text>
      </SafeAreaView>
    );
  }

  const news = appNewsQuery.data;

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView style={styles.scrollView}>
        {news.imageUrl && <Card.Cover style={styles.cardCover} source={{ uri: news.imageUrl }} />}
        <View style={styles.contentContainer}>
          <Text variant="headlineSmall" style={styles.title}>
            {news.title}
          </Text>
          <Text variant="bodyLarge" style={styles.summary}>
            {news.summary}
          </Text>
          <Text variant="bodyMedium" style={styles.date}>
            {convertTimestampToDate(news.createdAt).toDateString()}
          </Text>
          <Text variant="bodyMedium">{news.details}</Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
    },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    scrollView: {
      flexGrow: 1,
      flex: 1,
      // backgroundColor: "#f4f4f4",
      backgroundColor: theme.colors.elevation.level0,
    },
    card: {
      margin: 10,
      elevation: 1,
    },
    cardCover: {
      borderRadius: 0,
      marginBottom: 10,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
    contentContainer: {
      paddingTop: 20,
      paddingBottom: 50,
      paddingHorizontal: 20,
    },
    title: { color: theme.colors.primary },
    summary: { marginTop: 6, marginBottom: 6 },
    date: { marginBottom: 10 },
  });

export default AppNewsDetailsScreen;
