import React, { createContext, useState, ReactNode, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../lib/firebase";
import { supabase } from "../lib/supabase";
import { Session } from "@supabase/supabase-js";
import ensureFirebaseSession from "../hooks/ensureFirebaseSession";
import { useQueryClient } from "@tanstack/react-query";
import setSupabaseUserHasLoggedInThroughMobile from "../hooks/setSupabaseUserHasLoggedInThroughMobile";

interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  uid: string;
  logout: () => Promise<void>;
}
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return authContext;
};

interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const queryClient = useQueryClient();
  const [supabaseSession, setSupabaseSession] = useState<Session>();
  const [firebaseLoading, setFirebaseLoading] = useState<boolean>(true);
  const [supabaseLoading, setSupabaseLoading] = useState<boolean>(true);
  const [supabaseAuthenticated, setSupabaseAuthenticated] = useState<boolean>(false);
  const [firebaseAuthenticated, setFirebaseAuthenticated] = useState<boolean>(false);
  const [uid, setUid] = useState<string>("");
  const loading = firebaseLoading || supabaseLoading;
  ensureFirebaseSession(loading, supabaseSession);
  setSupabaseUserHasLoggedInThroughMobile(supabaseSession);

  const handleSupabaseSession = (session: Session | null) => {
    if (session) {
      setSupabaseSession(session);
      setUid(session.user.id);
      setSupabaseAuthenticated(true);
    } else {
      setSupabaseAuthenticated(false);
      setUid("");
    }
    setSupabaseLoading(false);
  };

  useEffect(() => {
    supabase.auth.getSession().then((handler) => {
      if (__DEV__) console.log(handler);
      handleSupabaseSession(handler.data.session);
    });

    const authListener = supabase.auth.onAuthStateChange((_event, session) => {
      if (__DEV__) console.log({ _event, session });
      handleSupabaseSession(session);
    });

    return () => authListener.data.subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setFirebaseAuthenticated(!!user);
      setFirebaseLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await supabase.auth.signOut();
      await auth.signOut();
      localStorage.removeItem("navigationState");
      localStorage.removeItem("navigationTabIndex");
      await queryClient.clear();
    } catch (error) {
      throw error;
    }
  };

  const isAuthenticated = firebaseAuthenticated && supabaseAuthenticated;
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, uid, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
