import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ToastProvider() {
  return <ToastContainer />;
}

function toastSuccess(message: string) {
  return toast.success(message);
}

function toastError(message: string) {
  return toast.error(message);
}

export { toastSuccess, toastError };
