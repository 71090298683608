import React, { useMemo, useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { IconButton, Card, Text, Button, MD3Theme, useTheme } from "react-native-paper";
import { useTranslation } from "react-i18next";
import QRCodeGenerator from "./QRCodeGenerator";
import { useAuth } from "../../contexts/AuthContext";
import QsolDialog from "./QsolDialog";
import useProfile from "../../hooks/queries/useProfile";

const MemberCard = () => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { uid } = useAuth();
  const profile = useProfile();
  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const windowWidth = Dimensions.get("window").width;
  const qrSize = Math.min(Math.round(windowWidth * 0.8), 400);
  const { t } = useTranslation();

  return (
    <Card
      style={styles.cardContainer}
      contentStyle={styles.cardContent}
      mode="elevated"
      elevation={1}
    >
      <View style={styles.infoIconButtonContainer}>
        <IconButton icon="information-outline" onPress={showDialog}></IconButton>
        <QsolDialog
          visible={visible}
          hideDialog={hideDialog}
          title={t("global.info")}
          actions={<Button onPress={hideDialog}>{t("global.done")}</Button>}
          children={<Text variant="bodyMedium">{t("membershipCardComponent.content")}</Text>}
          style={styles.dialogContainer}
        />
      </View>
      <View style={styles.qponlyNumberAndUidContainer}>
        <View>
          <Text style={styles.title}>Qponly {t("global.membershipCard")}</Text>
          {profile.query.data && (
            <Text style={styles.subTitle}>{profile.query.data.displayName}</Text>
          )}
        </View>
        <View style={styles.qrContainer}>
          <QRCodeGenerator qrContent={`UID_${uid}`} size={qrSize} bgColor="transparent" />
        </View>
        <View>
          <Text>{uid}</Text>
        </View>
      </View>
    </Card>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    qrContainer: {
      overflow: "hidden",
      marginHorizontal: 20,
    },
    cardContent: {
      width: "100%",
      position: "relative",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    cardContainer: {
      marginHorizontal: 16,
      marginVertical: 16,
      backgroundColor: theme.colors.background,
      justifyContent: "center",
      maxWidth: 450,
    },
    infoIconButtonContainer: {
      position: "absolute",
      right: 0,
      top: 0,
      padding: 6,
      zIndex: 99,
    },
    qponlyNumberAndUidContainer: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: 30,
      paddingVertical: 30,
    },
    title: {
      fontSize: 18,
    },
    subTitle: { marginTop: 6, textAlign: "center" },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
  });

export default MemberCard;
