import React, { useEffect, useMemo, useRef } from "react";
import { View, Animated, Easing, StyleSheet, Platform, ImageBackground } from "react-native";
import QponlyLogo from "../../assets/Qponly.svg";

interface CirclePosition {
  x: number;
  y: number;
}
interface CircleMeta {
  radius: number;
  position: CirclePosition;
}
const middleCircle: CircleMeta = {
  radius: 100,
  position: {
    x: 0,
    y: 0,
  },
};
const smallCircle: CircleMeta = {
  radius: 30,
  position: {
    x: 0,
    y: 0,
  },
};
const leftSmallCircle: CircleMeta = {
  radius: 50,
  position: {
    x: -103,
    y: -103,
  },
};
const rightSemiCircle: CircleMeta = {
  radius: 300,
  position: {
    x: 250,
    y: -270,
  },
};
const bottomSemiCircle: CircleMeta = {
  radius: 360,
  position: {
    x: -60,
    y: 390,
  },
};
const galaxyCircle: CircleMeta = {
  radius: 1500,
  position: {
    x: -1400,
    y: -1200,
  },
};

const LoadingScreen = () => {
  const rotateValue = useRef(new Animated.Value(0)).current;

  // Start the rotation animation
  const startRotation = () => {
    rotateValue.setValue(0);
    Animated.loop(
      Animated.timing(rotateValue, {
        toValue: 1,
        duration: 4000,
        easing: Easing.elastic(2),
        useNativeDriver: Platform.OS !== "web",
      })
    ).start();
  };

  useEffect(() => {
    startRotation();
  }, []);

  const spin = rotateValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  const logoViewbox = useMemo(
    () => (Platform.OS === "web" ? "53 -120 200 350" : "20 -120 200 350"),
    []
  );

  return (
    <View style={styles.container}>
      {/* <View style={styles.leftSmallCircle} /> */}
      <View style={styles.middleCircle} />
      {/* <View style={styles.galaxyCircle} /> */}
      <View style={styles.rightSemiCircle}>
        <View style={styles.rightSemiCircleIntersection} />
      </View>
      <View style={styles.bottomSemiCircle}>
        <View style={styles.bottomSemiCircleIntersection}>
          <ImageBackground
            source={{
              // uri: "https://s3.eu-central-1.amazonaws.com/static.qponly.app/image.webp",
              // uri: "https://images.unsplash.com/photo-1595088402198-24d44e48d57c?q=80&w=1516&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              // uri: "https://images.unsplash.com/photo-1610296669228-602fa827fc1f?q=80&w=1550&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              // uri: "https://images.unsplash.com/photo-1515942479748-a59a935bbef4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              // uri: "https://images.unsplash.com/photo-1484589065579-248aad0d8b13?q=80&w=1518&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
              uri: "https://images.unsplash.com/photo-1611716524065-622312678d68?q=80&w=1622&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            }}
            style={styles.acidImage}
          />
        </View>
      </View>

      {/* Animated path to simulate a loading effect
        <AnimatedPath
        d="M40 160 C 40 180, 80 180, 80 160 S 120 140, 120 160"
        fill="none"
        stroke="#000000"
        strokeWidth="20"
        strokeDasharray={100} // This should be the length of your path
        strokeDashoffset={strokeDashoffset}
      /> */}

      <Animated.View
        style={[
          styles.smallCircle,
          {
            transform: [
              { rotate: spin },
              { translateX: -(middleCircle.radius + smallCircle.radius) },
            ],
          },
        ]}
      />
      <View>
        <QponlyLogo
          style={{ overflow: "visible" }}
          width={280}
          height={180}
          viewBox={logoViewbox}
        />
      </View>
    </View>
  );
};

// Gray #CBCCC0
// Yellow #FDF87D

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "black",
    backgroundColor: "white",
    overflow: "hidden",
  },
  middleCircle: {
    width: middleCircle.radius * 2,
    height: middleCircle.radius * 2,
    borderWidth: 6,
    borderColor: "black",
    borderRadius: middleCircle.radius,
    position: "absolute",
    backgroundColor: "white",
  },
  leftSmallCircle: {
    width: leftSmallCircle.radius * 2,
    height: leftSmallCircle.radius * 2,
    borderWidth: 6,
    borderColor: "#d8d8d8",
    transform: [
      { translateX: leftSmallCircle.position.x - 3 },
      { translateY: leftSmallCircle.position.y - 3 },
    ],
    borderRadius: leftSmallCircle.radius,
    position: "absolute",
    backgroundColor: "#d8d8d8",
  },
  smallCircle: {
    borderWidth: 6,
    borderColor: "black",
    width: smallCircle.radius * 2,
    height: smallCircle.radius * 2,
    borderRadius: smallCircle.radius,
    position: "absolute",
    backgroundColor: "#FDF87D",
  },
  rightSemiCircle: {
    borderWidth: 6,
    borderColor: "white",
    width: rightSemiCircle.radius * 2,
    height: rightSemiCircle.radius * 2,
    borderRadius: rightSemiCircle.radius,
    transform: [
      { translateX: rightSemiCircle.position.x },
      { translateY: rightSemiCircle.position.y },
    ],
    position: "absolute",
    overflow: "hidden",
    backgroundColor: "white",
  },
  rightSemiCircleIntersection: {
    width: middleCircle.radius * 2,
    height: middleCircle.radius * 2,
    top: rightSemiCircle.radius - middleCircle.radius - rightSemiCircle.position.y - 6,
    left: rightSemiCircle.radius - middleCircle.radius - rightSemiCircle.position.x - 6,
    borderRadius: middleCircle.radius,
    position: "absolute",
    borderWidth: 6,
    borderColor: "black",
    backgroundColor: "black",
  },
  bottomSemiCircle: {
    width: bottomSemiCircle.radius * 2,
    height: bottomSemiCircle.radius * 2,
    borderWidth: 6,
    borderColor: "white", // "black",
    transform: [
      { translateX: bottomSemiCircle.position.x },
      { translateY: bottomSemiCircle.position.y },
    ],
    borderRadius: 500,
    backgroundColor: "white",
    position: "absolute",
    overflow: "hidden",
  },
  bottomSemiCircleIntersection: {
    width: middleCircle.radius * 2,
    height: middleCircle.radius * 2,
    top: bottomSemiCircle.radius - middleCircle.radius - bottomSemiCircle.position.y - 6,
    left: bottomSemiCircle.radius - middleCircle.radius - bottomSemiCircle.position.x - 6,
    borderRadius: middleCircle.radius,
    position: "absolute",
    backgroundColor: "black",
  },
  acidImage: {
    width: middleCircle.radius * 2,
    height: middleCircle.radius * 2,
    borderRadius: middleCircle.radius,
    overflow: "hidden",
    borderWidth: 6,
    borderColor: "black",
    opacity: 1,
    // backgroundColor: "black",
  },
  galaxyCircle: {
    borderWidth: 6,
    borderColor: "black",
    width: galaxyCircle.radius * 2,
    height: galaxyCircle.radius * 2,
    borderRadius: galaxyCircle.radius,
    transform: [{ translateX: galaxyCircle.position.x }, { translateY: galaxyCircle.position.y }],
    position: "absolute",
    overflow: "hidden",
    backgroundColor: "white",
  },
});

export default LoadingScreen;
