import { Vendor, vendorSchema } from "../models/Vendor";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { array } from "yup";
import { getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { logValidationErrors } from "../utils";
import { where } from "../lib/firebase/helpers";

export const vendorCollection = "vendors";

export default class VendorService {
  static getById = async (id: string): Promise<Vendor | null> => {
    try {
      const vendor = await getDocumentById<Vendor>(vendorCollection, id);
      logValidationErrors(vendor, vendorSchema, "VendorService.getById");
      return vendor;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting vendor",
        error,
      });
      return null;
    }
  };

  static getAll = async (): Promise<Vendor[]> => {
    try {
      const vendors = await getDocuments<Vendor>(vendorCollection, where("active", "==", true));
      logValidationErrors(vendors, array().of(vendorSchema), "VendorService.getAll");
      return vendors;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting vendors",
        error,
      });
      throw new Error("Error getting vendors");
    }
  };
}
