import { InferType, array, number, object, string } from "yup";
import { customerSchema } from "./Customer";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

const couponUsageSchema = object({
  couponId: string().defined(),
  timesUsed: number().defined(),
});

export const vendorCustomerSchema = customerSchema.shape({
  vendorId: string().required(),
  numberOfPoints: number().required(),
  lifetimePoints: number().required(),
  pointsUpdatedAt: firebaseTimestampSchema.required(),
  vendorNote: string(),
  couponUsage: array(couponUsageSchema).notRequired(),
});
export type VendorCustomer = InferType<typeof vendorCustomerSchema>;

export type VendorCustomerPoints = Pick<
  VendorCustomer,
  "uid" | "vendorId" | "numberOfPoints" | "pointsUpdatedAt" | "couponUsage"
>;
