import React, { useMemo } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import History from "../components/molecules/History";
import { type VendorCustomerHistory } from "../models/VendorCustomerHistory";
import HistoryService from "../services/history.service";
import { useQuery } from "@tanstack/react-query";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "./navigation/MainNavigation";
import { ActivityIndicator, Text, useTheme } from "react-native-paper";
import { Vendor } from "../models/Vendor";
import VendorService from "../services/vendor.service";

type Props = NativeStackScreenProps<RootStackParamList, "History">;

const HistoryScreen = ({ route }: Props) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const vendorId = route.params.vendorId;
  const { t } = useTranslation();

  const vendorQuery = useQuery<Vendor[] | null>({
    queryKey: ["vendors"],
    queryFn: () => VendorService.getAll(),
  });

  const historyQuery = useQuery<VendorCustomerHistory[] | null>({
    queryKey: ["history", vendorId],
    queryFn: () =>
      vendorId ? HistoryService.getHistoryByVendorId(vendorId) : HistoryService.getAllHistory(),
  });

  if (historyQuery.isLoading || vendorQuery.isLoading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!historyQuery.data || historyQuery.data?.length === 0) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("historyScreen.historyNotFound")}</Text>
      </SafeAreaView>
    );
  }

  const history = historyQuery.data ?? [];
  const vendors = vendorQuery.data ?? [];

  const historyWithVendorName = history.map((history) => {
    return {
      ...history,
      vendorName: vendors.find((vendor) => vendor.id === history.vendorId)?.name,
    };
  });

  const sortedHistory = historyWithVendorName.sort((a, b) => {
    return b.createdAt.seconds - a.createdAt.seconds;
  });

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView>
        <View style={styles.container}>
          {sortedHistory.map((history, index) => (
            <History vendorName={history.vendorName ?? ""} key={index} history={history} />
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = () =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
    },
    container: { paddingBottom: 50 },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
  });

export default HistoryScreen;
