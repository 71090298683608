import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Text, useTheme } from "react-native-paper";
import {
  ScrollView,
  Image,
  useWindowDimensions,
  View,
  StyleSheet,
  SafeAreaView,
  RefreshControl,
} from "react-native";
import { ActivityIndicator, IconButton, Snackbar } from "react-native-paper";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { type Customer } from "../../models/Customer";
import { type VendorCustomerPoints } from "../../models/VendorCustomer";
import VendorCustomerService from "../../services/vendorCustomer.service";
import CustomerService from "../../services/customer.service";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/MainNavigation";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "../navigation/MainNavigation";
import Description from "../../components/molecules/Description";
import { calculateDistance, dbt } from "../../utils";
import { useLocation } from "../../contexts/LocationContext";
import { getDistanceText } from "../../utils/index";
import Logger from "../../utils/logger";
import { LogLevel } from "../../models/helpers/LogEvent";
import { useTranslation } from "react-i18next";
import useCustomer from "../../hooks/queries/useProfile";
import useVendor from "../../hooks/queries/useVendor";
import useRewards from "../../hooks/queries/useRewards";
import useVendorRewards from "../../hooks/queries/useVendorRewards";
import VendorRewards from "./VendorRewards";
import Contact from "../../components/molecules/Contact";
import Map from "../../components/molecules/Map";
import VendorCoupons from "./VendorCoupons";

type Props = NativeStackScreenProps<RootStackParamList, "Vendor">;

export default function VendorScreen({ route }: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const queryClient = useQueryClient();
  const { location, updateLocation } = useLocation();
  const { width } = useWindowDimensions();
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [toastText, setToastText] = useState("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const { navigate } = useNavigation<MainNavigationProp>();
  const vendorId = route.params.vendorId;
  const customer = useCustomer();
  const vendor = useVendor({ id: vendorId, queryOnLoad: !!vendorId });
  const rewards = useRewards();
  const vendorRewards = useVendorRewards();

  const onDismissSnackBar = () => setVisible(false);

  const customerFavoriteVendorIdsMutation = useMutation({
    mutationFn: CustomerService.updateFavoriteVendorIds,
    onSuccess: (data) => {
      queryClient.setQueryData<Partial<Customer>>(["customer"], (customer) => {
        return {
          ...customer,
          favoriteVendorIds: data,
        };
      });
    },
    onError: (error) => {
      Logger.error(LogLevel.Error, {
        message:
          "Error updating favoriteVendorIds in VendorScreen component: CustomerService.updateFavoriteVendorIds.",
        error,
      });
    },
  });

  const customerPointsForAllVendorsQuery = useQuery<VendorCustomerPoints[] | null>({
    queryKey: ["customerPointsForAllVendors"],
    queryFn: () => VendorCustomerService.getCustomerMetaForAllVendors(),
  });

  const customerFavoriteVendorIds = customer.query.data?.favoriteVendorIds ?? [];

  useEffect(() => {
    setIsFavorite(customerFavoriteVendorIds.includes(vendorId));
  }, []);

  useEffect(() => {
    updateLocation();
  }, []);

  useEffect(() => {
    setRefreshing(customerPointsForAllVendorsQuery.isFetching); // Reset refreshing state after data is loaded or on error
  }, [customerPointsForAllVendorsQuery.isFetching]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    queryClient.invalidateQueries({
      queryKey: ["customerPointsForAllVendors"],
    });
  }, []);

  if (
    customer.query.isLoading ||
    vendor.query.isLoading ||
    customerPointsForAllVendorsQuery.isLoading ||
    rewards.query.isLoading
  ) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  if (!vendor.query.isSuccess || !vendor.query.data) {
    return (
      <SafeAreaView>
        <Text style={styles.notFoundText}>{t("vendorScreen.vendorNotFound")}</Text>
      </SafeAreaView>
    );
  }

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    setVisible(true);
    const newListOfFavorites = isFavorite
      ? customerFavoriteVendorIds.filter((v) => v !== vendorId)
      : [...customerFavoriteVendorIds, vendorId];
    customerFavoriteVendorIdsMutation.mutate(newListOfFavorites);
    setToastText(
      isFavorite ? t("vendorScreen.removedFromFavorites") : t("vendorScreen.addedToFavorites")
    );
  };

  const goToHistory = () => {
    navigate("History", { vendorId: vendorId });
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        {vendor.query.data.heroUrl && (
          <Image
            source={{
              uri: vendor.query.data.heroUrl ?? undefined,
            }}
            style={[styles.image, { width, height: width * 0.8, maxHeight: 700 }]}
          />
        )}
        <View style={styles.vendorNameAndFavoriteButtonContainer}>
          <View style={styles.vendorNameContainer}>
            <Text variant="titleMedium" style={styles.vendorNameText}>
              {vendor.query.data.name}
            </Text>
          </View>
          <View>
            <IconButton
              icon={isFavorite ? "cards-heart" : "cards-heart-outline"}
              mode="contained-tonal"
              iconColor={theme.colors.primary}
              size={20}
              onPress={toggleFavorite}
            />
          </View>
        </View>
        <View style={styles.vendorIntroductionContainer}>
          {vendor.query.data.description && (
            <Description
              initialText={dbt(i18n.language, vendor.query.data.description)}
              variant="bodyMedium"
            />
          )}
          {location && vendor.query.data.coordinates && (
            <View style={styles.distanceContainer}>
              <Icon source="ray-start-arrow" size={20} />
              <Text style={styles.distanceText}>
                {t("vendorScreen.distance")}:{" "}
                {getDistanceText(calculateDistance(location.coords, vendor.query.data.coordinates))}
              </Text>
            </View>
          )}
          {vendor.query.data.tags && (
            <View style={styles.tagsContainer}>
              <Icon source="tag-outline" size={20} />
              <View style={styles.tagsView}>
                <Text>
                  {vendor.query.data.tags
                    .map((x) => {
                      const tag = dbt(i18n.language, x);
                      return tag[0].toUpperCase() + tag.substring(1).toLowerCase();
                    })
                    .join(", ")}
                </Text>
              </View>
            </View>
          )}
        </View>
        <VendorCoupons vendorId={vendorId} />
        <VendorRewards vendorId={vendorId} />
        <View style={styles.rewardInfoContactAddressContainer}>
          <View style={styles.viewHistoryButtonContainer}>
            <Button
              icon="file-document-multiple-outline"
              mode="contained-tonal"
              onPress={goToHistory}
              style={styles.viewHistoryButton}
            >
              {t("vendorScreen.viewHistory")}
            </Button>
          </View>
          {(vendor.query.data.phone || vendor.query.data.email || vendor.query.data.website) && (
            <View style={styles.contactContainer}>
              <Contact
                phone={vendor.query.data.phone}
                email={vendor.query.data.email}
                website={vendor.query.data.website}
              />
            </View>
          )}
          {vendor.query.data.address && (
            <View style={styles.locationContainer}>
              <Text style={styles.addressTitle} variant="titleMedium">
                {t("vendorScreen.location")}
              </Text>
              <View style={styles.addressContainer}>
                <Icon source="map-marker-outline" size={20} />
                <View style={styles.addressView}>
                  <Map
                    address={vendor.query.data.address}
                    coordinates={vendor.query.data.coordinates}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      </ScrollView>
      <Snackbar
        visible={visible}
        onDismiss={onDismissSnackBar}
        action={{
          label: t("global.undo"),
          onPress: () => {
            setIsFavorite(!isFavorite);
          },
        }}
      >
        {toastText}
      </Snackbar>
    </SafeAreaView>
  );
}

const createStyles = () =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
    },
    listItem: {
      borderBottomWidth: 1,
      marginVertical: 8,
    },
    activityIndicator: {
      paddingTop: 20,
    },
    notFoundText: { paddingTop: 20, textAlign: "center" },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    image: {
      justifyContent: "center",
      resizeMode: "cover",
    },
    vendorNameAndFavoriteButtonContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 16,
    },
    vendorNameContainer: {
      flex: 1,
      alignItems: "flex-start",
      paddingRight: 20,
    },
    vendorNameText: {
      flexWrap: "wrap",
    },
    vendorIntroductionContainer: { marginHorizontal: 16 },
    distanceContainer: { flexDirection: "row", alignItems: "center" },
    distanceText: { marginLeft: 10 },
    tagsContainer: { flexDirection: "row", alignItems: "center" },
    tagsView: { marginLeft: 10 },
    rewardInfoContactAddressContainer: { marginTop: 30, paddingBottom: 50 },
    rewardInfoTitle: {
      textAlign: "left",
      marginHorizontal: 16,
    },
    customerOwnedStampsInAVendorText: {
      textAlign: "center",
      marginTop: 20,
    },
    checkoutRewardsText: { textAlign: "center", marginTop: 10 },
    viewHistoryButtonContainer: { marginHorizontal: 20, marginTop: 10 },
    viewHistoryButton: { alignSelf: "center" },
    contactContainer: { marginTop: 30, marginHorizontal: 16 },
    locationContainer: { marginTop: 30, marginHorizontal: 16 },
    addressTitle: {
      textAlign: "left",
      marginBottom: 10,
    },
    addressContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 10,
    },
    addressView: { marginLeft: 10 },
  });
