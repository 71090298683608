import React, { useEffect, useMemo, useRef } from "react";
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native";
import {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
  NativeStackNavigationProp,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import ProfileSheet from "../../components/organisms/ProfileSheet";
import EditProfileSheet from "../../components/organisms/EditProfileSheet";
import MessagesSheet from "../../components/organisms/MessagesSheet";
import WalletSheet from "../../components/organisms/WalletSheet";
import { Platform } from "react-native";
import CustomHeader from "./CustomHeader";
import { reactNavigationTheme } from "../../constants/theme";
import { useTranslation } from "react-i18next";

type BottomSheetStackParamList = {
  Profile: undefined;
  EditProfile: undefined;
  Messages: undefined;
  WalletSheet: undefined;
};
export type BottomSheetNavigationProp = NativeStackNavigationProp<BottomSheetStackParamList>;
export type BottomSheetNavigationKey = keyof BottomSheetStackParamList;

const Stack = createNativeStackNavigator<BottomSheetStackParamList>();

export default function BottomSheetNavigation({
  openWithRoute,
}: {
  openWithRoute: BottomSheetNavigationKey;
}) {
  const bottomSheetNavigationContainerRef = useRef<NavigationContainerRef<any>>(null);
  const { t, i18n } = useTranslation();

  const navigatorScreenOptions: any = useMemo(
    () => ({
      headerShown: true,
      safeAreaInsets: { top: 0 },
      cardStyle: {
        overflow: "visible",
      },
      contentStyle: {
        backgroundColor: "white",
      },
      ...(Platform.OS === "android" && {
        header: (props: NativeStackHeaderProps) => <CustomHeader {...props} />,
      }),
    }),
    []
  );

  const profileOptions: NativeStackNavigationOptions = useMemo(
    () => ({
      headerTitleAlign: "center",
      animation: "none",
      title: t("global.profile"),
    }),
    [i18n.language]
  );

  const messagesOptions: NativeStackNavigationOptions = useMemo(
    () => ({
      headerTitleAlign: "center",
      title: t("global.messages"),
    }),
    [i18n.language]
  );

  const walletOptions: NativeStackNavigationOptions = useMemo(
    () => ({
      headerTitleAlign: "center",
      headerShown: false,
      animation: "none",
      title: t("global.wallet"),
    }),
    [i18n.language]
  );

  const editProfileOptions: NativeStackNavigationOptions = useMemo(
    () => ({
      headerTitleAlign: "center",
      title: t("global.editProfile"),
    }),
    [i18n.language]
  );

  useEffect(() => {
    bottomSheetNavigationContainerRef.current?.reset({
      index: 1,
      routes: [{ name: openWithRoute }],
    });
  }, [openWithRoute]);

  return (
    <NavigationContainer
      ref={bottomSheetNavigationContainerRef}
      independent={true}
      theme={reactNavigationTheme}
    >
      <Stack.Navigator screenOptions={navigatorScreenOptions}>
        <Stack.Screen name="Profile" options={profileOptions} component={ProfileSheet} />
        <Stack.Screen
          name="EditProfile"
          options={editProfileOptions}
          component={EditProfileSheet}
        />
        <Stack.Screen name="Messages" options={messagesOptions} component={MessagesSheet} />
        <Stack.Screen name="WalletSheet" options={walletOptions} component={WalletSheet} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
