import {
  StyleSheet,
  View,
  useWindowDimensions,
  Platform,
  StyleProp,
  ViewStyle,
  Dimensions,
} from "react-native";
import React, { useMemo } from "react";
import { type RewardTemplate } from "../../../models/RewardTemplate";
import { Icon, MD3Theme, Text, useTheme } from "react-native-paper";
import FlipCard from "../../../components/molecules/FlipCard";
import QRCodeGenerator from "../../../components/molecules/QRCodeGenerator";
import QsolImage from "../../../components/atoms/QsolImage";
import { useTranslation } from "react-i18next";
import { dbt } from "../../../utils";
import RewardService from "../../../services/reward.service";
import { VendorReward } from "../../../models/VendorRewards";

interface RewardsCarouselOnboardingItemProps {
  uid: string;
  item: VendorReward;
  userOwnedStampsInAVendor: number;
  active?: boolean;
  canRedeem?: boolean;
}
export default function RewardsCarouselItem({
  uid,
  item,
  canRedeem,
}: RewardsCarouselOnboardingItemProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { width } = useWindowDimensions();
  const carouselInnerItemStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      height: "100%",
      flex: 1,
      flexBasis: width / 2,
      backgroundColor: canRedeem ? theme.colors.secondaryContainer : theme.colors.background,
    }),
    [width]
  );
  const windowWidth = Dimensions.get("window").width;
  const qrSize = Math.min(Math.round(windowWidth * 0.8), 400);

  const frontContent = () => {
    return (
      <View style={carouselInnerItemStyle}>
        {item.imageUrl ? (
          <QsolImage uri={item.imageUrl} style={styles.image} fallbackImage="reward.webp" />
        ) : (
          <View style={styles.image}>
            <Icon source="ticket" size={250} color={theme.colors.elevation.level5} />
          </View>
        )}
        <View style={styles.carouselInnerItemTextContainer}>
          <View style={styles.rewardInfoContainer}>
            <View
              style={
                (styles.rewardTitleContainer,
                {
                  width: Platform.OS === "web" ? "100%" : "80%",
                })
              }
            >
              {!!item.title && (
                <Text variant="titleMedium" style={styles.rewardTitleText}>
                  {dbt(i18n.language, item.title)}
                </Text>
              )}
            </View>
            {!!item.requiredPoints && (
              <Text variant="bodyMedium" style={styles.stampsText}>
                {t("global.requires")} {item.requiredPoints}{" "}
                {t(`global.stamp.${item.requiredPoints > 1 ? "plural" : "singular"}`)}
              </Text>
            )}
            {canRedeem && <Text>{t("vendorScreen.clickToRevealYourQrCode")}</Text>}
          </View>
        </View>
      </View>
    );
  };

  const backContent = () => {
    return (
      <View style={styles.backContentContainer}>
        <QRCodeGenerator
          size={qrSize}
          qrContent={RewardService.getQrContentForReward(uid, item.id!)}
        />
        <Text style={styles.backContentText} variant="bodySmall">
          {t("vendorScreen.redeemYourRewardUsingThisQRCode")}
        </Text>
      </View>
    );
  };

  return (
    <View style={[styles.container, { width }]}>
      <FlipCard
        style={[styles.carouselInnerItemTextContainer, carouselInnerItemStyle]}
        frontContent={frontContent()}
        backContent={backContent()}
        canFlip={canRedeem}
      />
    </View>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: Platform.OS === "web" ? 500 : "auto",
      flexDirection: "row",
      paddingBottom: 30,
    },
    image: {
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      resizeMode: "cover",
      height: "100%",
      backgroundColor: theme.colors.elevation.level2,
      borderRadius: 15,
      margin: 15,
    },
    rewardTitleText: {
      fontWeight: "800",
      textAlign: "center",
      paddingHorizontal: 6,
      flexShrink: 1,
      width: "100%",
    },
    stampsText: {
      textAlign: "center",
      paddingHorizontal: 6,
    },
    carouselInnerItemTextContainer: {
      flexDirection: "column",
      justifyContent: "center",
      paddingBottom: 15,
    },
    rewardInfoContainer: { flexDirection: "column", alignItems: "center" },
    rewardTitleContainer: { flexDirection: "row" },
    backContentContainer: { alignItems: "center", flexDirection: "column" },
    backContentText: { paddingTop: 10, paddingHorizontal: 30 },
  });
