import { AppNews, appNewsSchema } from "../models/AppNews";
import Logger from "../utils/logger";
import { LogLevel } from "../models/helpers/LogEvent";
import { array } from "yup";
import { getDocumentById, getDocuments } from "../lib/firebase/connectors";
import { logValidationErrors } from "../utils";

const appNewsCollection = "appNews";

export default class AppNewsService {
  static getAll = async (): Promise<AppNews[] | null> => {
    try {
      const appNews = await getDocuments<AppNews>(appNewsCollection);
      logValidationErrors(appNews, array().of(appNewsSchema), "AppNewsService.getAll");
      return appNews;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting all app news in AppNewsService.getAll",
        error,
      });
      return null;
    }
  };

  static getById = async (id: string): Promise<AppNews | null> => {
    try {
      const appNews = await getDocumentById<AppNews>(appNewsCollection, id);
      logValidationErrors(appNews, appNewsSchema, "AppNewsService.getbyId");
      return appNews;
    } catch (error) {
      Logger.error(LogLevel.Error, {
        message: "Error getting an app news in AppNewsService.getById",
        error,
      });
      return null;
    }
  };
}
