import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useAuth } from "../../contexts/AuthContext";
import WalletScreen from "../WalletScreen";
import MoreScreen from "../MoreScreen";
import { BottomNavigation, MD3Theme, useTheme } from "react-native-paper";
import SearchScreen from "../SearchScreen";
import HistoryScreen from "../HistoryScreen";
import ProfileSheet from "../../components/organisms/ProfileSheet";
import MessagesSheet from "../../components/organisms/MessagesSheet";
import EditProfileSheet from "../../components/organisms/EditProfileSheet";
import AppNewsListScreen from "../AppNewsListScreen";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import VendorScreen from "../VendorScreen";
import AppNewsDetailsScreen from "../AppNewsDetailsScreen";
import { QponlyNavbarTheme } from "../../constants/theme";
import EmailLoginScreen from "../auth/EmailLoginScreen";
import { useTranslation } from "react-i18next";
import WalletSheet from "../../components/organisms/WalletSheet";
import useNavigationState from "../../hooks/useNavigationState";
import useNavigateToPreviousState from "../../hooks/useNavigateToPreviousState";
import LoadingScreen from "../LoadingScreen";
import SetupProfile from "./SetupProfile";
import EmailRegisterScreen from "../auth/EmailRegisterScreen";
import useProfile from "../../hooks/queries/useProfile";
import HomeScreen from "../HomeScreen";
import VerifyEmailScreen from "../auth/VerifyEmailScreen";
import TabNavigationContextProvider, { QponlyTabs } from "./TabNavigationContext";

export type RootStackParamList = {
  Qponly: undefined;
  Profile: undefined;
  EditProfile: undefined;
  SetupProfile: undefined;
  Wallet: undefined;
  WalletSheet: undefined;
  Messages: undefined;
  Login: undefined;
  VerifyEmail: { email: string };
  RegisterAccount: undefined;
  Loading: undefined;
  History: { vendorId?: string };
  Vendor: { vendorId: string };
  AppNewsList: undefined;
  AppNewsDetails: { appNewsId: string };
};
export type MainNavigationProp = NativeStackNavigationProp<RootStackParamList>;

const Tabs = () => {
  const { i18n } = useTranslation();
  const navState = useNavigationState();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [index, setIndex] = useState<number>(0);

  const openTab = (tab: QponlyTabs) => {
    switch (tab) {
      case "Home":
        setIndex(0);
        break;
      case "Wallet":
        setIndex(1);
        break;
      case "Search":
        setIndex(2);
        break;
      case "More":
        setIndex(3);
        break;
    }
  };

  useEffect(() => {
    if (navState.tabIndex) {
      setIndex(navState.tabIndex);
    }
  }, [navState.tabIndex]);

  const handleIndexChange = (index: number) => {
    setIndex(index);
    navState.updateTabIndex(index);
  };

  const routes = useMemo(
    () => [
      {
        key: "home",
        focusedIcon: "home",
        unfocusedIcon: "home-outline",
      },
      {
        key: "wallet",
        focusedIcon: "wallet",
        unfocusedIcon: "wallet-outline",
      },
      {
        key: "search",
        focusedIcon: "store-search",
        unfocusedIcon: "store-search-outline",
      },
      {
        key: "more",
        focusedIcon: "dots-horizontal-circle",
        unfocusedIcon: "dots-horizontal-circle-outline",
      },
    ],
    [i18n.language]
  );

  const renderScene = BottomNavigation.SceneMap({
    home: () => <HomeScreen />,
    wallet: () => <WalletScreen />,
    search: () => <SearchScreen />,
    more: () => <MoreScreen />,
  });

  return (
    <TabNavigationContextProvider openTab={openTab}>
      <BottomNavigation
        navigationState={{ index, routes }}
        onIndexChange={handleIndexChange}
        renderScene={renderScene}
        barStyle={styles.bottomNavigationBar}
        theme={QponlyNavbarTheme}
      />
    </TabNavigationContextProvider>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    bottomNavigationBar: {
      justifyContent: "center",
      height: 40,
    },
  });

const MainNavigation = () => {
  const { isAuthenticated, loading } = useAuth();
  const { t } = useTranslation();
  const Stack = createNativeStackNavigator<RootStackParamList>();
  const profile = useProfile({ queryOnLoad: isAuthenticated });
  const profileLoading = profile.query.isLoading;
  const appLoading = loading || profileLoading;
  const hasNoProfile = !profile.query.data || !profile.query.data?.displayName;
  useNavigateToPreviousState(appLoading);

  const webOnlyScreenOptions: any = useMemo(
    () => ({
      contentStyle: {
        backgroundColor: "white",
      },
    }),
    []
  );

  if (appLoading) {
    return (
      <Stack.Navigator>
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Loading" component={LoadingScreen} />
        </Stack.Group>
      </Stack.Navigator>
    );
  }

  if (!isAuthenticated) {
    return (
      <Stack.Navigator>
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen
            name="Login"
            options={{ title: t("auth.signIn") }}
            component={EmailLoginScreen}
          />
          <Stack.Screen
            name="RegisterAccount"
            options={{ title: t("auth.registerAccount") }}
            component={EmailRegisterScreen}
          />
          <Stack.Screen
            name="VerifyEmail"
            options={{ title: t("auth.verifyEmail") }}
            component={VerifyEmailScreen}
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  }

  if (hasNoProfile) {
    if (profileLoading) {
      return (
        <Stack.Navigator>
          <Stack.Group screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Loading" component={LoadingScreen} />
          </Stack.Group>
        </Stack.Navigator>
      );
    }
    return (
      <Stack.Navigator>
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name="SetupProfile" component={SetupProfile} />
        </Stack.Group>
      </Stack.Navigator>
    );
  }

  return (
    <Stack.Navigator>
      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Qponly" component={Tabs} />
      </Stack.Group>
      <Stack.Group screenOptions={webOnlyScreenOptions}>
        <Stack.Screen
          name="Profile"
          options={{ title: t("global.profile") }}
          component={ProfileSheet}
        />
        <Stack.Screen
          name="EditProfile"
          options={{ title: t("global.editProfile") }}
          component={EditProfileSheet}
        />
        <Stack.Screen
          name="Wallet"
          options={{ title: t("global.wallet") }}
          component={WalletScreen}
        />
        <Stack.Screen
          name="WalletSheet"
          options={{ title: t("global.wallet") }}
          component={WalletSheet}
        />
        <Stack.Screen
          name="Messages"
          options={{ title: t("global.messages") }}
          component={MessagesSheet}
        />
        <Stack.Screen
          name="History"
          options={{ title: t("global.history") }}
          component={HistoryScreen}
        />
        <Stack.Screen
          name="Vendor"
          component={VendorScreen}
          options={{ title: t("global.serviceProvider") }}
          getId={({ params }) => params.vendorId}
        />
        <Stack.Screen
          name="AppNewsList"
          options={{ title: t("global.news") }}
          component={AppNewsListScreen}
        />
        <Stack.Screen
          name="AppNewsDetails"
          options={{ title: "" }}
          component={AppNewsDetailsScreen}
          getId={({ params }) => params.appNewsId}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default MainNavigation;
