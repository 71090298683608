import React, { useMemo } from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { useTheme } from "react-native-paper";
import QRCode from "react-qr-code";
import Logger from "../../utils/logger";
import { LogLevel } from "../../models/helpers/LogEvent";
import { useTranslation } from "react-i18next";

interface QRCodeGeneratorProps {
  qrContent: string;
  size?: number;
  bgColor?: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ qrContent: id, size, bgColor }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => createStyles(), []);
  if (!id) {
    Logger.error(LogLevel.Error, {
      message: "QRCodeGenerator: id is undefined",
    });
    return (
      <SafeAreaView>
        <Text style={styles.errorText}>{t("global.errorMessage.problemWithQRCode")}</Text>
      </SafeAreaView>
    );
  }
  const { colors } = useTheme();
  return (
    <QRCode
      style={styles.qrCode}
      value={id}
      size={size ?? 120}
      bgColor={bgColor}
      fgColor={colors.onSurface}
    />
  );
};

const createStyles = () =>
  StyleSheet.create({
    qrCode: {
      borderRadius: 12,
    },
    errorText: {
      paddingTop: 20,
      textAlign: "center",
    },
  });

export default QRCodeGenerator;
