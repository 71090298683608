import { Customer } from "../../../models/Customer";
import { getCloudFunction } from "../connectors";

export type CallableResult<T> = { data: T };
export type CallableFunc<Tin, Tout> = (params: Tin) => Promise<CallableResult<Tout>>;

interface GetTokenParams {
  uid: string;
  accessToken: string;
}
export async function getToken(params: GetTokenParams): Promise<string> {
  const cloudFunction = await getCloudFunction<GetTokenParams, { token: string }>("getToken");
  try {
    const response = await cloudFunction(params);
    if (response.data) {
      return response.data.token;
    }

    throw new Error("Token could not be fetched from Firebase.");
  } catch (error: any) {
    console.error("Could not get token from Firebase", error);
    throw error;
  }
}

interface EnsureCustomerHasModelParams {
  uid: string;
  email: string;
  displayName: string;
}
export async function ensureCustomerHasModel(
  params: EnsureCustomerHasModelParams
): Promise<Customer> {
  const cloudFunction = await getCloudFunction<EnsureCustomerHasModelParams, Customer>(
    "ensureCustomerHasModel"
  );
  try {
    const response = await cloudFunction(params);
    return response.data;
  } catch (error: any) {
    console.error("Could not ensure customer has model", error);
    throw error;
  }
}

interface HandleMobileSupabaseLoginParams {
  email: string;
  password: string;
}
interface EnsureSupabaseAuthResponse {
  success: boolean;
  status: "can_login" | "cannot_register" | "can_register" | "not_confirmed" | "unknown_error";
  error?: string;
}
export async function handleMobileSupabaseLogin(
  params: HandleMobileSupabaseLoginParams
): Promise<EnsureSupabaseAuthResponse> {
  const cloudFunction = await getCloudFunction<
    HandleMobileSupabaseLoginParams,
    EnsureSupabaseAuthResponse
  >("handleMobileSupabaseLogin");
  try {
    const response = await cloudFunction(params);
    return response.data;
  } catch (error: any) {
    console.error("Failed to test Supabase", error);
    throw error;
  }
}
