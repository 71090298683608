import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ReadByCustomerService from "../../services/readByCustomer.service";
import Logger from "../../utils/logger";
import { LogLevel } from "../../models/helpers/LogEvent";

interface Properties {
  queryOnLoad?: boolean;
}

export default function useReadByCustomer(props?: Properties) {
  const queryKey = ["useReadByCustomer"];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: () => ReadByCustomerService.get(),
    enabled: props?.queryOnLoad ?? true,
  });

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const mutation = useMutation({
    mutationFn: ReadByCustomerService.set,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error) => {
      Logger.error(LogLevel.Error, {
        message:
          "Error updating readByCustomer in MessagesSheet component: ReadByCustomerService.upsert.",
        error,
      });
    },
  });

  return { query, invalidate, mutation };
}
