import { InferType, boolean, object, string, array } from "yup";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

// Customer
// Added displayName and imageUri
export const customerSchema = object({
  showPhoneToVendor: boolean().defined(),
  createdAt: firebaseTimestampSchema.defined(),
  active: boolean().defined(),
  email: string().email("Invalid email format"),
  name: string(),
  displayName: string().defined(),
  imageUrl: string().nullable().url("Invalid image URL format"),
  phone: string().defined(),
  uid: string().defined(),
  updatedAt: firebaseTimestampSchema.defined(),
  favoriteVendorIds: array().of(string().defined()),
});
export type Customer = InferType<typeof customerSchema>;

// CreateCustomer
export const createCustomerSchema = object({
  email: string().email("Invalid email format"),
  name: string(),
  phone: string().required(),
});
export type CreateCustomer = InferType<typeof createCustomerSchema>;
