import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  StyleSheet,
  View,
  SafeAreaView,
  ScrollView,
  RefreshControl,
  Platform,
  StatusBar,
} from "react-native";
import {
  Text,
  Searchbar,
  SegmentedButtons,
  ActivityIndicator,
  useTheme,
  Button,
} from "react-native-paper";
import VendorList from "../components/organisms/VendorList";
import { useTranslation } from "react-i18next";
import { useLocation } from "../contexts/LocationContext";
import useVendors from "../hooks/queries/useVendors";
import useCurrentUserCustomerModel from "../hooks/queries/useProfile";

const SearchScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(), []);
  const { location, status, updateLocation } = useLocation();
  const [selectedButton, setSelectedButton] = useState<string>("all");
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const vendors = useVendors();
  const customer = useCurrentUserCustomerModel();

  const onChangeSearch = (query: string) => setSearchQuery(query);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    vendors.invalidate();
    customer.invalidate();
  }, []);

  useEffect(() => {
    setRefreshing(vendors.query.isFetching || customer.query.isFetching);
  }, [vendors.query.isFetching, customer.query.isFetching]);

  useEffect(() => {
    updateLocation();
  }, []);

  // useEffect(() => {
  //   vendors.query.data?.forEach((vendor) => {
  //     queryClient.setQueryData(["vendor", vendor.id], vendor, {});
  //   });
  // }, [vendors.query.data]);

  if (vendors.query.isLoading || customer.query.isLoading || status === "undetermined") {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  const vendorList = vendors.query.data ?? [];
  const customerFavoriteVendorIds = customer.query.data?.favoriteVendorIds ?? [];

  const filteredVendors = vendorList
    .filter((vendor) => (showFavorites ? customerFavoriteVendorIds.includes(vendor.id) : true))
    .filter((vendor) => vendor.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <View style={styles.container}>
          <Text variant="titleLarge" style={styles.title}>
            {t("global.search")}
          </Text>
          <View style={styles.contentView}>
            <Searchbar
              style={styles.searchBar}
              placeholder={t("searchScreen.searchForServiceProviders")}
              onChangeText={onChangeSearch}
              value={searchQuery}
            />
            <SegmentedButtons
              style={styles.segmentedButtons}
              value={selectedButton}
              onValueChange={(newValue: string) => setSelectedButton(newValue)}
              buttons={[
                {
                  value: "all",
                  label: t("searchScreen.all"),
                  icon: showFavorites ? "store-outline" : "store",
                  onPress: () => setShowFavorites(false),
                },
                {
                  value: "favorites",
                  label: t("searchScreen.favorites"),
                  icon: showFavorites ? "heart" : "heart-outline",
                  onPress: () => setShowFavorites(true),
                },
              ]}
            />
            {filteredVendors.length === 0 || !filteredVendors ? (
              <View>
                <Text style={styles.text}>
                  {showFavorites
                    ? t("searchScreen.noFavoritesFound")
                    : t("searchScreen.noServiceProvidersFound")}
                </Text>
              </View>
            ) : (
              <View style={styles.listView}>
                {!location && (
                  <View style={styles.checkLocationView}>
                    <Button icon="map-marker" onPress={() => updateLocation(true)}>
                      {t("global.alert.locationPermission.checkLocation")}
                    </Button>
                  </View>
                )}
                <VendorList vendors={filteredVendors} location={location} />
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = () =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
      paddingTop: Platform.select({
        ios: 0,
        android: StatusBar.currentHeight,
        web: 20,
      }),
    },
    container: { marginTop: 20 },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    segmentedButtons: { width: "90%" },
    listView: { width: "90%", paddingBottom: 50 },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: 10,
    },
    contentView: {
      alignItems: "center",
    },
    searchBar: {
      width: "90%",
      marginBottom: 16,
    },
    text: {
      padding: 20,
    },
    listItemImage: {
      width: 120,
      height: 100,
      borderRadius: 10,
      marginRight: 10,
    },
    checkLocationView: {
      // display: "flex",
      flexDirection: "row",
      marginTop: 8,
      justifyContent: "flex-start",
    },
  });

export default SearchScreen;
