import { InferType, bool, number, object, string } from "yup";
import { translatableSchema } from "./helpers/Translatable";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";

export const productSchema = object({
  id: string(),
  active: bool().required(),
  vendorId: string().nullable(),
  title: translatableSchema.required(),
  description: translatableSchema.nullable(),
  imageUrl: string().nullable(),
  category: string().nullable(),
  visibleForCustomers: bool().required(),
  usableAsReward: bool().required(),
  pointsRequired: number().required(),
  pointsRewarded: number().required(),
  usableInQrMenu: bool().required(),
  sales_tax_percentage: number(),
  createdAt: firebaseTimestampSchema,
});

export type Product = InferType<typeof productSchema>;
