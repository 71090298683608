import { InferType, bool, number, object, string } from "yup";
import { firebaseTimestampSchema } from "./helpers/FirebaseTimestamp";
import { translatableSchema } from "./helpers/Translatable";

export const rewardTemplateSchema = object({
  id: string().notRequired(),
  createdAt: firebaseTimestampSchema.required(),
  updatedAt: firebaseTimestampSchema.required(),
  active: bool().required(),
  vendorId: string().required(),
  requiredPoints: number().required(),
  title: translatableSchema.required().defined(),
  description: translatableSchema.required(),
  imageUrl: string().notRequired(),
});

export type RewardTemplate = InferType<typeof rewardTemplateSchema>;
