import React, { useState } from "react";
import { TouchableOpacity, ViewStyle, StyleProp } from "react-native";

interface Props {
  frontContent: React.ReactNode;
  backContent: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  canFlip?: boolean;
}

const FlipCard = ({ frontContent, backContent, style, canFlip }: Props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  if (canFlip === false) {
    return <>{frontContent}</>;
  }

  return (
    <TouchableOpacity onPress={flipCard} style={style}>
      {!isFlipped ? frontContent : backContent}
    </TouchableOpacity>
  );
};

export default FlipCard;
