import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TouchableOpacity,
  Linking,
  StyleSheet,
  Platform,
  View,
  Modal,
} from "react-native";
import { MD3Theme, Text, useTheme } from "react-native-paper";

interface CoordinatesProps {
  latitude: number;
  longitude: number;
}
interface MapProps {
  address: string;
  coordinates?: CoordinatesProps | null;
}

const Map: React.FC<MapProps> = ({ address, coordinates }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [modalVisible, setModalVisible] = useState(false);

  const openMap = () => {
    if (coordinates) {
      if (Platform.OS === "ios") {
        // Show the modal on iOS
        setModalVisible(true);
      } else {
        // Directly open Google Maps on Android
        openGoogleMaps();
      }
    }
  };

  const openGoogleMaps = () => {
    if (coordinates) {
      const { latitude, longitude } = coordinates;

      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

      Linking.openURL(googleMapsUrl).catch(() => {
        console.error("Failed to open Google Maps app or web page");
      });
    }
  };

  const openAppleMaps = () => {
    if (coordinates) {
      const { latitude, longitude } = coordinates;

      const appleMapsUrl = `https://maps.apple.com/?q=${address}&ll=${latitude},${longitude}`;

      Linking.openURL(appleMapsUrl).catch(() => {
        console.error("Failed to open Apple Maps app or web page");
      });
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <View>
      <TouchableOpacity onPress={coordinates ? openMap : undefined}>
        <Text
          style={{ textDecorationLine: coordinates ? "underline" : "none" }}
        >
          {address}
        </Text>
      </TouchableOpacity>
      {Platform.OS === "ios" && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={closeModal}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <View style={styles.guideView}>
                <Text style={styles.guideTitle} variant="titleSmall">
                  {t("mapComponent.map")}
                </Text>
                <Text style={styles.guideText}>
                  {t("mapComponent.chooseThePreferredNavigationApp")}
                </Text>
              </View>
              <TouchableOpacity style={styles.button} onPress={openGoogleMaps}>
                <Text style={styles.mapButtonText}>
                  {t("mapComponent.googleMaps")}
                </Text>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity style={styles.button} onPress={openAppleMaps}>
                <Text style={styles.mapButtonText}>
                  {t("mapComponent.appleMaps")}
                </Text>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity style={styles.button} onPress={closeModal}>
                <Text style={styles.cancelButtonText}>{t("global.cancel")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};
const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: "flex-end", // Display at the bottom of the screen
      paddingBottom: 30,
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent background
    },
    modalContent: {
      backgroundColor: "rgba(255,255,255,0.97)",
      paddingTop: 20,
      borderRadius: 10,
      elevation: 5,
      alignItems: "center",
      width: "95%",
    },
    guideView: {
      alignItems: "center",
      paddingVertical: 10,
    },
    guideTitle: {
      fontSize: 18,
      color: "#666",
    },
    guideText: {
      marginTop: 10,
      fontSize: 16,
      color: "#666",
      textAlign: "center",
    },
    button: {
      width: "100%",
      paddingHorizontal: 16,
      paddingVertical: 20,
    },
    divider: {
      width: "100%",
      borderBottomWidth: 1, // Add a bottom border to mimic a text button
      borderColor: "#f0f0f0", // Border color similar to iOS link color
    },
    mapButtonText: {
      color: "#007aff", // Text color similar to iOS link color
      fontSize: 18,
      textAlign: "center",
    },
    cancelButtonText: {
      color: "#007aff", // Text color similar to iOS link color
      fontSize: 18,
      textAlign: "center",
    },
  });
export default Map;
