import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { Badge, IconButton, MD3Theme, Text, useTheme } from "react-native-paper";
import { useQsolBottomSheet } from "../../contexts/QsolBottomSheetContext";
import { VIEW_PADDING } from "../../constants/styles";
import useUnreadMessagesCount from "../../hooks/queries/useUnreadMessagesCount";

export default function HeaderRow() {
  const bottomSheet = useQsolBottomSheet();
  const styles = createStyles(useTheme());
  const { t } = useTranslation();
  const unreadMessagesCount = useUnreadMessagesCount();
  const badgeIsVisible = unreadMessagesCount > 0;

  return (
    <View style={styles.headerRow}>
      <Text variant="titleLarge" style={{ fontWeight: "900" }}>
        {t("global.hi")},
      </Text>
      <View style={styles.headerButtons}>
        <IconButton
          icon="badge-account-horizontal-outline"
          size={26}
          onPress={() => bottomSheet.show("WalletSheet")}
        />
        <IconButton
          icon="account-cog-outline"
          size={26}
          onPress={() => bottomSheet.show("Profile")}
        />
        {badgeIsVisible && <Badge size={8} style={styles.badge} />}
      </View>
    </View>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    headerRow: {
      flexDirection: "row",
      height: 48,
      paddingHorizontal: VIEW_PADDING,
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerButtons: {
      flexDirection: "row",
      position: "relative",
    },
    button: { borderRadius: 60 },
    redeemableRewards: {
      paddingHorizontal: VIEW_PADDING,
      paddingTop: 30,
      paddingBottom: 50,
      backgroundColor: theme.colors.inverseOnSurface,
    },
    redeemableRewardsTitle: {
      textAlign: "center",
      width: "100%",
      paddingBottom: 10,
      fontWeight: "800",
    },
    scrollView: { paddingHorizontal: 0, paddingTop: 0 },
    badge: { position: "absolute", top: 15, right: 15 },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
    dialogTextContainer: {
      marginTop: 10,
    },
    dialogText: {
      marginBottom: 14,
    },
  });
