// VendorItem.tsx
import React, { useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useTheme, MD3Theme, Text } from "react-native-paper";
import QsolImage from "../atoms/QsolImage";
import { capitalizeEachWord, dbt, getAvatarText, getDistanceText } from "../../utils";
import { useNavigation } from "@react-navigation/native";
import { MainNavigationProp } from "../../screens/navigation/MainNavigation";
import { Translatable } from "../../models/helpers/Translatable";
import { useTranslation } from "react-i18next";

interface VendorItemProps {
  vendorId: string;
  heroUrl?: string;
  vendorName: string;
  distance: number | null;
  vendorTags?: Translatable[] | undefined;
}

const VendorItem: React.FC<VendorItemProps> = ({
  vendorId,
  heroUrl,
  vendorName,
  distance,
  vendorTags,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { navigate } = useNavigation<MainNavigationProp>();
  const { i18n } = useTranslation();

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => navigate("Vendor", { vendorId: vendorId })}
    >
      {/* Left half: Vendor Hero Image */}
      {heroUrl ? (
        <QsolImage uri={heroUrl ?? undefined} style={[styles.view]} />
      ) : (
        <View style={[styles.view, styles.fallbackView]}>
          <Text variant="headlineLarge" style={styles.fallbackText}>
            {getAvatarText(vendorName)}
          </Text>
        </View>
      )}
      {/* Right half: Vendor information */}
      <View style={styles.textInfoView}>
        <Text variant="titleMedium">{capitalizeEachWord(vendorName)}</Text>
        {distance !== null && distance !== -1 && <Text>{getDistanceText(distance)}</Text>}
        {vendorTags && (
          <Text>
            {vendorTags
              .map((x) => {
                const tag = dbt(i18n.language, x);
                return tag[0].toUpperCase() + tag.substring(1).toLowerCase();
              })
              .join(", ")}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      marginTop: 12,
    },
    view: {
      flex: 1,
      marginRight: 5,
      borderRadius: 10,
      maxWidth: 250,
      height: 100,
    },
    fallbackView: {
      backgroundColor: theme.colors.inverseOnSurface,
      justifyContent: "center",
    },
    fallbackText: {
      color: theme.colors.onSurface,
      textAlign: "center",
    },
    textInfoView: {
      flex: 1,
      padding: 10,
      marginLeft: 5,
      justifyContent: "center",
    },
  });

export default VendorItem;
