import React, { useMemo } from "react";
import { View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { Text, useTheme, MD3Theme } from "react-native-paper";
import { useTranslation } from "react-i18next";
import Logo from "../../components/atoms/Logo";
import { RootStackParamList } from "../navigation/MainNavigation";
import LanguageSwitchButton from "../../components/atoms/LanguageSwitchButton";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

type Props = NativeStackScreenProps<RootStackParamList, "VerifyEmail">;

export default function VerifyEmailScreen({ route }: Props) {
  const verifyEmail = route.params.email;
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { t } = useTranslation();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{ width: "100%", alignItems: "center", marginBottom: 10 }}>
          <Logo />
          <LanguageSwitchButton />
        </View>
        <View style={{ alignItems: "center", gap: 10 }}>
          <Text variant="headlineLarge">{t("auth.verifyEmail")}</Text>
          <View>
            <Text variant="bodyMedium">
              {t("auth.weHaveSentVerificationEmailTo")}{" "}
              <Text variant="labelLarge">{verifyEmail}</Text>.
            </Text>
            <Text variant="bodyMedium">{t("auth.followTheLinkToLogIntoYourAccount")}.</Text>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      justifyContent: "center",
      padding: 20,
    },
    input: {
      marginBottom: 12,
    },
    button: {
      marginTop: 12,
    },
  });
