import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  StatusBar,
  Platform,
  RefreshControl,
} from "react-native";
import {
  IconButton,
  Text,
  Button,
  useTheme,
  ActivityIndicator,
  Card,
  Avatar,
} from "react-native-paper";
import { VIEW_PADDING } from "../../constants/styles";
import { MD3Theme } from "react-native-paper/lib/typescript/types";
import QsolDialog from "../../components/molecules/QsolDialog";
import { useTranslation } from "react-i18next";
import usePromotions from "../../hooks/queries/usePromotions";
import useRewards from "../../hooks/queries/useRewards";
import useVendors from "../../hooks/queries/useVendors";
import useVendorCustomer from "../../hooks/queries/usePoints";
import RedeemableRewards from "../../components/organisms/RedeemableRewards";
import HeaderRow from "./HeaderRow";
import { useTabContext } from "../navigation/TabNavigationContext";

const HomeScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [visible, setVisible] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const promotions = usePromotions();
  const rewards = useRewards();
  const vendors = useVendors();
  const points = useVendorCustomer();
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const { openTab } = useTabContext();

  useEffect(() => {
    setRefreshing(points.query.isFetching || rewards.query.isFetching); // Reset refreshing state after data is loaded or on error
  }, [points.query.isFetching, rewards.query.isFetching]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    points.invalidate();
    rewards.invalidate();
  }, []);

  if (promotions.query.isLoading || vendors.query.isLoading) {
    return (
      <SafeAreaView style={styles.activityIndicatorSafeAreaView}>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={true}
          color={theme.colors.primary}
        />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <ScrollView
        style={styles.scrollView}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        <View style={styles.container}>
          <HeaderRow />
          <View style={styles.redeemableDataContainer}>
            <Text variant="titleLarge" style={{ fontWeight: "900" }}>
              {t("global.welcomeToQponly")}!
            </Text>
            <View>
              <IconButton icon="information-outline" onPress={showDialog} />
              <QsolDialog
                scrollable
                style={styles.dialogContainer}
                visible={visible}
                hideDialog={hideDialog}
                title={t("howToUseQponlyDialog.title")}
                actions={<Button onPress={hideDialog}>{t("global.close")}</Button>}
                children={
                  <View style={styles.dialogTextContainer}>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      📱 {t("howToUseQponlyDialog.description.paragraph1")}
                    </Text>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      🕶️ {t("howToUseQponlyDialog.description.paragraph2")}
                    </Text>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      🎫 {t("howToUseQponlyDialog.description.paragraph3")}
                    </Text>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      🎁 {t("howToUseQponlyDialog.description.paragraph4")}
                    </Text>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      🗂️ {t("howToUseQponlyDialog.description.paragraph5")}
                    </Text>
                    <Text variant="bodyMedium" style={styles.dialogText}>
                      {t("howToUseQponlyDialog.description.paragraph6")} 🎉
                    </Text>
                  </View>
                }
              />
            </View>
          </View>
          <View style={{ paddingHorizontal: VIEW_PADDING, gap: VIEW_PADDING }}>
            <Card>
              <Card.Title
                title={t("vendor.plural")}
                titleVariant="titleLarge"
                left={(props) => (
                  <Avatar.Icon
                    {...props}
                    icon="store"
                    style={{ backgroundColor: theme.colors.primary }}
                  />
                )}
              />
              <Card.Content>
                <Text variant="bodyMedium">{t("homeScreen.findDealsFromVendorsNearYou")}</Text>
              </Card.Content>
              <Card.Actions>
                <Button onPress={() => openTab("Search")} mode="contained-tonal">
                  {t("homeScreen.browseVendors")}
                </Button>
              </Card.Actions>
            </Card>

            <Card>
              <Card.Title
                title={t("points.yourPoints")}
                titleVariant="titleLarge"
                left={(props) => (
                  <Avatar.Icon
                    {...props}
                    icon="ticket-confirmation"
                    style={{ backgroundColor: theme.colors.primary }}
                  />
                )}
              />
              <Card.Content>
                <Text variant="bodyMedium">
                  {t("homeScreen.hereYouCanSeeYourPointsForVendors")}
                </Text>
              </Card.Content>
              <Card.Actions>
                <Button onPress={() => openTab("Wallet")} mode="contained-tonal">
                  {t("homeScreen.showYourPoints")}
                </Button>
              </Card.Actions>
            </Card>

            <Card style={{ marginBottom: 300 }}>
              <Card.Title
                title={t("homeScreen.availableRewards")}
                titleVariant="titleLarge"
                left={(props) => (
                  <Avatar.Icon
                    {...props}
                    icon="star-face"
                    style={{ backgroundColor: theme.colors.primary }}
                  />
                )}
              />
              <Card.Content>
                <RedeemableRewards />
              </Card.Content>
            </Card>
          </View>
          {/* {promotions.query.isSuccess && promotions.query.data && (
            <PromotionsCarousel availablePromotions={promotions.query.data} />
          )} */}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const createStyles = (theme: MD3Theme) =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
      paddingTop: Platform.select({
        ios: 0,
        android: StatusBar.currentHeight,
        web: 20,
      }),
      // paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
    },
    container: { marginTop: 6, gap: 14 },
    redeemableDataContainer: {
      paddingHorizontal: VIEW_PADDING,
      flexDirection: "row",
      paddingTop: 10,
      alignItems: "center",
    },
    activityIndicator: {
      paddingTop: 20,
    },
    activityIndicatorSafeAreaView: {
      marginVertical: 30,
    },
    buttonContent: {
      flexDirection: "column",
      marginRight: 5,
      marginTop: 8,
      minWidth: 60,
    },
    button: { borderRadius: 60 },
    redeemableRewards: {
      paddingHorizontal: VIEW_PADDING,
      paddingTop: 30,
      paddingBottom: 50,
      backgroundColor: theme.colors.inverseOnSurface,
    },
    redeemableRewardsTitle: {
      textAlign: "center",
      width: "100%",
      paddingBottom: 10,
      fontWeight: "800",
    },
    scrollView: { paddingHorizontal: 0, paddingTop: 0 },
    dialogContainer: {
      backgroundColor: theme.colors.background,
    },
    dialogTextContainer: {
      marginTop: 10,
    },
    dialogText: {
      marginBottom: 14,
    },
  });

export default HomeScreen;
